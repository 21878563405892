<div [formGroup]="personForm" *ngIf="person$ | async as person" class="person-edit-frame">
  <div fxLayout="row" fxLayoutGap="10px" class="row-level">
    <div class="buffer">
      <mat-icon>portrait</mat-icon>
    </div>
    <div fxFlex class="name-box">
      <div class="prefix">
        <mat-form-field class="width-100">
          <mat-label>Prefix</mat-label>
          <input matInput type="text" formControlName="prefix" name="prefix" 
                 [matAutocomplete]="prefixAuto">
          <mat-autocomplete #prefixAuto>
            <mat-option *ngFor="let pref of prefixOptions" [value]="pref">
              <span>{{pref}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="name-field">
        <mat-form-field class="width-100">
          <mat-label>First Name</mat-label>
          <input autocomplete="off" matInput type="text" formControlName="first_name" name="first_name">
        </mat-form-field>
      </div>
      <div class="name-field">
        <mat-form-field class="width-100">
          <mat-label>Middle Name</mat-label>
          <input autocomplete="off" matInput type="text" formControlName="middle_name" name="middle_name">
        </mat-form-field>
      </div>
      <div class="name-field">
        <mat-form-field class="width-100">
          <mat-label>Last Name</mat-label>
          <input autocomplete="off" matInput type="text" formControlName="last_name" name="last_name">
        </mat-form-field>
      </div>
      <div class="suffix">
        <mat-form-field class="width-100">
          <mat-label>Suffix</mat-label>
          <input matInput type="text" formControlName="suffix" name="suffix" 
                 [matAutocomplete]="suffixAuto">
          <mat-autocomplete #suffixAuto>
            <mat-option *ngFor="let suff of suffixOptions" [value]="suff">
              <span>{{suff}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div class="secondary-name-box">
      <div fxLayout="row" fxLayoutGap="10px">
        <div class="buffer">
          <mat-icon title="Preferred Name">badge</mat-icon>
        </div>
        <div fxFlex>
          <mat-form-field class="width-100">
            <mat-label>Preferred Name</mat-label>
            <input matInput type="text" formControlName="preferred_name" name="preferred_name">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div class="buffer">
          <mat-icon title="Relationships">group</mat-icon>
        </div>
        <div fxFlex>
          <mat-form-field class="width-100">
            <mat-label>Spouse</mat-label>
            <input matInput type="text" name="spouse" formControlName="spouse">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div class="buffer">
      <mat-icon title="Emails">email</mat-icon>
    </div>
    <div fxFlex formArrayName="email_addresses" class="overflow-auto">
      <div class="overflow-auto" 
           *ngFor="let emailAdd of personForm.get('email_addresses')['controls']; let emailAddressIndex = index; let lastEmail = last;">
        <div class="multi-form-container" [formGroupName]="emailAddressIndex">
          <mat-form-field>
            <mat-label>Email Address</mat-label>
            <input matInput type="email" formControlName="email"
                   name="email" autocomplete="off">
          </mat-form-field>
          <mat-form-field fxFlex class="label-field">
            <mat-label>Label</mat-label>
            <input matInput [matAutocomplete]="emailLabel" autocomplete="off"
                   type="text" formControlName="label" name="label">
            <mat-autocomplete #emailLabel>
              <mat-option *ngFor="let label of emailLabels" [value]="label">
                <span>{{label}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-checkbox formControlName="primary_email" color="primary"
                        (change)="setPrimaryEmailAddress(emailAddressIndex)">Primary</mat-checkbox>
          <button mat-icon-button color="warn" class="md-icon-btn"
                  title="Delete This Email Address"
                  (click)="deleteEmailAddress(emailAddressIndex, person)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="addEmailAddressForm()"
                  class="md-icon-btn" [style.visibility]="lastEmail? 'visible' : 'hidden'">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div class="buffer">
      <mat-icon title="Phones">phone</mat-icon>
    </div>
    <div fxFlex formArrayName="phones" class="overflow-auto">
      <div class="overflow-auto"
           *ngFor="let phone of personForm.get('phones')['controls']; let phoneIndex = index; let lastPhone = last;">
        <div class="multi-form-container" [formGroupName]="phoneIndex">
          <mat-form-field>
            <mat-label>Phone Number</mat-label>
            <input matInput mask="(000) 000-0000" type="text" formControlName="phone_number"
                   name="phone_number" autocomplete="off">
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field fxFlex class="label-field">
              <mat-label>Label</mat-label>
              <input matInput [matAutocomplete]="phoneLabel" type="text" autocomplete="off"
                     formControlName="label" name="label">
              <mat-autocomplete #phoneLabel>
                <mat-option *ngFor="let label of phoneLabels" [value]="label">{{label}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 15px;">
              <mat-checkbox formControlName="primary_phone" color="primary"
                            (change)="setPrimaryPhone(phoneIndex)">Primary</mat-checkbox>
              <button mat-icon-button color="warn" title="Delete This Phone Number"
                      class="md-icon-btn" (click)="deletePhone(person.person_id, phoneIndex)">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="addPhoneForm()"
                      class="md-icon-btn" [style.visibility]="lastPhone? 'visible' : 'hidden'">
                <mat-icon>add_circle</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div class="buffer">
      <mat-icon title="Addresses">place</mat-icon>
    </div>
    <div fxFlex formArrayName="addresses">
      <div *ngFor="let address of personForm.get('addresses')['controls']; let addressIndex = index; let lastAddress = last;">
        <div [formGroupName]="addressIndex" class="width-100 overflow-none">
          <div fxLayout="row" fxLayoutAlign="start center"
               class="width-100 overflow-auto">
            <mat-form-field fxFlex class="label-field">
              <mat-label>Address Label</mat-label>
              <input matInput [matAutocomplete]="addressLabel" type="text" formControlName="label" autocomplete="off">
              <mat-autocomplete #addressLabel>
                <mat-option *ngFor="let label of addressLabels" [value]="label">{{label}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 15px;">
              <mat-checkbox formControlName="primary_address" color="primary"
                            (change)="setPrimaryAddress(addressIndex)">Primary</mat-checkbox>
              <button mat-icon-button color="warn" title="Delete This Address" class="md-icon-btn"
                      (click)="deleteAddress(addressIndex, person.person_id)">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-icon-button color="primary" class="md-icon-btn"
                      [style.visibility]="lastAddress? 'visible' : 'hidden'" (click)="addAddressForm()">
                <mat-icon>add_circle</mat-icon>
              </button>
            </div>
          </div>
          <div>
            <mat-form-field class="width-100">
              <mat-label>Address</mat-label>
              <input matInput type="text" formControlName="address" name="address">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="width-100">
              <mat-label>Address 2</mat-label>
              <input matInput type="text" formControlName="address2" name="address2">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <mat-form-field fxFlex>
              <mat-label>City</mat-label>
              <input matInput type="text" formControlName="city" name="city">
            </mat-form-field>
            <mat-form-field style="width: 75px;">
              <mat-label>State</mat-label>
              <input matInput type="text" formControlName="state" name="state">
            </mat-form-field>
            <mat-form-field style="width: 90px;">
              <mat-label>Zip</mat-label>
              <input matInput type="text" formControlName="zip" name="zip"
                     minlength="5" maxlength="5">
            </mat-form-field>
            <span>-</span>
            <mat-form-field style="width: 75px;">
              <mat-label>Zip-4</mat-label>
              <input matInput type="text" formControlName="zip4" name="zip4"
                     minlength="4" maxlength="4">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <mat-form-field fxFlex="40">
              <mat-label>County</mat-label>
              <input matInput type="text" formControlName="county">
            </mat-form-field>
            <mat-form-field fxFlex="60">
              <mat-label>Congressional District</mat-label>
              <input matInput type="text" formControlName="congressional_district">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div class="buffer">
      <mat-icon title="Notes">assignment</mat-icon>
    </div>
    <div fxFlex>
      <mat-form-field class="width-100">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName="notes" name="notes"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button mat-flat-button color="warn" (click)="cancel(person)">
      <span>Cancel</span>
    </button>
    <button mat-flat-button color="primary" (click)="savePerson(personForm.value)"
            [disabled]="personForm.pristine || disableSave">
      <span>Save</span>
    </button>
  </div>
</div>