import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  userId: string = '';
  singleUseIdActive: boolean = true;
  passwordSet: boolean = false;
  username: string = "";
  password: FormControl = new FormControl();
  confirmPassword: FormControl = new FormControl();

  constructor(private route: ActivatedRoute,
              private router: Router,
              public authSvc: AuthService) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.authSvc.getUserStatusBySingleUseId(this.userId).subscribe((response: any) => {
      this.singleUseIdActive = response.singleUseIdActive;
      if (response.passwordSet) this.passwordSet = response.passwordSet;
    });
  }
  
  setNewUserPassword = (userId: string, password: string) => {
    this.authSvc.setNewUserPassword(userId, password).subscribe((response: any) => {
      if (response.user_id) {
        this.username = response.email;
        this.passwordSet = true;
        this.password.reset();
        this.confirmPassword.reset();
      }
    });
  }
}