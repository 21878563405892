<div class="dialog-header" fxLayout="row" fxLayoutAlign="start center">
  <span class="dialog-title" fxFlex>Action</span>
  <button mat-icon-button *ngIf="viewMode === 'view'"
          [matMenuTriggerFor]="dialogActionMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #dialogActionMenu="matMenu">
    <button mat-menu-item (click)="viewMode = 'edit'">
      <span>Edit Note</span>
    </button>
    <button mat-menu-item (click)="delete(action)">
      <span>Delete Note</span>
    </button>
  </mat-menu>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="viewMode === 'view'">
    <div><span class="bold">Action: </span>{{action.name}}</div>
    <div><span class="bold">Status: </span>{{action.status}}</div>
    <div><span class="bold">Action Lead: </span>{{action.action_lead}}</div>
    <div><span class="bold underline">Notes</span></div>
    <div><span>{{action.notes}}</span></div>
    <button mat-flat-button mat-dialog-close color="warn">
      <span>Close</span>
    </button>
  </ng-container>
  <div *ngIf="viewMode === 'edit'" [formGroup]="actionForm">
    <div>
      <mat-form-field class="width-100">
        <mat-label>Action</mat-label>
        <input matInput type="text" formControlName="name" name="name" autocomplete="off">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="width-100">
        <mat-label>Action Lead</mat-label>
        <input matInput name="action_lead" formControlName="action_lead"
               autocomplete="off">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <input matInput name="status" formControlName="status"
               [matAutocomplete]="statusAuto" autocomplete="off">
      </mat-form-field>
      <mat-autocomplete #statusAuto>
        <mat-option *ngFor="let opt of statusOptions" [value]="opt">
          <span>{{opt}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-form-field>
        <mat-label>Date Completed</mat-label>
        <input matInput name="date_completed" formControlName="date_completed"
               [matDatepicker]="datepicker" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="width-100">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName="notes" name="notes"></textarea>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
      <button mat-flat-button mat-dialog-close color="warn">
        <span>Cancel</span>
      </button>
      <button mat-flat-button color="primary" (click)="save(actionForm.value)"
              [disabled]="actionForm.pristine">
        <span>Save</span>
      </button>
    </div>
  </div>
</div>