import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PeopleService } from '../people.service';

@Component({
  selector: 'app-deduplicator',
  templateUrl: './deduplicator.component.html',
  styleUrls: ['./deduplicator.component.scss']
})
export class DeduplicatorComponent implements OnInit {
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({pageIndex: 0, pageSize: 40});
  // tagFilter$: BehaviorSubject<NewFilter> = new BehaviorSubject<NewFilter>({} as NewFilter);
  filterSearch: FormControl = new FormControl();
  filterSearchResults: any[] = [];
  duplicateList: any[] = [];
  duplicateCount: number;
  duplicates: any[] = [];
  primary$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  unsavedPrimary$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  index$: Subject<number> = new Subject<number>();
  
  constructor(private peopleSvc: PeopleService) {}
  
  ngOnInit() {
    this.filter$.pipe(
      filter((filter: any) => typeof filter.pageSize !== "undefined" && typeof filter.pageIndex !== "undefined")
    ).subscribe((filter: any) => {
      this.peopleSvc.getDuplicates(filter.pageIndex, filter.pageSize).subscribe((response: any) => {
        this.duplicateList = response.items;
        this.duplicateCount = response.count;
        this.index$.next(0);
      });
    });
    this.index$.subscribe((index: number) => {
      this.getDuplicatesByName(this.duplicateList[index].first_name, this.duplicateList[index].last_name);
    });
  }
  
  refreshDuplicates = (filter: any, index?: number) => {
    this.filter$.next(filter);
  }
  removePerson = (index: number, duplicates: any[], listIndex: number) => {
    this.duplicates = duplicates.filter((item: any, idx: number) => idx !== index);
    if (this.duplicates.length === 1) this.refreshDuplicates(listIndex);
  }
  getDuplicatesByName = (first: string, last: string) => {
    this.peopleSvc.getDuplicatesByName(first, last).subscribe((response: any[]) => {
      this.duplicates = response;
      this.primary$.next(response[0]);
    });
  }
  updatePerson = (person: any, primary: any) => {
    let index: number = this.duplicates.findIndex((pers: any) => pers.id === person.id);
    this.duplicates[index] = person;
    if (primary.id === person.id) this.primary$.next(person);
  }
    
  /* linkPersonToTag = (person: any, tag: any) => {
    this.itemSvc.linkItemToItem(tag, person).subscribe((response: any) => {
      person.tags.push(response);
      this.primary$.next(person);
    });
  } */
}