import { Component, OnInit } from '@angular/core';

import { BehaviorSubject, withLatestFrom } from 'rxjs';
import { filter, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ImporterService } from '../importer.service';

@Component({
  selector: 'app-import-desktop',
  templateUrl: './import-desktop.component.html',
  styleUrls: ['./import-desktop.component.scss']
})
export class ImportDesktopComponent implements OnInit {
  importActionSteps: any[] = [
    {name: "Upload the Spreadsheet File to the Server", complete: true},
    {name: "Convert the Spreadsheet Column Headers to Database Table Field Names", complete: true},
    {name: "Create Temporary Database Table for Imporations", complete: true},
    {name: "Check for and Remove any Invalid Characters from the Spreadsheet", complete: false},
    {name: "Produce metrics of Invalid Character Removal", complete: false},
    {name: "Create Error Logging and Reporting", complete: false},
    {name: "Import the Spreadsheet Data Into a Temporary Database Table", complete: true},
    {name: "Map Imported List Column Headers To Destination Table Fields", complete: false},
    {name: "Capture Mappings Over Time and Automate the Mapping Process", complete: false},
    {name: "Prompt the User to Verify the Accuracy of Automated Pappings Prior to Importation", complete: false},
    {name: "Analyze and Clean Imported Data for Final Insertion", complete: false},
    {name: "Import Cleansed Data into the Designated Destination Database Table", complete: false},
    {name: "Tag Imported Records", complete: false},
    {name: "Deduplicate Imported Records", complete: false},
  ];
  activeFile$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  activeWorksheet$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  
  fileSearchResults: any[] = [];
  
  spreadsheetColumnHeaders: string[] = [];
  tempTableFields: any[] = [];
  
  destinationTableOptions: any[] = [
    {label: "People", tableName: "people"},
    {label: "Email Addresses", tableName: "email_addresses"},
    {label: "Phone Numbers", tableName: "phones"},
    {label: "Addresses", tableName: "addresses"},
    {label: "Donations", tableName: "donations"},
    {label: "Notes", tableName: "reviews"},
    {label: "Contact Log", tableName: "contact_log"}
  ];
  destinationTableFields: any[] = [];
  tableDisplay: any = this.destinationTableOptions[0];
  
  selectedSourceField: any = {};
  
  importedFileFieldMappings: any[];
  fieldMap: any[] = [];

  constructor(private importSvc: ImporterService) { }

  ngOnInit(): void {
    this.importSvc.getFiles({pageSize: 10, orderBy: 'date_created DESC'}).subscribe((response: any[]) => {
      this.fileSearchResults = response;
    });
    this.importSvc.getDestinationFields().subscribe((response: any) => {
      this.destinationTableOptions = this.destinationTableOptions.map((table: any) => {
        table.columns = response.filter((cols: any) => cols.TABLE_NAME === table.tableName);
        return table;
      });
    });
    this.activeWorksheet$.pipe(
      filter((worksheet: any) => !!worksheet.id),
      withLatestFrom(this.activeFile$),
      switchMap(([worksheet, activeFile]: [any, any]) => this.importSvc.getWorksheetData(activeFile.id, worksheet.id))
    ).subscribe((response: any) => this.loadWorksheetData(response));
  }
  
  loadWorksheetData = (data: any) => {
    this.spreadsheetColumnHeaders = data.sheetHeaders;
  }
  
  uploadFile = (event) => {
    const file: File = event.target.files[0];
    
    if (file) {
      this.importSvc.uploadFile(file).subscribe((response: any) => {
        this.activeFile$.next(response);
      });
    }
  }
  
  displayNull = () => {
    return "";
  }
  toggleTable = (table: any, activeTable: any) => {
    if (table.tableName === activeTable.tableName) {
      this.tableDisplay = {};
    } else {
      this.tableDisplay = table;
    }
  }
}