<ng-container *ngIf="displayMode === 'view' && emailAddress">
  <a [href]="'mailto:' + emailAddress.email"><span>{{emailAddress.email}}</span></a>
  <span>
    <span *ngIf="emailAddress.label || emailAddress.primary_email">(</span>
    <span *ngIf="emailAddress.label">{{emailAddress.label}}</span>
    <span *ngIf="emailAddress.label && emailAddress.primary_email"> - </span>
    <span *ngIf="emailAddress.primary_email">Primary</span>
    <span *ngIf="emailAddress.label || emailAddress.primary_email">)</span>
  </span>
  <div fxFlex></div>
  <button mat-icon-button class="email-menu sm-icon-btn" [matMenuTriggerFor]="emailMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #emailMenu="matMenu">
    <button mat-menu-item (click)="displayMode = 'edit'">
      <span>Edit '{{emailAddress.email}}'</span>
    </button>
    <button mat-menu-item *ngIf="!emailAddress.primary_email" (click)="setPrimary(emailAddress, person)">
      <span>Set '{{emailAddress.email}}' as Primary Email Address</span>
    </button>
    <button mat-menu-item (click)="delete(emailAddress, person)">
      <span>Delete '{{emailAddress.email}}'</span>
    </button>
  </mat-menu>
</ng-container>
<div class="email-form-column" *ngIf="displayMode === 'edit'"
     [formGroup]="emailAddressForm">
  <div class="email-form">
    <mat-form-field class="email-form-field">
      <mat-label>Email Address</mat-label>
      <input matInput type="email" formControlName="email" name="email">
    </mat-form-field>
    <mat-form-field class="label-form-field">
      <mat-label>Label</mat-label>
      <input matInput [matAutocomplete]="emailLabel" type="text"
             formControlName="label" name="label">
      <mat-autocomplete #emailLabel>
        <mat-option *ngFor="let label of emailLabels" [value]="label">
          <span>{{label}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-checkbox formControlName="primary_email" color="primary" class="margin-below">
      <span>Primary</span>
    </mat-checkbox>
    <button mat-icon-button color="primary" title="Save new entry"
            [disabled]="emailAddressForm.pristine" class="margin-below"
            (click)="save(emailAddressForm.value, person)">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button color="warn" title="Cancel new entry" class="margin-below"
            (click)="reset(emailAddress, person)">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div *ngIf="duplicates.length > 0" fxLayout="column" class="duplicate-email-container">
    <div><span class="header">Duplicate Emails</span></div>
    <div *ngFor="let duplicate of duplicates" fxLayout="row" fxLayoutAlign="start center">
      <span class="link" (click)="navigateToContact(emailAddress, duplicate)">{{peopleSvc.getPersonName(duplicate)}}</span>
      <span> - {{duplicate.email}}</span>
      <button mat-icon-button class="sm-icon-btn" (click)="openQuickView(duplicate)">
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>
  </div>
</div>