import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, of } from 'rxjs';
import { filter, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { DonationsService } from '../donations.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { AuthService } from '@modules/auth/auth.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { DonationDialogComponent } from '@modules/donor-management/donation-dialog/donation-dialog.component';
import { Donation } from '@modules/donor-management/donation.model';

@Component({
  selector: 'app-donations-table',
  templateUrl: './donations-table.component.html',
  styleUrls: ['./donations-table.component.scss']
})
export class DonationsTableComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  donations$: BehaviorSubject<Donation[]> = new BehaviorSubject<Donation[]>([]);
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 5,
    pageIndex: 0,
    personId: 0,
    orderBy: [{field: `donations.date`, order: `DESC`}]
  });
  pageSizeOptions: number[] = [5,10,15,20];
  totalRecords$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalPages: number = 0;
  totalPledged: number = 0;
  totalPaid: number = 0;

  constructor(private dialogSvc: DialogService,
              private dialog: MatDialog,
              private donationSvc: DonationsService,
              private authSvc: AuthService,
              private peopleSvc: PeopleService) { }

  ngOnInit(): void {
    this.person$.pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([person, filter]: [any, any]) => {
      if (person.person_id) {
        filter.personId = person.person_id;
        this.filter$.next(filter);
      } else {
        this.donations$.next([]);
        this.totalRecords$.next(0);
      }
    });
    this.filter$.pipe(
      filter((filter: any) => !!filter.personId),
      switchMap((filter: any) => this.donationSvc.getFilteredDonations(filter))
    ).subscribe((response: any) => {
      this.donations$.next(response.items);
      this.totalRecords$.next(response.count);
      this.totalPledged = response.totalPledged;
      this.totalPaid = response.totalPaid;
    });
    this.filter$.pipe(
      distinctUntilChanged((prev: any, curr: any) => prev.pageSize === curr.pageSize),
      withLatestFrom(this.totalRecords$)
    ).subscribe(([filter, totalRecords]: [any, number]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
    this.totalRecords$.pipe(withLatestFrom(this.filter$))
    .subscribe(([totalRecords, filter]: [number, any]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
  }
  
  updatePageSize = (pageSize: number, filter: any) => {
    filter.pageSize = pageSize;
    this.filter$.next(filter);
  }
  pageIncrement = (filter: any, pageIncrement: number) => {
    filter.pageIndex = filter.pageIndex + pageIncrement;
    this.filter$.next(filter);
  }
  setPage = (page: number, filter: any) => {
    filter.pageIndex = page;
    this.filter$.next(filter);
  }
  
  delete = (donation: any, donationFilter: any) => {
    let title: string = "Confirm Donation Delete",
        msg: string = `Are you sure you want to delete the donation?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.donationSvc.delete(donation.donation_id))
    ).subscribe((response: any) => this.filter$.next(donationFilter));
  }
  
  openDialog = (donation: any, person: any, filter: any, viewMode: string) => {
    let data = {donation: donation, person: person, filter: filter, viewMode: viewMode},
        dialogRef: MatDialogRef<DonationDialogComponent> = this.dialog.open(DonationDialogComponent, {data: data, maxWidth: "750px"});
    
    dialogRef.afterClosed().subscribe((response: boolean) => this.filter$.next(filter));
  }
  
  sumArrayValues = (arr: any[], prop: string) => {
    if (typeof arr !== "undefined") return arr.reduce((a,b) => { return a + b[prop]}, 0);
    return "";
  }
}