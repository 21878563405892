import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-spreadsheet-preview',
  templateUrl: './spreadsheet-preview.component.html',
  styleUrls: ['./spreadsheet-preview.component.scss']
})
export class SpreadsheetPreviewComponent implements OnInit {
  @Input('selected-sheet') selectedSheet$: Subject<any> = new Subject<any>();
  @Output('import-sheet') importSheet: EventEmitter<any> = new EventEmitter<any>();
  sheetPreviewHeaders: string[] = [];
  sheetPreviewData: any[] = [];
  previewRows: number = 20;
  
  ngOnInit() {
    this.selectedSheet$.subscribe((selectedSheet: any) => {
      console.log("SELECTED SHEET: ", selectedSheet);
    });
  }
}