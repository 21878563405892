<ng-container *ngIf="{sheet: (selectedSheet$ | async)} as selected">
  <div id="column-1">
    <div id="upload-button" (click)="fileUpload.click()">
      <div *ngIf="!file" class="action-text">
        <span>Click to Upload Your File to the Server</span>
      </div>
      <input #fileUpload type="file" class="file-input" style="display:none;"
             (change)="onFileSelected($event)">
      <div *ngIf="file">
        <span class="bold">Uploaded Active File:&nbsp;</span>
        <span>{{(file || {}).name}}</span>
      </div>
    </div>
    <div (click)="selectedView = 'Preview'">
      <div class="action-text underline" style="margin-bottom: 10px;">
        <span>Preview the Worksheet</span>
      </div>
      <div *ngFor="let worksheet of (file || {}).worksheets" class="worksheet"
           [ngClass]="{'selected': worksheet.id === (selected.sheet || {}).id}"
           (click)="selectSheet(worksheet, selectedSheet)">
        <span>{{worksheet.sheet_name}}</span>
      </div>
    </div>
    <div (click)="selectedView = 'Field Mapper'">
      <div class="header"><span>Map Import Field to Destination Table Fields</span></div>
    </div>
    <div>
      <div class="header"><span>Verify and Clean Data</span></div>
    </div>
    <div>
      <div class="header"><span>Run Deduplication</span></div>
    </div>
    <div>
      <div class="header"><span>Import and Tag Data</span></div>
    </div>
    <div>
      <span>Past Uploaded Files with Status</span>
    </div>
  </div>
  <div id="column-2">
    <app-spreadsheet-preview #spreadsheetPreview
                             *ngIf="selectedView === 'Preview'"
                             [selected-sheet]="selectedSheet$"
                             (import-sheet)="importWorksheet((selected.sheet || {}).id)">
    </app-spreadsheet-preview>
    <app-field-mapper *ngIf="selectedView === 'Field Mapper'"
                      [sheet]="sheet$">
    </app-field-mapper>
  </div>
</ng-container>