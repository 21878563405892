import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export class ActivistNote {
  review_id: string;
  title: string;
  prepared_by: string;
  date_sent: string;
  person_link: number;
  content: string;
  date_created: string;
  user_created: string;
  last_updated: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  url: string = "https://slingofdavid.com:3000/";

  constructor(private http: HttpClient) { }
  
  createNote = (note: any) => {
    return this.http.post(this.url + "notes", note);
  }
  deleteNote = (id: number) => {
    return this.http.delete(this.url + `notes`, {params: {id: id}});
  }
  updateNote = (note: any) => {
    return this.http.put(this.url + "notes", note);
  }
  
  getNotes = () => {
    return this.http.get(this.url + "notes");
  }
  getFilteredNotes = (filter: any) => {
    return this.http.post(this.url + "notes/filter", filter);
  }
  getReport = (filter: any) => {
    return this.http.post(this.url + "notes/report", filter, {responseType: 'blob'});
  }
  
  getStaffOptions = () => {
    return this.http.get(this.url + "notes/staff-options");
  }
  getUserReportLog = () => {
    return this.http.get(this.url + "notes/report-log");
  }
}