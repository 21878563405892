import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { PersonProfilesModule } from '@modules/person-profiles/person-profiles.module';
import { DonorManagementModule } from '@modules/donor-management/donor-management.module';

import { DeduplicationDesktopComponent } from './deduplication-desktop/deduplication-desktop.component';
import { DuplicateListComponent } from './duplicate-list/duplicate-list.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImportDuplicateDisplayComponent } from './import-duplicate-display/import-duplicate-display.component';
import { ImportFilesComponent } from './import-files/import-files.component';
import { ScanTableCellComponent } from './scan-table-cell/scan-table-cell.component';
import { TemporaryTableDialogComponent } from './temporary-table-dialog/temporary-table-dialog.component';
import { ValidTableCellComponent } from './valid-table-cell/valid-table-cell.component';


@NgModule({
  declarations: [
    DeduplicationDesktopComponent,
    DuplicateListComponent,
    FileUploadComponent,
    ImportDuplicateDisplayComponent,
    ImportFilesComponent,
    ScanTableCellComponent,
    TemporaryTableDialogComponent,
    ValidTableCellComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatDividerModule,
    MatDialogModule,
    DragDropModule,
    PersonProfilesModule,
    DonorManagementModule,
  ]
})
export class FileImporterModule { }