<div class="container-fluid">
  <div class="row">
    <div class="col-2">
      <div class="w-100 d-flex flex-row">
        <form [formGroup]="listForm" class="flex-grow-1">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Create New List</mat-label>
            <input matInput formControlName="list" name="list">
            <button matSuffix mat-icon-button (click)="listForm.reset()">
              <mat-icon>close</mat-icon>
            </button>
            <button matSuffix mat-icon-button color="primary"
                    [disabled]="!listForm.get('list').value"
                    (click)="createList(listForm.value)">
              <mat-icon>save</mat-icon>
            </button>
          </mat-form-field>
        </form>
        <button mat-icon-button color="warn" (click)="deleteList(selectedList)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <ul class="listbox ul-border">
        <li [ngClass]="{'selected': selectedList.list_id === lst.list_id}"
            *ngFor="let lst of listSvc.lists | async" (click)="selectedList = lst;">
          <span>{{lst.list}}</span>
        </li>
      </ul>
    </div>
    <div class="col-2">
      <app-list-people-control></app-list-people-control>      
    </div>
    <div class="col-2">
      <app-list-people-control></app-list-people-control>      
    </div>
    <div class="col-2">
      <app-list-people-control></app-list-people-control>      
    </div>
  </div>
</div>