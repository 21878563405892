import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-scan-table-cell',
  templateUrl: './scan-table-cell.component.html',
  styleUrls: ['./scan-table-cell.component.scss']
})
export class ScanTableCellComponent implements OnInit {
  @Input("cell-data") cellData: string;
  @Input("key") cellKey: string;
  @Output() saveCellChanges = new EventEmitter<any>();
  regexStr = /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^\x00-\x7F])/g;
  
  viewMode: string = 'view';
  cellForm: FormGroup;

  constructor(private fb: FormBuilder) { }
  
  // CHARACTER SCAN FUNCTIONS *****************************************************
  badChars = (text) => {
    let regex = new RegExp(this.regexStr);
    return regex.test(text);
  }
  
  // ACTION FUNCTIONS *************************************************************
  cancel = () => {
    this.cellForm.reset({cellValue: this.cellData});
    this.viewMode = 'view';
  }
  save = (cellValue) => {
    let regex = new RegExp(this.regexStr);
    
    if (!regex.test(cellValue)) this.saveCellChanges.emit(cellValue);
  }
  replaceAll = () => {
    let obj = {};
    obj[this.cellKey] = this.cellData.replace(this.regexStr, "");
    this.saveCellChanges.emit(obj);
  }

  ngOnInit(): void {
    this.cellForm = this.fb.group({
      cellValue: [this.cellData]
    });
  }

}