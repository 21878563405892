import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-edit-user-page',
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.scss']
})
export class EditUserPageComponent implements OnInit {
  user$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private route: ActivatedRoute,
              private authSvc: AuthService) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((map: any) => this.authSvc.getUser(map.get('id')))
    ).subscribe((user: any) => {
      this.user$.next(user);
    });
  }

}