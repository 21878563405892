import { Injectable } from '@angular/core';

import * as moment from "moment";
import 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DatetimefxService {

  constructor() { }
  
  mysql_date = (date) => {
    let new_date = new Date(moment(date).format("YYYY-MM-DD")).toUTCString();
    return moment.tz(new_date, "America/New_York").format("YYYY-MM-DD");
  }
  mysql_datetime = (datetime) => {
    let new_datetime = new Date(datetime).toUTCString();
    return moment.tz(new_datetime, "America/New_York").format("YYYY-MM-DD HH:mm:ss");
  }
  local_datetime = (datetime: string) => {
    return moment.tz(datetime, "America/New_York").format("YYYY-MM-DD HH:mm:ss");
  }
}