import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { withLatestFrom, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { PeopleService } from '@modules/person-profiles/people.service';
import { ContactLogService } from '../contact-log.service';

@Component({
  selector: 'app-solicitor-dashboard',
  templateUrl: './solicitor-dashboard.component.html',
  styleUrls: ['./solicitor-dashboard.component.scss']
})
export class SolicitorDashboardComponent implements OnInit {
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    solicitors: [],
    pageIndex: 0,
    pageSize: 20
  });
  filterForm: FormGroup = this.fb.group({
    startDate: [''],
    endDate: ['']
  });
  contactLogs: any[] = [];
  timeframeOptions: any[] = [
    {label: "Today", startDate: moment().format('YYYY-MM-DD'), endDate: ''},
    {label: "This Week", startDate: moment().startOf('week').format('YYYY-MM-DD'), endDate: ''},
    {label: "Last 7 Days", startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), endDate: ''},
    {label: "This Month", startDate: moment().startOf('month').format('YYYY-MM-DD'), endDate: ''},
    {label: "Last 30 Days", startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'), endDate: ''},
    {label: "All Time", startDate: '', endDate: ''}
  ];
  panelTimeframe$: BehaviorSubject<any> = new BehaviorSubject<any>(this.timeframeOptions[0]);
  
  totalRecords: number = 0;
  
  constructor(protected peopleSvc: PeopleService,
              private contactLogSvc: ContactLogService,
              private fb: FormBuilder) {}
  
  ngOnInit() {
    this.peopleSvc.loadSolicitors().pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([solicitors, filter]: [any[], any]) => {
      filter.solicitors = solicitors;
      this.filter$.next(filter);
      this.filter$.pipe(
        switchMap((filter: any) => this.contactLogSvc.getContactLog(filter))
      ).subscribe((response: any) => {
        this.contactLogs = response.items;
        this.totalRecords = response.count;
      });
    });
    this.panelTimeframe$.subscribe((timeframe: any) => {
      this.filterForm.patchValue(timeframe);
    });
  }
  
  toggleAllSolicitors = (solicitors: any[], filter: any, filter$: ReplaySubject<any>) => {
    filter$.next(((filter || {}).solicitors || []).length < solicitors.length?solicitors:[]);
  }
  clearSolicitors = (filter: any, filter$: ReplaySubject<any>) => {
    filter.solicitors = [];
    filter$.next(filter);
  }
  toggleSolicitor = (solicitor: any, filter: any, filter$: ReplaySubject<any>) => {
    if (((filter || {}).solicitors || []).some((sol: any) => sol.user_id === solicitor.user_id)) {
      ((filter || {}).solicitors || []).filter((sol: any) => sol.user_id !== solicitor.user_id);
    } else {
      if (typeof filter.solicitors === "undefined") filter.solicitors = [];
      filter.solicitors.push(solicitor);
    }
    filter$.next(filter);
  }
  solicitorSelected = (solicitor: any, filter: any) => {
    return ((filter || {}).solicitors || []).some((sol: any) => sol.user_id === solicitor.user_id);
  }
  checkSolicitorFilter = (filter: any, solicitors: any[]) => {
    return ((filter || {}).solicitors || []).length < solicitors.length;
  }
}