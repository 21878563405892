<ng-container [formGroup]="userForm" *ngIf="user$ | async as user">
  <div>
    <div>
      <mat-form-field style="width: 150px;">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="first_name" name="first_name" autocomplete="off">
      </mat-form-field>
      <mat-form-field style="width: 150px;">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="last_name" name="last_name" autocomplete="off">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 300px;">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" name="email" autocomplete="off">
        <button matSuffix mat-icon-button (click)="userForm.get('email').patchValue(''); duplicateUsername = false;">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div *ngIf="!user.user_id">
      <mat-form-field class="w-100">
        <mat-label>Confirm Email</mat-label>
        <input matInput type="text" formControlName="confirm_email"
               name="confirm_email" autocomplete="off">
        <button matSuffix mat-icon-button (click)="confirmEmail.reset();">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div>
      <span *ngIf="userForm.get('email').errors && userForm.get('email').touched">{{userForm.get('email').errors.required?"Username is required.":""}}</span>
      <span *ngIf="userForm.get('email').errors && userForm.get('email').touched">{{userForm.get('email').errors.email?"Username must be an email.":""}}</span>
      <span *ngIf="duplicateUsername">This username is already taken.</span>
    </div>
    <div>
      <mat-form-field>
        <mat-label>User Group</mat-label>
        <mat-select formControlName="user_group">
          <mat-option *ngFor="let group of userGroups" [value]="group">
            <span>{{group}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Regions</mat-label>
        <input matInput formControlName="regions" name="regions">
      </mat-form-field>
    </div>
    <div><mat-checkbox formControlName="solicitor">Solicitor</mat-checkbox></div>
  </div>
  <div>
    <div><mat-checkbox formControlName="create_people">Create People</mat-checkbox></div>
    <div><mat-checkbox formControlName="edit_people">Edit People</mat-checkbox></div>
    <div><mat-checkbox formControlName="delete_people">Delete People</mat-checkbox></div>
    <div><mat-checkbox formControlName="view_donations">View Donations</mat-checkbox></div>
    <div><mat-checkbox formControlName="create_donations">Create Donations</mat-checkbox></div>
    <div><mat-checkbox formControlName="edit_donations">Edit Donations</mat-checkbox></div>
    <div><mat-checkbox formControlName="delete_donations">Delete Donations</mat-checkbox></div>
    <div><mat-checkbox formControlName="create_lists">Create Lists</mat-checkbox></div>
    <div><mat-checkbox formControlName="edit_lists">Edit Lists</mat-checkbox></div>
    <div><mat-checkbox formControlName="delete_lists">Delete Lists</mat-checkbox></div>
    <div><mat-checkbox formControlName="download_lists">Download Lists</mat-checkbox></div>
  </div>
  <div>
    <div><mat-checkbox formControlName="view_users">View Users</mat-checkbox></div>
    <div><mat-checkbox formControlName="create_users">Create Users</mat-checkbox></div>
    <div><mat-checkbox formControlName="edit_users">Edit Users</mat-checkbox></div>
    <div><mat-checkbox formControlName="delete_users">Delete Users</mat-checkbox></div>
    <div><mat-checkbox formControlName="draft_emails">Draft Emails</mat-checkbox></div>
    <div><mat-checkbox formControlName="send_emails">Send Emails</mat-checkbox></div>
  </div>
  <div class="button-row">
    <button mat-flat-button color="warn" (click)="cancel(user)">
      <span>Cancel</span>
    </button>
    <button mat-flat-button color="primary"
            [disabled]="userForm.pristine || userForm.invalid || (!user.user_id && (duplicateUsername || userForm.get('email').value !== userForm.get('confirm_email').value))"
            (click)="saveUser(userForm.value)">
      <span>Save</span>
    </button>
  </div>
</ng-container>