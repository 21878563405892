<ng-container *ngIf="{data: (sheet$ | async)} as sheet" cdkDropListGroup>
  <div>
    <div><span class="header">Spreadsheet Column Headers</span></div>
    <div class="sheet-header-list" cdkDropListSortingDisabled
         cdkDropList [cdkDropListData]="((sheet.data || {}).sheet || {}).columnNames">
      <div *ngFor="let hdr of ((sheet.data || {}).sheet || {}).columnNames"
           cdkDrag [cdkDragData]="hdr">
        <span>{{hdr.sheet_column_name}}</span>
        <div *cdkDragPlaceholder>
          <span>&nbsp;--->&nbsp;{{hdr.sheet_column_name}}</span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div><span class="header">Destination Table Fields</span></div>
    <div class="table-list">
      <div *ngFor="let table of tables" (click)="selectedTable$.next(table)"
           [ngClass]="{'selected': table.name === (selectedTable$ | async).name}">
        <span>{{table.label}}</span>
      </div>
    </div>
    <div>
      <div *ngFor="let field of destinationFields" cdkDropList
           [cdkDropListData]="field" class="destination-field">
        <span>{{field.COLUMN_NAME}}</span>
      </div>
    </div>
  </div>
  <div>
    <div><span class="header">Field Mappings</span></div>
  </div>
</ng-container>