<ng-container *ngIf="filter$ | async as filter">
  <app-pagination [total-records]="donorCount" item-name="Donors"
                  [page-index]="filter.pageIndex" [page-size]="filter.pageSize"
                  (page-increment)="pageChange($event, filter)"
                  (change-page-size)="updatePageSize($event, filter)">
  </app-pagination>
  <table>
    <thead>
      <tr>
        <th><span>Q</span></th>
        <th><span>Donor</span></th>
        <th class="currency-header"><span>Lifetime Total</span></th>
        <th class="currency-header"><span>2021</span></th>
        <th class="currency-header"><span>2022</span></th>
        <th class="currency-header"><span>2023</span></th>
        <th class="currency-header"><span>2024</span></th>
        <th class="staff-header"><span>Staff Lead</span></th>
        <th class="last-contact-header"><span>Last Contact</span></th>
        <th class="contact-log-header"><span>Total 2024 Contacts</span></th>
        <th class="notes"><span>Annual Planning Donor Notes</span></th>
        <th class="action-menu"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="2" class="total-row-header"><span>TOTAL:</span></td>
        <td class="currency-cell"><span>{{total | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="currency-cell"><span>{{yearlyTotals.year2021 | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="currency-cell"><span>{{yearlyTotals.year2022 | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="currency-cell"><span>{{yearlyTotals.year2023 | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="currency-cell"><span>{{yearlyTotals.year2024 | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td colspan="2"></td>
        <td></td>
        <td class="notes"></td>
        <td></td>
      </tr>
      <tr>
        <td><span></span></td>
        <td colspan="11">
          <div class="donor-search-form-field">
            <input matInput placeholder="Add Donor" [matAutocomplete]="addDonorAuto"
                   [formControl]="addDonorControl">
            <button mat-icon-button class="sm-icon-btn" (click)="addDonorControl.reset('')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </td>
      </tr>
      <tr *ngFor="let donor of donors; index as index">
        <td class="pointer">
          <span [matMenuTriggerFor]="quarterMenu">{{donor.quarter}}</span>
          <mat-menu #quarterMenu="matMenu">
            <button *ngFor="let quarter of quarters" mat-menu-item
                    (click)="update({quarter: quarter}, donor.id, filter)">
              <span>{{quarter}}</span>
            </button>
          </mat-menu>
        </td>
        <td>
          <a [href]="'/activists/' + donor.person_id" target="_blank">
            <span>{{donor.first_name + " " + donor.last_name}}</span>
          </a>
        </td>
        <td><span>{{donor.total_giving | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="currency-cell"><span>{{donor.total2021 | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="currency-cell"><span>{{donor.total2022 | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="currency-cell"><span>{{donor.total2023 | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="currency-cell"><span>{{donor.total2024 | currency: 'USD' : 'symbol' : '1.0-0'}}</span></td>
        <td class="staff-cell">
          <div class="staff-form-control" [matMenuTriggerFor]="staffLeadMenu">
            <div [style.color]="donor.staff_lead?'':'gray'">
              <span>{{donor.staff_lead || 'None'}}</span>
            </div>
            <mat-icon style="font-size: 24px;">arrow_drop_down</mat-icon>
          </div>
          <mat-menu #staffLeadMenu="matMenu">
            <button mat-menu-item (click)="updateStaffLead('', donor, index)">
              <span>None</span>
            </button>
            <button mat-menu-item *ngFor="let staff of peopleSvc.solicitors$ | async"
                    (click)="updateStaffLead(staff.name, donor, index)">
              <span>{{staff.name}}</span>
            </button>
          </mat-menu>
        </td>
        <td class="last-contact-cell" [title]="formatDate(donor.lastContact)">
          <div><span class="time-since">{{calculateDays(donor.lastContact)}}</span></div>
        </td>
        <td class="contact-log-cell"><span>{{donor.contactLogCount | number: '1.0-0'}}</span></td>
        <td class="notes">
          <app-annual-planning-notes-table-cell (update)="itemUpdated($event)"
                                                [filter]="filter$" [notes]="donor.planning_notes"
                                                [year]="year" [quarter]="quarter" [id]="donor.id">
          </app-annual-planning-notes-table-cell>
        </td>
        <td class="action-menu">
          <button mat-icon-button class="sm-icon-btn"
                  [matMenuTriggerFor]="donorActions">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #donorActions="matMenu">
            <button mat-menu-item (click)="removeDonor(donor.id, donor.first_name + ' ' + donor.last_name, filter)">
              <span>Remove {{donor.first_name + " " + donor.last_name}} from Planning</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
  <mat-autocomplete (optionSelected)="addDonorToAnnualPlanning($event.option.value.person_id, filter)"
                    autoActiveFirstOption #addDonorAuto="matAutocomplete"
                    [displayWith]="displayNull">
    <mat-option *ngIf="searching">
      <div class="spinner-option">
        <mat-spinner diameter="20"></mat-spinner>
        <span>Searching...</span>
      </div>
    </mat-option>
    <mat-option *ngFor="let person of donorSearchResults" [value]="person">
      <div class="ellipsis">
        <span>{{peopleSvc.getPersonName(person)}}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
</ng-container>