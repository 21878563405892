<ng-container *ngIf="unsavedPrimary$ | async as unsavedPrimary">
  <div *ngIf="primary$ | async as primary" class="duplicate-frame"
       [ngClass]="{'unsaved': primary.person_id === person.person_id && unsavedPrimary.person_id, 'primary': primary.person_id === person.person_id}">
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
      <div fxFlex fxLayout="row" fxLayoutGap="5px" *ngIf="primary.person_id !== person.person_id">
        <button mat-flat-button color="primary" (click)="setPrimary.emit(person)"
                 [disabled]="primary.person_id === person.person_id">
          <span>Set As Primary</span>
        </button>
        <button mat-flat-button color="warn" [disabled]="primary.person_id === person.person_id"
                (click)="deletePerson(person)">
          <span>Delete</span>
        </button>
      </div>
      <div fxFlex fxLayout="row" fxLayoutGap="5px" *ngIf="primary.person_id === person.person_id">
        <button mat-flat-button color="warn" (click)="clearUnsaved()"
                [disabled]="!unsavedPrimary.person_id">
          <span>Cancel</span>
        </button>
        <button mat-flat-button color="primary" (click)="saveDuplicate(unsavedPrimary)"
                [disabled]="!unsavedPrimary.person_id">
          <span>Save</span>
        </button>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="duplicateMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #duplicateMenu="matMenu">
        <button mat-menu-item (click)="openPersonDialog(person)">
          <span>Edit Person</span>
        </button>
        <button mat-menu-item (click)="addNonDuplicate(primary.person_id, person.person_id)">
          <span>Mark as Not a Duplicate with the Primary</span>
        </button>
      </mat-menu>
    </div>
    <div class="fields">
      <div *ngFor="let field of fields">
        <span class="bold">{{field.label}}:&nbsp;</span>
        <span (click)="update(field, person, primary, unsavedPrimary, unsavedPrimary$, false)"
              [ngClass]="{'different-value pointer': primary.person_id && primary[field.value] !== person[field.value] && unsavedPrimary[field.value] !== person[field.value], 'unsaved pointer': person.person_id === primary.person_id && unsavedPrimary[field.value], 'pointer': primary.person_id && primary[field.value] !== person[field.value] && unsavedPrimary[field.value] !== person[field.value]}"
              [matMenuTriggerFor]="(field.value === 'notes' && primary.person_id !== person.person_id)?fieldReplace:null">
          <span *ngIf="field.date">{{(unsavedPrimary[field.value] || person[field.value]) | date: 'short'}}</span>
          <span *ngIf="!field.date">{{(primary.person_id === person.person_id && unsavedPrimary[field.value])?unsavedPrimary[field.value]:person[field.value]}}</span>
        </span>
        <mat-menu #fieldReplace="matMenu">
          <button mat-menu-item (click)="addText(primary, unsavedPrimary, field.value, person[field.value])">
            <span>Add</span>
          </button>
          <button mat-menu-item (click)="update(field, person, primary, unsavedPrimary, unsavedPrimary$, true)">
            <span>Replace</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="emails">
      <span class="bold">Email Addresses</span>
      <div *ngFor="let email of person.email_addresses" (click)="addItem(email, 'email_addresses', unsavedPrimary, primary.person_id)">
        <span [ngClass]="{'different-value pointer': duplicateEmails(email, primary, unsavedPrimary)}">{{email.email}}</span>
        <span *ngIf="email.label || email.primary_email">
          <span>&nbsp;(</span>
          <span>{{email.label}}</span>
          <span *ngIf="email.label && email.primary_email">&nbsp;-&nbsp;</span>
          <span *ngIf="email.primary_email">Primary</span>
          <span>)</span>
        </span>
      </div>
      <ng-container *ngIf="primary.person_id === person.person_id">
        <div *ngFor="let email of unsavedPrimary.email_addresses; let index = index" class="unsaved pointer"
             (click)="removeItem(unsavedPrimary, 'email_addresses', index)">
          <span>{{email.email}}</span>
          <span *ngIf="email.label || email.primary_email">
            <span>&nbsp;(</span>
            <span>{{email.label}}</span>
            <span *ngIf="email.label && email.primary_email">&nbsp;-&nbsp;</span>
            <span *ngIf="email.primary_email">Primary</span>
            <span>)</span>
          </span>
        </div>
      </ng-container>
      <div *ngIf="(person.email_addresses || []).length === 0">
        <span>None</span>
      </div>
    </div>
    <div class="phones">
      <span class="header">Phone Numbers</span>
      <div *ngFor="let phone_number of person.phones">
        <span>{{phone_number.phone_number}}</span>
        <span *ngIf="phone_number.label || phone_number.primary_phone">
          <span>(</span>
          <span>{{phone_number.label}}</span>
          <span *ngIf="phone_number.label && phone_number.primary_phone">&nbsp;-&nbsp;</span>
          <span *ngIf="phone_number.primary_phone">Primary</span>
          <span>)</span>
        </span>
      </div>
      <div *ngIf="(person.phones || []).length === 0">
        <span>None</span>
      </div>
    </div>
    <div class="addresses">
      <span class="header">Addresses</span>
      <div *ngFor="let address of person.addresses">
        <div>
          <span [ngClass]="{'different-value': duplicateAddresses(address, primary, unsavedPrimary, 'address')}"
                [matMenuTriggerFor]="person.person_id!==primary.person_id?addressReplace:null">{{address.address}}</span>
          <mat-menu #addressReplace="matMenu">
            <button mat-menu-item (click)="addItem(address, 'addresses', unsavedPrimary, primary.person_id)">
              <span>Add Full Address to Primary</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="addressMenu">
              <span>Replace Primary's Address</span>
            </button>
          </mat-menu>
          <mat-menu #addressMenu="matMenu">
            <button mat-menu-item *ngFor="let rAddr of primary.addresses"
                    (click)="replaceElement(rAddr, address, unsavedPrimary, 'addresses', 'address', primary)">
              <div>
                <span class="emphasis">{{rAddr.address}}</span>
                <span>&nbsp;-->&nbsp;</span>
                <span class="emphasis">{{address.address}}</span>
              </div>
              <div><span>{{rAddr.address2}}</span></div>
              <div>
                <span>{{rAddr.city}}</span>
                <span>,&nbsp;</span>
                <span>{{rAddr.state}}</span>
                <span>&nbsp;</span>
                <span>{{rAddr.zip}}</span>
              </div>
            </button>
          </mat-menu>
        </div>
        <div>
          <span [ngClass]="{'different-value': duplicateAddresses(address, primary, unsavedPrimary, 'address2')}"
                [matMenuTriggerFor]="person.person_id!==primary.person_id?address2Replace:null">{{address.address2}}</span>
          <mat-menu #address2Replace="matMenu">
            <button mat-menu-item>
              <span>Add Address to Primary</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="address2Menu">
              <span>Replace Primary's Address 2</span>
            </button>
          </mat-menu>
          <mat-menu #address2Menu="matMenu">
            <button mat-menu-item *ngFor="let rAddr of primary.addresses">
              <div><span>{{rAddr.address}}</span></div>
              <div>
                <span class="emphasis">{{rAddr.address2}}</span>
                <span>&nbsp;-->&nbsp;</span>
                <span class="emphasis">{{address.address2}}</span>
              </div>
              <div>
                <span>{{rAddr.city}}</span>
                <span>,&nbsp;</span>
                <span>{{rAddr.state}}</span>
                <span>&nbsp;</span>
                <span>{{rAddr.zip}}</span>
              </div>
            </button>
          </mat-menu>
        </div>
        <div>
          <span [ngClass]="{'different-value': duplicateAddresses(address, primary, unsavedPrimary, 'city')}"
                [matMenuTriggerFor]="person.person_id!==primary.person_id?cityReplace:null">{{address.city}}</span>
          <mat-menu #cityReplace="matMenu">
            <button mat-menu-item>
              <span>Add Address to Primary</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="cityMenu">
              <span>Replace Primary's City</span>
            </button>
          </mat-menu>
          <mat-menu #cityMenu="matMenu">
            <button mat-menu-item *ngFor="let rAddr of primary.addresses">
              <div><span>{{rAddr.address}}</span></div>
              <div><span>{{rAddr.address2}}</span></div>
              <div>
                <span>{{rAddr.city}}</span>
                <span>&nbsp;-->&nbsp;</span>
                <span class="emphasis">{{address.city}}</span>
              </div>
              <div>
                <span>{{rAddr.state}}</span>
                <span>&nbsp;</span>
                <span>{{rAddr.zip}}</span>
              </div>
            </button>
          </mat-menu>
          <span *ngIf="address.city && address.state">,&nbsp;</span>
          <span [ngClass]="{'different-value': duplicateAddresses(address, primary, unsavedPrimary, 'state')}"
                [matMenuTriggerFor]="person.person_id!==primary.person_id?stateReplace:null">{{address.state}}</span>
          <mat-menu #stateReplace="matMenu">
            <button mat-menu-item>
              <span>Add Address to Primary</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="stateMenu">
              <span>Replace Primary's State</span>
            </button>
          </mat-menu>
          <mat-menu #stateMenu="matMenu">
            <button mat-menu-item *ngFor="let rAddr of primary.addresses">
              <div><span>{{rAddr.address}}</span></div>
              <div><span>{{rAddr.address2}}</span></div>
              <div>
                <span>{{rAddr.city}}</span>
              </div>
              <div>
                <span>{{rAddr.state}}</span>
                <span>&nbsp;-->&nbsp;</span>
                <span class="emphasis">{{address.state}}</span>
                <span>&nbsp;</span>
                <span>{{rAddr.zip}}</span>
              </div>
            </button>
          </mat-menu>
          <span [ngClass]="{'different-value': duplicateAddresses(address, primary, unsavedPrimary, 'zip')}"
                [matMenuTriggerFor]="person.person_id!==primary.person_id?zipReplace:null">&nbsp;{{address.zip}}</span>
          <mat-menu #zipReplace="matMenu">
            <button mat-menu-item>
              <span>Add Address to Primary</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="zipMenu">
              <span>Replace Primary's Zip</span>
            </button>
          </mat-menu>
          <mat-menu #zipMenu="matMenu">
            <button mat-menu-item *ngFor="let rAddr of primary.addresses">
              <div><span>{{rAddr.address}}</span></div>
              <div><span>{{rAddr.address2}}</span></div>
              <div>
                <span>{{rAddr.city}}</span>
              </div>
              <div>
                <span>{{rAddr.state}}</span>
                <span>&nbsp;</span>
                <span>{{rAddr.zip}}</span>
                <span>&nbsp;-->&nbsp;</span>
                <span class="emphasis">{{address.zip}}</span>
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
      <ng-container *ngIf="primary.person_id === person.person_id">
        <div *ngFor="let address of unsavedPrimary.addresses; let index = index" class="unsaved"
             (click)="removeItem(unsavedPrimary, 'addresses', index)">
          <div><span>{{address.address}}</span></div>
          <div><span>{{address.address2}}</span></div>
          <div>
            <span>{{address.city}}</span>
            <span *ngIf="address.city && address.state">,&nbsp;</span>
            <span>{{address.state}}</span>
            <span>&nbsp;{{address.zip}}</span>
          </div>
        </div>
      </ng-container>
      <div *ngIf="(person.addresses || []).length === 0">
        <span>None</span>
      </div>
    </div>
    <div class="activist-notes">
      <span class="header">Activist Notes</span>
      <div *ngFor="let review of person.reviews">
        <span>{{review.title}}</span>
      </div>
      <div *ngIf="(person.reviews || []).length === 0">
        <span>None</span>
      </div>
    </div>
    <div class="donations">
      <span class="header">Donations</span>
      <table *ngIf="(person.donations || []).length !== 0" class="donation-table">
        <thead>
          <tr>
            <th><span>Date</span></th>
            <th><span>Pledged</span></th>
            <th><span>Paid</span></th>
            <th style="width: 24px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let donation of person.donations" (click)="addItem(donation, 'donations', unsavedPrimary, primary.person_id)"
              [ngClass]="{'different-value': duplicateDonations(donation, primary, unsavedPrimary), 'pointer': person.person_id !== primary.person_id}">
            <td style="text-align: center;"><span>{{donation.date | date: 'shortDate'}}</span></td>
            <td style="text-align: right;"><span>{{donation.amount_paid | currency}}</span></td>
            <td style="text-align: right;"><span>{{donation.amount_pledged | currency}}</span></td>
            <td style="width: 24px;"></td>
          </tr>
          <ng-container *ngIf="primary.person_id === person.person_id">
            <tr *ngFor="let donation of unsavedPrimary.donations; let index = index" class="unsaved">
              <td style="text-align: center;"><span>{{donation.date | date: 'shortDate'}}</span></td>
              <td style="text-align: right;"><span>{{donation.amount_paid | currency}}</span></td>
              <td style="text-align: right;"><span>{{donation.amount_pledged | currency}}</span></td>
              <td style="width: 24px; padding-top: 2px;" *ngIf="person.person_id === primary.person_id">
                <button mat-icon-button class="mini-icon-btn" (click)="removeItem(unsavedPrimary, 'donations', index)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div *ngIf="(person.donations || []).length === 0">
        <span>None</span>
      </div>
    </div>
    <div class="contact-log">
      <span class="header">Contact Log</span>
      <div *ngFor="let log of person.contact_log"
           [ngClass]="{'different-value': duplicateContactLog(log, primary, unsavedPrimary, 'title')}">
        <span>{{log.title}}</span>
      </div>
      <div *ngIf="(person.contact_log || []).length === 0">
        <span>None</span>
      </div>
    </div>
    <div class="lists">
      <div><span class="header">Lists</span></div>
      <div class="list-tags">
        <div *ngFor="let list of person.lists" class="list-tag" (click)="addItem(list, 'lists', unsavedPrimary, primary.person_id)"
             [ngClass]="{'different-value': duplicateLists(list, primary, unsavedPrimary)}">
          <span>{{list.list}}</span>
        </div>
        <ng-container *ngIf="primary.person_id === person.person_id">
          <div *ngFor="let list of unsavedPrimary.lists; let index = index" class="list-tag unsaved">
            <span>{{list.list}}</span>
            <button mat-icon-button class="mini-icon-btn" (click)="removeItem(unsavedPrimary, 'lists', index)">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
      <div *ngIf="(person.lists || []).length === 0">
        <span>None</span>
      </div>
    </div>
  </div>
</ng-container>