import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
  MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  userForm: FormGroup;
  changePasswordForm: FormGroup;

  constructor(private fb: FormBuilder,
              public authSvc: AuthService,
              private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      first_name: [''],
      last_name: ['']
    });
    this.changePasswordForm = this.fb.group({
      currentPassword: [''],
      newPassword: [''],
      confirmNewPassword: ['']
    });
    this.userForm.patchValue(this.authSvc.currentUser$.getValue());
  }
  
  cancel = () => {
    this.userForm.patchValue(this.authSvc.currentUser$.getValue());
  }
  saveUser = (userId: number, userObj: any) => {
    this.authSvc.saveUser(userObj)
    .subscribe((x: any) => {
      this.authSvc.currentUser$.next(x[0][0]);
      this.authSvc.setUser(x[0][0]);
      this.userForm.patchValue(x[0][0]);
      this.userForm.markAsPristine();
      this._snackBar.open('User Profile Changes Saved!', 'X', {horizontalPosition: 'center', verticalPosition: 'top', duration: 2000})
    })
  }
  changePassword = (userId: number, passwordObj: any) => {
    this.authSvc.changePassword(userId, passwordObj).subscribe((x: any) => {
      this.changePasswordForm.reset();
      this._snackBar.open('Your Password has been Changed!', 'X', {horizontalPosition: 'center', verticalPosition: 'top', duration: 2000})
    })
  }
}