import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input('item-name') itemName: string = "Items";
  @Input('total-records') totalRecords: number = 0;
  @Input('total-filtered-records') totalFilteredRecords: number;
  @Input('page-index') pageIndex: number = 0;
  @Input('page-size') pageSize: number = 5;
  @Output('change-page-size') changePageSize: EventEmitter<number> = new EventEmitter<number>();
  @Output('page-increment') pageIncrement: EventEmitter<number> = new EventEmitter<number>();
  @Output('set-page') setPage: EventEmitter<number> = new EventEmitter<number>();
  pageSizeOptions: number[] = [5,10,15,20,25,30,35,50];
  totalPages: number = 0;
  
  ngOnInit() {
    
  }
  ngOnChanges(changes: SimpleChanges) {
    let executed = false;
    if (typeof changes['totalRecords'] !== "undefined") {
      if (changes['totalRecords'].previousValue !== changes['totalRecords'].currentValue) {
        this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
        executed = true;
      }
    }
    if (typeof changes['pageSize'] !== "undefined" && !executed) {
      if (changes['pageSize'].currentValue !== changes['pageSize'].previousValue) {
        this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
      }
    }
  }
  pages = (length: number, start?: number) => {
    start = start || 1;
    return Array.from({length: length}, (value: any, index: number) => index + start);
  }
}