<ng-container *ngIf="displayMode === 'view'">
  <a [href]="'tel:' + phoneNumber.phone_number">{{phoneNumber.phone_number | mask: '(000) 000-0000'}}</a>
  <span>
    <span *ngIf="phoneNumber.label || phoneNumber.primary_phone">&nbsp;&nbsp;(</span>
    <span *ngIf="phoneNumber.label">{{phoneNumber.label}}</span>
    <span *ngIf="phoneNumber.label && phoneNumber.primary_phone"> - </span>
    <span *ngIf="phoneNumber.primary_phone">Primary</span>
    <span *ngIf="phoneNumber.label || phoneNumber.primary_phone">)</span>
  </span>
  <div fxFlex></div>
  <button mat-icon-button class="phone-menu sm-icon-btn" [matMenuTriggerFor]="phoneMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #phoneMenu="matMenu">
    <button mat-menu-item (click)="displayMode = 'edit'">
      <span>Edit {{phoneNumber.phone_number | mask: '(000) 000-0000'}}</span>
    </button>
    <button mat-menu-item *ngIf="!phoneNumber.primary_phone" (click)="setPrimary(phoneNumber, person)">
      <span>Make {{phoneNumber.phone_number | mask: '(000) 000-0000'}} the Primary Phone Number</span>
    </button>
    <button mat-menu-item (click)="delete(phoneNumber, person)">
      <span>Delete {{phoneNumber.phone_number | mask: '(000) 000-0000'}}</span>
    </button>
  </mat-menu>
</ng-container>
<ng-container *ngIf="displayMode === 'edit'" [formGroup]="phoneForm">
  <mat-form-field>
    <mat-label>Phone Number</mat-label>
    <input matInput mask="(000) 000-0000" type="text" formControlName="phone_number">
    <mat-error *ngIf="phoneForm.get('phone_number').hasError('required')">Phone Number Required</mat-error>
    <mat-error *ngIf="phoneForm.get('phone_number').hasError('minlength')">Phone Number Requires 10 Digits</mat-error>
    <mat-error *ngIf="phoneForm.get('phone_number').hasError('maxlength')">Phone Number Requires No More Than 10 Digits</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Label</mat-label>
    <input matInput [matAutocomplete]="phoneLabel" type="text" formControlName="label">
    <mat-autocomplete #phoneLabel="matAutocomplete">
      <mat-option *ngFor="let label of phoneLabels" [value]="label">
        <span>{{label}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-checkbox formControlName="primary_phone" color="primary">
    <span>Primary</span>
  </mat-checkbox>
  <button mat-icon-button color="warn" title="Cancel new entry"
          class="md-icon-btn" (click)="reset(phoneNumber, person)">
    <mat-icon>cancel</mat-icon>
  </button>
  <button mat-icon-button color="primary" class="md-icon-btn" title="Save new entry"
          [disabled]="phoneForm.pristine || phoneForm.invalid" (click)="save(phoneForm.value, person)">
    <mat-icon>save</mat-icon>
  </button>
</ng-container>