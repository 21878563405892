import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { AuthService } from '../auth.service';
import { ConfigService } from '@services/config.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { PeopleService } from '@modules/person-profiles/people.service';

import { UserDialogComponent } from '../user-dialog/user-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  userGroups: any[];

  constructor(public authSvc: AuthService,
              public configSvc: ConfigService,
              private dialogSvc: DialogService,
              private peopleSvc: PeopleService,
              public dialog: MatDialog) { }
  
  deleteUser = (user: any) => {
    this.dialogSvc.confirmDialog("Delete User", `Are you sure that you want to delete Sling of David user ${user.first_name +  ' ' + user.last_name}? This action cannot be undone.`).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.authSvc.deleteUser(user.user_id))
    ).subscribe((users: any[]) => {
      this.users$.next(users);
      this.peopleSvc.getSolicitors();
    });
  }
  activateUser = (userId: number, status: string) => {
    this.authSvc.activateUser(userId, status)
    .subscribe((users: any[]) => this.users$.next(users));
  }
  createUser = (user: any) => {
    this.authSvc.createUser(user).subscribe((response: any) => {
      let users: any[] = this.users$.getValue();
      
      users.push(response);
      this.users$.next(users);
      this.peopleSvc.getSolicitors();
    });
  }
  
  updateUser = (user: any, index: number) => {
    let users = this.users$.getValue();
    
    this.authSvc.saveUser(user).subscribe((response: any) => {
      users[index] = response;
      this.users$.next(users);
      this.peopleSvc.getSolicitors();
    });
  }
  updateUserGroup = (user: any, group: string, index: number) => {
    user.user_group = group;
    this.updateUser(user, index);
  }
  
  openDialog() {
    const dialogRef = this.dialog.open(UserDialogComponent, {maxWidth: '600px'});

    dialogRef.afterClosed().subscribe(result => {
      let users: any[] = this.users$.getValue();
      
      if (users.length > 0) {
        if (users.some((user: any) => user.user_id === result.user_id)) {
          let index = users.findIndex((user: any) => user.id === result.user_id);

          users[index] = result;
          this.users$.next(users);
        } else {
          users.push(result);
          this.users$.next(users);
        }
      }
    });
  }

  ngOnInit(): void {
    this.authSvc.getUsers().subscribe((users: any[]) => this.users$.next(users));
    this.configSvc.settings.subscribe((settings: any) => this.userGroups = settings.userGroups);
  }
}