import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject, ReplaySubject, BehaviorSubject, of, first } from 'rxjs';
import { map, debounceTime, startWith, switchMap, filter, tap, take } from 'rxjs/operators';

import { ConfigService } from '@services/config.service';

export interface PhoneNumber {
  phone_id: number;
  phone_number: string;
  label: string;
  primary_phone: number;
  contact_link: number;
  date_created: string;
  user_created: string;
}
export interface Address {
  address_id: number;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  contact_link: number;
  date_created: string;
  user_created: string;
}

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  url: string;
  activistList$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  baselineCallInviteList$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  personListUpdate$: Subject<any> = new Subject<any>();
  solicitors$: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  
  actionStatusOptions = [
    "Unassigned",
    "Tasked",
    "Accepted",
    "Ongoing",
    "Completed",
    "Surrendered"
  ];
  house = [];
  senate = [];

  constructor(private http: HttpClient,
              private configSvc: ConfigService
              ) {
    this.configSvc.settings.pipe(
      filter((settings: any) => settings.apiUrl)
    ).subscribe((settings: any) => this.url = settings.apiUrl);
    this.getSolicitors();
  }
  
  loadSolicitors = () => {
    return this.solicitors$.pipe(first());
  }
  getSolicitors = () => {
    this.http.get<any[]>(this.url + "users/solicitors").subscribe((response: any[]) => {
      this.solicitors$.next(response);
    });
  }
  
  getPersonName = (person: any) => {
    return (person.first_name + " " + person.last_name).trim();
  }
  getFullAddress = (person: any) => {
    return person.address + (person.address2?", " + person.address2:"") + (person.city?", " + person.city:"") + (person.state?", " + person.state:"") + (person.zip?" " + person.zip:"")
  }
  removeEmptyFieldRecords = (person: any) => {
    if (typeof person.email_addresses !== "undefined") {
      person.email_addresses.forEach((em: any, idx: number) => {if (!em.email && !em.email_address_id) person.email_addresses.splice(idx, 1)});
    }
    if (typeof person.phones !== "undefined") {
      person.phones.forEach((ph: any, idx: number) => {if (!ph.phone_number && !ph.phone_id) person.phones.splice(idx, 1)});
    }
    if (typeof person.addresses !== "undefined") {
      person.addresses.forEach((add: any, index: number) => {
        if (!add.address_id && !add.address && !add.city && !add.state && !add.zip) {
          person.addresses.splice(index, 1);
        }
      });
    }
    return person;
  }

  // PERSON FUNCTIONS ******************************************************************************
  getPerson = (id: number) => {
    return this.http.get(this.url + "people", {params: {id: id}});
  }
  getFilteredPeople = (filter: any) => {
    return this.http.post(this.url + "people/filter", filter);
  }
  getPersonSimpleRecord = (personId: number) => {
    return this.http.get(this.url + "people/simple-record/" + personId)
  }
  createPerson = (person: any) => {
    return this.http.post(this.url + "people", this.removeEmptyFieldRecords(person));
  }
  updatePerson = (person: any) => {
    return this.http.put(this.url + "people", this.removeEmptyFieldRecords(person));
  }
  deletePerson = (id: number) => {
    return this.http.delete(this.url + "people", {params: {id: id}});
  }
  
  toggleVerificationStatus = (id: number, verificationObj: any) => {
    return this.http.put(this.url + "people/verification/" + id, verificationObj);
  }
    
  // DUPLICATE FUNCTIONS **************************************************************************
  getDuplicates = (pageIndex: number, pageSize: number) => {
    return this.http.get(this.url + "people/duplicates", {params: {pageIndex: pageIndex, pageSize: pageSize}});
  }
  getDuplicatesByName = (first: string, last: string) => {
    return this.http.get<any[]>(this.url + "people/duplicates-by-name", {params: {first: first, last: last}});
  }
  
  saveUnsavedPrimary = (unsavedPrimary: any) => {
    return this.http.put(this.url + "people/duplicate", unsavedPrimary);
  }
  addNonDuplicate = (person1_id: number, person2_id: number) => {
    return this.http.post(this.url + "people/non-duplicate", {person1_id: person1_id, person2_id: person2_id});
  }
  
  // EMAIL FUNCTIONS ******************************************************************************
  addEmailAddress = (email: any) => {
    return this.http.post(this.url + "email-addresses", email);
  }
  updateEmailAddress = (email: any) => {
    return this.http.put(this.url + "email-addresses", email);
  }
  deleteEmailAddress = (id: number, personId: number) => {
    return this.http.delete(this.url + `email-addresses`, {params: {id: id, personId: personId}});
  }
  
  checkEmailAddress = (email: string) => {
    return this.http.get(this.url + "email-addresses/check", {params: {email: email}});
  }
  unsubscribe = (personId: number) => {
    return this.http.get(this.url + "unsubscribe/" + personId);
  }
  
  // PHONE NUMBER FUNCTIONS ***********************************************************************
  addPhoneNumber = (phoneNumber: PhoneNumber) => {
    return this.http.post<PhoneNumber[]>(this.url + "phone-numbers", phoneNumber);
  }
  updatePhoneNumber = (phoneNumber: PhoneNumber) => {
    return this.http.put<PhoneNumber[]>(this.url + 'phone-numbers', phoneNumber)
  }
  deletePhoneNumber = (id: number, personId: number) => {
    return this.http.delete(this.url + `phone-numbers`, {params: {id: id, personId: personId}});
  }
  formatPhoneNumber = (phoneNumber: string) => {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }
  
  // ADDRESS FUNCTIONS ****************************************************************************
  addAddress = (address: Address) => {
    return this.http.post<Address[]>(this.url + "addresses", address);
  }
  updateAddress = (address: Address) => {
    return this.http.put<Address[]>(this.url + "addresses", address);
  }
  deleteAddress = (id: number, personId: number) => {
    return this.http.delete<Address[]>(this.url + "addresses", {params: {id: id, personId: personId}});
  }
  
  zipLookup = (zip: string) => {
    return this.http.get(this.url + "addresses/zip-lookup", {params: {zip: zip}});
  }
  
  // SEARCH FUNCTION ******************************************************************************
  searchControl = (control) => {
    return control.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value && typeof value === "string") {
          return this.searchAll(value.trim());
        } else {
          return of([null]);
        }
      }));
  }
  searchAll = (searchRequest: string) => {
    return this.http.get(this.url + "people/search/" + encodeURIComponent(searchRequest))
  }
  
}