import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import * as moment from 'moment';

import { EmailService } from '../email.service';

@Component({
  selector: 'app-email-results',
  templateUrl: './email-results.component.html',
  styleUrls: ['./email-results.component.scss']
})
export class EmailResultsComponent implements OnInit {
  results$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  response: any = {};
  awsEvents: string[] = ["Delivery", "Open", "Click", "Bounce", "Complaint", "DeliveryDelay"];

  constructor(public emailSvc: EmailService) {}

  ngOnInit(): void {
    
  }
  
  parse = (str: string) => {
    return JSON.parse(str);
  }
  seeThrottled = (id: number) => {
    this.emailSvc.seeThrottled(id).subscribe((response: any) => {
      this.response = response;
    });
  }
  fixThrottled = (id: number) => {
    this.emailSvc.fixThrottled(id).subscribe((response: any) => {
      this.response = response;
    });
  }
  fixAWSResponses = () => {
    this.emailSvc.fixAWSResponses().subscribe((response: any) => {
      this.response.text = JSON.stringify(response, null, 4);
    });
  }
  transformAWSResponses = (event: string) => {
    this.response.text = `Running ${event} UPDATE queries...`;
    this.emailSvc.transformAWSResponses(event).subscribe((response: any) => {
      this.response.text = `Attempted ${response.count} ${event} updates and compeleted ${response.result.filter(re => re.affectedRows === 1).length}`;
    });
  }
  fixSendResults = () => {
    this.emailSvc.fixSendResults().subscribe((response: any) => {
      this.response.text = JSON.stringify(response, null, 4);
    });
  }
  insertMessages = () => {
    this.emailSvc.insertMessages().subscribe((response: any) => {
      this.response.text = JSON.stringify(response, null, 4);
    })
  }
  
  getEmailReport = (subject: string) => {
    this.emailSvc.getEmailReport(subject).subscribe((blob: Blob) => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = "Email Delivery Report - " + moment().format("YYYY-MM-DD-hh-mm-ss") + ".xlsx";
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
}