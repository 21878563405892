import { Component, OnInit } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';

import { DonationsService } from '@modules/donor-management/donations.service';
import { UtilityService } from '@services/utility.service';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-donations',
  templateUrl: './donations.component.html',
  styleUrls: ['./donations.component.scss']
})
export class DonationsComponent implements OnInit {
  donations$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  columns = ["recipient", "amount", "date_pledged", "date_paid"];
  tableData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  filter$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(["pledge"]);

  constructor(private donationSvc: DonationsService,
              public utilitySvc: UtilityService,
              private dialogSvc: DialogService) { }

  ngOnInit(): void {
    this.donationSvc.getAllDonations().subscribe((x: any[]) => {
      this.donations$.next(x[0])
    });
    combineLatest(this.donations$, this.filter$).subscribe(x => {
      let data = x[0].filter(row => x[1].indexOf(row.type) > -1);
      data.sort((a,b) => a.type === "pledge"?1:-1);
      data.sort((a,b) => a.date_pledged > b.date_pledged?1:-1);
      this.tableData$.next(data);
    });
  }
  
  viewDonation = (donation) => {
    this.dialogSvc.donationDialog('view', donation.person_id, donation)
    .subscribe(x => console.log(x));
  }
  createDonation = () => {
    this.dialogSvc.donationDialog('edit')
    .subscribe(x => console.log(x));
  }
  
  toggleFilter = (type: string) => {
    let data = this.filter$.getValue(), index = data.indexOf(type)
    if (index === -1) {
      data.push(type);
    } else {
      data = data.filter(el => el !== type);
    }
    this.filter$.next(data);
  }
}