import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';
import { filter, withLatestFrom, switchMap, distinctUntilChanged } from 'rxjs/operators';

import * as moment from 'moment';

import { ContactLogService } from '../contact-log.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { ContactLogDialogComponent } from '../contact-log-dialog/contact-log-dialog.component';

@Component({
  selector: 'app-contact-log-table',
  templateUrl: './contact-log-table.component.html',
  styleUrls: ['./contact-log-table.component.scss']
})
export class ContactLogTableComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageIndex: 0,
    pageSize: 5,
    id: 0,
    orderBy: [{field: `contact_log.contact_datetime`, order: `DESC`}]
  })
  contactLog$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  totalRecords$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalPages: number = 0;
  
  constructor(private contactLogSvc: ContactLogService,
              private dialogSvc: DialogService,
              private dialog: MatDialog) {}
  
  ngOnInit() {
    this.person$.pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([person, filter]: [any, any]) => {
      if (person.person_id) {
        filter.contact_link = person.person_id;
        this.filter$.next(filter);
      } else {
        this.contactLog$.next([]);
        this.totalRecords$.next(0);
      }
    });
    this.filter$.pipe(
      filter((filter: any) => !!filter.contact_link),
      switchMap((filter: any) => this.contactLogSvc.getContactLog(filter))
    ).subscribe((response: any) => {
      this.contactLog$.next(response.items);
      this.totalRecords$.next(response.count);
    });
    this.filter$.pipe(
      distinctUntilChanged((prev: any, curr: any) => prev.pageSize === curr.pageSize),
      withLatestFrom(this.totalRecords$)
    ).subscribe(([filter, totalRecords]: [any, number]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
    this.totalRecords$.pipe(withLatestFrom(this.filter$))
    .subscribe(([totalRecords, filter]: [number, any]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
  }
  
  pageIncrement = (filter: any, increment: number) => {
    filter.pageIndex = filter.pageIndex + increment;
    
    this.filter$.next(filter);
  }
  updatePageSize = (pageSize: number, filter: any) => {
    filter.pageSize = pageSize;
    this.filter$.next(filter);
  }

  openDialog = (contactLog: any, person: any, filter: any, viewMode: string) => {
    let data: any = {contactLog: contactLog, person: person, filter: filter, viewMode: viewMode},
        dialogRef: MatDialogRef<ContactLogDialogComponent> = this.dialog.open(ContactLogDialogComponent, {data: data});
    
    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response) this.filter$.next(filter);
    });
  }
  delete = (contactLog: any, itemFilter: any) => {
    let title: string = "Confirm Contact Log Entry Delete",
        msg: string = `Are you sure you want to delete the ${contactLog.contact_datetime?moment(contactLog.contact_datetime).format('MMM Do, YYYY') + " ":""}contact log entry titled '${contactLog.title}'?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      withLatestFrom(this.filter$),
      switchMap(([confirm, filter]) => this.contactLogSvc.delete(contactLog.contact_log_id))
    )
    .subscribe((response: any) => {
      this.filter$.next(itemFilter);
    });
  }
}