<ng-container *ngIf="person$ | async as person">
  <h2 class="text-center" style="margin-bottom: 0;">{{peopleSvc.getPersonName(person)}}</h2>
  <div fxFlex fxLayout="column" fxLayoutGap="6px" class="contact-info-frame">
    <div fxLayout="row">
      <div *ngIf="person.preferred_name" fxFlex="50">
        <div class="buffer">
          <button class="md-icon-btn" mat-icon-button>
            <mat-icon class="contact-icon" title="Preferred Name">badge</mat-icon>
          </button>
        </div>
        <div fxFlex>
          <span>{{person.preferred_name}}</span>
        </div>
      </div>
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
        <div class="buffer">
          <button class="md-icon-btn" mat-icon-button>
            <mat-icon class="contact-icon" title="Staff Lead">support_agent</mat-icon>
          </button>
        </div>
        <div class="staff-form-field">
          <span class="bold">Staff Lead:&nbsp;</span>
          <div class="staff-form-control" [matMenuTriggerFor]="staffLeadMenu">
            <div><span>{{person.staff_lead || 'None'}}</span></div>
            <mat-icon style="font-size: 24px;">arrow_drop_down</mat-icon>
          </div>
        </div>
        <mat-menu #staffLeadMenu="matMenu">
          <button mat-menu-item (click)="updateStaffLead('', person)">
            <span>None</span>
          </button>
          <button mat-menu-item *ngFor="let staff of peopleSvc.solicitors$ | async"
                  (click)="updateStaffLead(staff.name, person)">
            <span>{{staff.name}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button (click)="showNewEmail = !showNewEmail;">
          <mat-icon class="contact-icon" title="Emails">email</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div fxFlex class="overflow-auto">
        <app-email-address-view *ngFor="let eaddress of person.email_addresses"
                                (person-updated)="personUpdated.emit($event)"
                                [email-address]="eaddress" [person]="person">
        </app-email-address-view>
        <app-email-address-view display-mode="edit" *ngIf="showNewEmail" [person]="person"
                                #newEmailForm (show-new-email-form)="showNewEmail = $event"
                                (person-updated)="personUpdated.emit($event); showNewEmail = false;">
        </app-email-address-view>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button
                (click)="showNewPhone = !showNewPhone">
          <mat-icon class="contact-icon" title="Phones">phone</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div fxFlex class="overflow-auto">
        <app-phone-number-view *ngFor="let phone of person.phones" [phone-number]="phone"
                               [person]="person" (person-updated)="personUpdated.emit($event)">
        </app-phone-number-view>
        <app-phone-number-view *ngIf="showNewPhone" display-mode="edit" [person]="person"
                               (person-updated)="personUpdated.emit($event)"
                               (show-new-phone-form)="showNewPhone = $event">
        </app-phone-number-view>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button (click)="showNewAddress = !showNewAddress;">
          <mat-icon class="contact-icon" title="Addresses">place</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div fxFlex class="overflow-auto">
        <app-address-view *ngFor="let address of person.addresses" [person]="person"
                          [address]="address" (person-updated)="personUpdated.emit($event)"
                          (show-new-address-form)="showNewAddress = $event;"></app-address-view>
        <app-address-view display-mode="edit" *ngIf="showNewAddress" [person]="person"
                          (person-updated)="personUpdated.emit($event)"
                          (show-new-address-form)="showNewAddress = $event;"></app-address-view>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button (click)="showNewListForm = !showNewListForm">
          <mat-icon class="contact-icon" title="Lists">label</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div fxFlex>
        <div *ngIf="showNewListForm" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field fxFlex>
            <mat-label>Select list to add {{peopleSvc.getPersonName(person)}}</mat-label>
            <input matInput type="text" [matAutocomplete]="autoList" name="list"
                   [formControl]="listControl">
            <button matSuffix mat-icon-button (click)="listControl.reset('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <button mat-icon-button color="warn" title="Close New List"
                  (click)="listControl.reset(''); showNewListForm = false">
            <mat-icon>cancel</mat-icon>
          </button>
          <mat-autocomplete #autoList="matAutocomplete" [displayWith]="displayNull"
                            (optionSelected)="addPersonToSelectedList($event, person)">
            <mat-option *ngIf="listControl.value" [value]="{list: listControl.value}">
              <span>Create New List '{{listControl.value}}' and add {{getPersonName(person)}}</span>
            </mat-option>
            <mat-option *ngFor="let list of listDropdown$ | async" [value]="list">
              <span>{{list.list}}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
        <p class="pointer" *ngIf="!person.lists?.length && !showNewListForm"
           style="color: gray;" (click)="showNewListForm = true;">
          Click here to add {{peopleSvc.getPersonName(person)}} to a list.</p>
        <div class="list-row">
          <div *ngFor="let list of person.lists">
            <span>{{list.list}}</span>
            <button mat-icon-button class="sm-icon-btn"
                    (click)="removePersonFromList(list.person_list_join_id, list, person)">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button>
          <mat-icon class="contact-icon" title="Notes">assignment</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div fxFlex class="overflow-auto">
        <p>{{person.notes}}</p>
      </div>
    </div>
  </div>
</ng-container>