import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { NotesService } from '../notes.service';

@Component({
  selector: 'app-activist-note-dashboard',
  templateUrl: './activist-note-dashboard.component.html',
  styleUrls: ['./activist-note-dashboard.component.scss']
})
export class ActivistNoteDashboardComponent implements OnInit {
  notes: any[] = [];
  userReportLog: any[] = [];
  totalNoteCount: number = 0;
  filteredNoteCount: number = 0;
  unreadNoteCount: number = 0;
  notesPerPageOptions: number[] = [5,10,20,25,50];
  noteSummaryByStaffer: any[] = [];
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 25,
    pageIndex: 0,
    preparedBy: [],
    text: "",
    startDate: '',
    endDate: '',
    reportFormat: "Chronological Order",
    orderBy: [
      {field: "reviews.last_updated", label: "Date Note Last Updated", order: "DESC"},
      {field: "reviews.date_created", label: "Date Note Created", order: "DESC"},
    ]
  });
  dateRangeForm: FormGroup;
  textSearch: FormControl = new FormControl();
  staffOptions: any[] = [];
  spinner: boolean = false;
  reportFormatOptions: string[] = [
    "Chronological Order",
    "Grouped By Staff Author",
    "Grouped By Activist",
    "Grouped By Staff Author and Activist"
  ];

  constructor(private noteSvc: NotesService,
              private fb: FormBuilder) { }
  
  ngOnInit(): void {
    this.noteSvc.getStaffOptions().subscribe((response: any[]) => {
      this.staffOptions = response;
    });
    this.noteSvc.getUserReportLog().subscribe((response: any[]) => {
      this.userReportLog = response;
    });
    this.dateRangeForm = this.fb.group({
      startDate: [],
      endDate: []
    });
    
    this.filter$.pipe(
      switchMap((filter: any) => this.noteSvc.getFilteredNotes(filter))
    ).subscribe((response: any) => {
      this.notes = response.notes;
      this.totalNoteCount = response.total;
      this.filteredNoteCount = response.count;
    });
    this.dateRangeForm.valueChanges.subscribe((form: any) => {
      let filter = this.filter$.getValue();
      
      filter.startDate = form.startDate?moment(form.startDate).format('YYYY-MM-DD hh:mm:ss a'):"";
      filter.endDate = form.endDate?moment(form.endDate).format('YYYY-MM-DD hh:mm:ss a'):"";
      this.filter$.next(filter);
    });
    this.textSearch.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((text: string) => {
      let filter = this.filter$.getValue();
      
      filter.text = text;
      this.filter$.next(filter);
    })
  }

  totalPages = (filter: any) => {
    return Math.floor(this.filteredNoteCount / filter.pageSize) + 1;
  }
  previousPage = (filter: any) => {
    filter.pageIndex = filter.pageIndex - 1;
    this.filter$.next(filter);
  }
  nextPage = (filter: any) => {
    filter.pageIndex = filter.pageIndex + 1;
    this.filter$.next(filter);
  }
  updatePageSize = (size: number, filter: any) => {
    filter.pageSize = size;
    this.filter$.next(filter);
  }
  toggleStaffFilter = (staff: string, filter: any) => {
    if (filter.preparedBy.includes(staff)) {
      filter.preparedBy = filter.preparedBy.filter((stf: string) => staff !== stf);
    } else {
      filter.preparedBy.push(staff);
    }
    this.filter$.next(filter);
  }
  clearStaffFilter = (filter: any) => {
    filter.preparedBy = [];
    this.filter$.next(filter);
  }
  displayNoteAuthors = (filter: any) => {
    return filter.preparedBy.map((pr: string) => pr || "None").join(", ") || "All";
  }
  
  getReport = (filter: any) => {
    this.spinner = true;
    this.noteSvc.getReport(filter).subscribe((blob: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = "Activist Notes - " + moment().format("YYYY-MM-DD-hh-mm-ss") + ".pdf";
      a.click();
      URL.revokeObjectURL(objectUrl);
      this.spinner = false;
    });
  }
  selectReportDate = (report: any) => {
    this.dateRangeForm.patchValue({startDate: moment(report.date_accessed), endDate: ""});
  }
  updateDateFilter = (filter: any, update: any) => {
    this.dateRangeForm.patchValue(update);
  }
  
  startOfWeek = () => {
    return moment().startOf('week');
  }
  lastWeek = () => {
    return {
      startDate: moment().startOf('week').subtract(7, 'days'),
      endDate: moment().startOf('week')
    }
  }
  lastMonth = () => {
    return {
      startDate: moment().startOf('month').subtract(1, 'months'),
      endDate: moment().startOf('month')
    }
  }
  startOfMonth = () => {
    return moment().startOf('month');
  }
  lastNoOfDays = (no: number) => {
    return moment().startOf('day').subtract(no, 'days');
  }
  
  mapDisplay = (array: any[], key: string, delimiter: string) => {
    return array.map(item => item[key]).join(delimiter);
  }
  updateFilter = (filter: any, update: any) => {
    filter = {
      ...filter,
      ...update
    }
    
    this.filter$.next(filter);
  }
  addGrouping = (filter: any, grouping: any) => {
    filter.groupBy.push(grouping);
    this.filter$.next(filter);
  }
}