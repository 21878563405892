import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { NgxMaskModule } from 'ngx-mask';

import { ActionManagerModule } from '@modules/action-manager/action-manager.module';
import { ActivistNotesModule } from '@modules/activist-notes/activist-notes.module';
import { ContactLogsModule } from '@modules/contact-logs/contact-logs.module';
import { DialogsModule } from '@modules/dialogs/dialogs.module';
import { DonorManagementModule } from '@modules/donor-management/donor-management.module';
import { SharedComponentsModule } from '@modules/shared-components/shared-components.module';

import { PersonComponent } from './person/person.component';
import { PersonFilterListComponent } from './person-filter-list/person-filter-list.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { EmailResultsTableComponent } from './email-results-table/email-results-table.component';
import { PersonFormComponent } from './person-form/person-form.component';
import { PersonViewComponent } from './person-view/person-view.component';
import { EmailAddressViewComponent } from './email-address-view/email-address-view.component';
import { PhoneNumberViewComponent } from './phone-number-view/phone-number-view.component';
import { AddressViewComponent } from './address-view/address-view.component';
import { PersonQuickViewComponent } from './person-quick-view/person-quick-view.component';
import { DeduplicatorComponent } from './deduplicator/deduplicator.component';
import { DuplicatePersonViewComponent } from './duplicate-person-view/duplicate-person-view.component';

@NgModule({
  declarations: [
    PersonComponent,
    PersonFilterListComponent,
    SignupFormComponent,
    EmailResultsTableComponent,
    PersonFormComponent,
    PersonViewComponent,
    EmailAddressViewComponent,
    PhoneNumberViewComponent,
    AddressViewComponent,
    PersonQuickViewComponent,
    DeduplicatorComponent,
    DuplicatePersonViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMenuModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    NgxMaskModule,
    ActionManagerModule,
    ActivistNotesModule,
    ContactLogsModule,
    DialogsModule,
    DonorManagementModule,
    SharedComponentsModule,
  ],
  exports: [
    PersonComponent,
    SignupFormComponent,
    DeduplicatorComponent
  ],
  providers: [
     { provide: MAT_DIALOG_DATA, useValue: {} },
     { provide: MatDialogRef, useValue: {} }
 ]
})
export class PersonProfilesModule { }
