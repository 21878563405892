import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImporterService {
  url: string = "https://slingofdavid.com:3000/";

  constructor(private http: HttpClient) { }
  
  //**********************************************************************************************
  uploadFile = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    
    return this.http.post(this.url + "files/upload", formData);
  }
  getSheetData = (id: number) => {
    return this.http.get(this.url + "files/sheet", {params: {id: id}});
  }
  importWorksheet = (id: number) => {
    return this.http.get(this.url + "files/import-spreadsheet", {params: {id: id}});
  }
  
  getTableColumns = (table: string) => {
    return this.http.get(this.url + "database/columns", {params: {table: table}});
  }  
  //**********************************************************************************************
  
  getFiles = (filter: any) => {
    return this.http.get(this.url + "files", {params: filter});
  }
  getFile = (id: number) => {
    return this.http.get(this.url + "files/" + id);
  }
  
  getWorksheetData = (fileId: string, worksheetId: string) => {
    return this.http.get(this.url + "files/worksheet/" + fileId + "/" + worksheetId);
  }
    
  getDestinationFields = () => {
    return this.http.get(this.url + "files/fields");
  }
}