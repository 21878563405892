import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { debounceTime, filter, switchMap } from 'rxjs/operators';

import { PeopleService, Address } from '../people.service';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent implements OnInit {
  @Input('address') address: any;
  @Input('person') person: any;
  @Input('display-mode') displayMode: string = "view";
  @Output('person-updated') personUpdated: EventEmitter<Address[]> = new EventEmitter<Address[]>();
  @Output('show-new-address-form') showNewAddressForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  addressForm: FormGroup;
  addressLabels: string[] = ["Home", "Work", "Second Home", "Other"];
  
  constructor(private fb: FormBuilder,
              protected peopleSvc: PeopleService,
              private dialogSvc: DialogService) {}
  
  ngOnInit() {
    this.addressForm = this.fb.group({
      address_id: [],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip: [''],
      zip4: [''],
      county: [''],
      congressional_district: [''],
      label: [''],
      contact_link: [],
      primary_address: [0]
    });
    if (typeof this.person !== "undefined") this.addressForm.get('contact_link').setValue(this.person.person_id);
    if (this.address) this.addressForm.patchValue(this.address);
    this.addressForm.get('zip').valueChanges.pipe(
      debounceTime(300),
      filter((zip: string) => (zip || '').length === 5),
      switchMap((zip: string) => this.peopleSvc.zipLookup(zip))
    ).subscribe((response: any) => {
      if (response.status !== "not found") {
        this.addressForm.get('state').setValue(response.state);
      } else {
        console.log(response);
      }
    });
  }
  save = (address: Address, person: any) => {
    if (address.address_id) {
      this.peopleSvc.updateAddress(address).subscribe((response: Address[]) => {
        person.addresses = response;
        this.personUpdated.emit(person);
        this.displayMode = 'view';
      });
    } else {
      this.peopleSvc.addAddress(address).subscribe((response: Address[]) => {
        person.addresses = response;
        this.personUpdated.emit(person);
        this.resetForm(address, person);
      });
    }
  }
  setPrimary = (address: Address, person: any) => {
    let addressUpdate: any = {
      address_id: address.address_id,
      primary_address: 1,
      contact_link: address.contact_link
    }
    
    this.peopleSvc.updateAddress(addressUpdate).subscribe((response: Address[]) => {
      person.addresses = response;
      this.personUpdated.emit(person);
    });
  }
  delete = (address: Address, person: any) => {
    let title: string = "Confirm Delete Address",
        question: string = `Are you sure you want to delete '${address.address}' from ${this.peopleSvc.getPersonName(person)}'s profile?`;
    
    this.dialogSvc.confirmDialog(title, question).pipe(
      filter((response: boolean) => response),
      switchMap(() => this.peopleSvc.deleteAddress(address.address_id, person.person_id))
    ).subscribe((response: Address[]) => {
      person.addresses = response;
      this.personUpdated.emit(person);
    });
  }
  
  resetForm = (address: Address, person: any) => {
    if (typeof address !== "undefined") {
      if (address.address_id) {
        this.addressForm.reset(address);
        this.displayMode = 'view';
      } else {
        this.addressForm.reset({contact_link: person.person_id});
        this.showNewAddressForm.emit(false);
      }
    } else {
      this.addressForm.reset({contact_link: person.person_id});
      this.showNewAddressForm.emit(false);
    }
  }
  reset = (address: Address, person: any) => {
    if (this.addressForm.pristine) {
      this.resetForm(address, person);
    } else {
      let title: string = `Confirm Discard Changes`,
          question: string = `Are you sure you want to discard your changes?`;
      
      this.dialogSvc.confirmDialog(title, question).pipe(
        filter((response: boolean) => response)
      ).subscribe((response: boolean) => {
        this.resetForm(address, person);
      });
    }
  }
}