<div class="d-flex flex-row">
  <div class="flex-grow-1">
    <ng-container *ngIf="viewMode === 'view'">{{cellData}}</ng-container>
    <form *ngIf="viewMode === 'edit'" [formGroup]="cellForm">
      <mat-form-field class="w-100">
        <textarea matInput matTextareaAutosize formControlName="cellValue"></textarea>
        <button mat-icon-button matSuffix color="warn" (click)="cancel()">
          <mat-icon class="pointer">cancel</mat-icon>
        </button>
        <button mat-icon-button matSuffix color="primary" [disabled]="cellForm.pristine">
          <mat-icon class="pointer" (click)="save(cellForm.value)">save</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
  <div>
    <button *ngIf="badChars(cellData)"
            mat-icon-button
            style="top: 2px; right: 2px; height: 10px; width: 10px; line-height: 10px;"
            [matMenuTriggerFor]="scanActionMenu">
      <mat-icon style="font-size: 10px; line-height: 10px; height: 10px; width: 10px;">arrow_drop_down</mat-icon>
    </button>
    <mat-menu class="compact-menu" #scanActionMenu="matMenu">
      <button mat-menu-item (click)="replaceAll()">Delete Bad Characterss</button>
      <button mat-menu-item (click)="viewMode = 'edit'">Edit Cell Contents</button>
    </mat-menu>
  </div>
</div>