import { Component, OnInit, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-correspondence-table',
  templateUrl: './correspondence-table.component.html',
  styleUrls: ['./correspondence-table.component.scss']
})
export class CorrespondenceTableComponent implements OnInit {
  @Input('person') person: any;
  correspondence: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(private dialogSvc: DialogService) { }

  ngOnInit(): void {
    
  }
  
  openCorrespondenceDialog = () => {
    this.dialogSvc.correspondenceDialog()
      .subscribe((x: any) => console.log(x));
  }

}