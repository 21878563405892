<div class="container-fluid h-100 overflow-auto d-flex flex-column">
  <div clas="row">
    <h1>Donors</h1>
  </div>
  <div class="col-xl-6 col-md-12 flex-grow-1 overflow-auto">
    <table class="table table-hover table-sm">
      <thead>
        <tr>
          <td *ngFor="let col of columns | async">{{col.label}}</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let person of donors | async">
          <td *ngFor="let col of columns | async">{{person[col]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>