import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '@services/config.service';

import { BehaviorSubject } from 'rxjs';
import { filter, tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListService {
  url: string;
  lists: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    this.configSvc.settings.pipe(
      filter((settings: any) => settings.apiUrl),
      tap((settings: any) => this.url = settings.apiUrl),
      switchMap(() => this.getAllLists())
    ).subscribe((lsts: any[]) => this.lists.next(lsts));
  }
  
  getAllLists = () => {
    return this.http.get(this.url + "lists");
  }
  createList = (list: any) => {
    return this.http.post(this.url + "lists", list);
  }
  deleteList = (id: number) => {
    return this.http.delete(this.url + "lists", {params: {id: id}});
  }
  
  getListPeople = (listId: number) => {
    return this.http.get(this.url + "lists/people", {params: {id: listId}});
  }
  addPersonToList = (personId: number, listId: number) => {
    return this.http.post(this.url + "lists/people", {personId: personId, listId: listId});
  }
  removePersonFromList = (personListJoinId: number) => {
    return this.http.delete(this.url + "lists/people", {params: {id: personListJoinId}});
  }
  createListAndAddPerson = (listObj: any, personId: number) => {
    return this.http.post(this.url + "lists/create-and-add-person", {listObj: listObj, personId: personId});
  }
  
  downloadCallList = () => {
    return this.http.get(this.url + "export/call-list", {responseType: 'blob'});
  }
  downloadEmailList = () => {
    return this.http.get(this.url + "export/email-list", {responseType: 'blob'});
  }
  downloadFilterList = (filter: any) => {
    return this.http.post(this.url + "export/filter-list", filter, {responseType: 'blob'});
  }
  downloadDonorReport = () => {
    return this.http.get(this.url + "export/donor-list", {responseType: 'blob'});
  }
  downloadNoStateReport = () => {
    return this.http.get(this.url + "export/no-state", {responseType: 'blob'});
  }
}