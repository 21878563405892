import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { AuthService } from '../auth.service';
import { ConfigService } from '@services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  });
  showPassword: boolean = false;

  constructor(private fb: FormBuilder,
              public authSvc: AuthService,
              private router: Router,
              private _snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private configSvc: ConfigService
              ) { }

  ngOnInit(): void {
    this.loginForm.valueChanges.subscribe(x => this.authSvc.error.next({}));
  }
  
  login = (loginObj) => {
    if (loginObj.email && loginObj.password) {
      this.authSvc.login(loginObj).subscribe((x: any) => {
        const { redirect } = window.history.state;
        this.authSvc.setSession(x);
        this.authSvc.currentUser$.next(x.user);
        this.router.navigateByUrl(redirect || '/');
        this._snackBar.open(`You have successfully logged in, ${x.user.first_name + " " + x.user.last_name}!`, 'X', {horizontalPosition: 'center', verticalPosition: 'top', duration: 2000});
      }, (err) => {
        console.log("current nav: ", this.router.getCurrentNavigation().extras.state)
        console.log(window.history.state.error);
      });
    }
  }
  emailErrorMsg = (formControl: any) => {
    if (formControl.errors) {
      if (formControl.errors.email) this.authSvc.error.next({type: "username", msg: "A username must be a valid email address."});
    } else {
      
    }
  }
}