<div class="d-flex flex-row"> <!-- [ngClass]="{'highlight': applyValidation(cellData)}"> -->
  <div class="flex-grow-1">
    <span *ngIf="viewMode === 'view'">{{cellData}}</span>
    <form *ngIf="viewMode === 'edit'" [formGroup]="cellForm">
      <mat-form-field class="w-100">
        <textarea matInput matTextareaAutosize formControlName="cellValue"></textarea>
        <button mat-icon-button matSuffix color="warn">
          <mat-icon class="pointer">cancel</mat-icon>
        </button>
        <button mat-icon-button matSuffix color="primary" [disabled]="cellForm.pristine">
          <mat-icon class="pointer">save</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
  <div>
    <button *ngIf="invalid"
            mat-icon-button
            style="top: 2px; right: 2px; height: 10px; width: 10px; line-height: 10px;"
            [matMenuTriggerFor]="scanActionMenu">
      <mat-icon style="font-size: 10px; line-height: 10px; height: 10px; width: 10px;">arrow_drop_down</mat-icon>
    </button>
    <mat-menu class="compact-menu" #scanActionMenu="matMenu">
      <button mat-menu-item (click)="splitName(cellData)" [matMenuTriggerFor]="splitNameMenu">Split Name</button>
      <button mat-menu-item (click)="confirmDestinationField()">Confirm Destination Field</button>
      <button mat-menu-item (click)="moveToNotes(cellData)">Move to Notes</button>
      <button mat-menu-item (click)="viewMode = 'edit'">Edit Cell Contents</button>
    </mat-menu>
    <mat-menu class="compact-menu" #splitNameMenu="matMenu">
      <button mat-menu-item>Split First Name, Last Name</button>
      <button *ngIf="countChars(cellData, ' ') === 2" mat-menu-item (click)="splitFMLName(cellData)">Split First Name, Middle Name, Last Name</button>
      <button mat-menu-item>Split Prefix, Last Name</button>
    </mat-menu>
  </div>
</div>