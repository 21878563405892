<div fxFlexFill fxLayout="column" class="overflow-auto"
     *ngIf="filter$ | async as filter">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <mat-form-field>
      <mat-label>Timeframe</mat-label>
      <mat-select [formControl]="timeframeControl">
        <mat-option *ngFor="let timeframe of timeframes"
                    [value]="timeframe">
          <span>{{timeframe}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Sort</mat-label>
      <mat-select [formControl]="sortControl">
        <mat-option *ngFor="let sortOption of sortOptions"
                    [value]="sortOption">
          <span>{{sortOption.label}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="getActivistMetricReport(filter)">
      <span>Download Report</span>
    </button>
    <div *ngIf="preparingReport" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-spinner diameter="25"></mat-spinner>
      <span>Preparing Report...</span>
    </div>
  </div>
  <div class="overflow-auto" *ngIf="data$ | async as data">
    <table fxFlex>
    <thead>
      <tr>
        <th></th>
        <th colspan="2">Total Activists</th>
        <th colspan="4">Email List</th>
        <th colspan="4">Call List</th>
      </tr>
      <tr>
        <th></th>
        <th><span>Total</span></th>
        <th><span>Change {{filter.timeframe}}</span></th>
        <th><span>Total</span></th>
        <th><span>Change {{filter.timeframe}}</span></th>
        <th><span>With Email</span></th>
        <th><span>Without Email</span></th>
        <th><span>Total</span></th>
        <th><span>Change {{filter.timeframe}}</span></th>
        <th><span>With Phone</span></th>
        <th><span>Without Phone</span></th>
      </tr>
    </thead>
    <tbody>
      <tr class="bold">
        <td>Total Activists</td>
        <td class="text-center">
          <span>{{data.total | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.overTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.emailTotal | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.emailOverTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.emailTotalWithEmail | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.emailTotalWithoutEmail | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.phoneTotal | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.phoneOverTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.phoneTotalWithPhone | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.phoneTotalWithoutPhone | number: "1.0"}}</span>
        </td>
      </tr>
      <tr class="bold">
        <td>Activists with No State</td>
        <td class="text-center">
          <span>{{data.noStateTotal | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStateOverTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStateEmailTotal | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStateEmailOverTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStateEmailTotalWithEmail | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStateEmailTotalWithoutEmail | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStatePhoneTotal | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStatePhoneOverTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStatePhoneTotalWithPhone | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{data.noStatePhoneTotalWithoutPhone | number: "1.0"}}</span>
        </td>
      </tr>
      <tr *ngFor="let state of data.states">
        <td><span>{{state.name}}</span></td>
        <td class="text-center">
          <span>{{state.total | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.overTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.emailTotal | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.emailOverTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.emailTotalWithEmail | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.emailTotalWithoutEmail | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.phoneTotal | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.phoneOverTime | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.phoneTotalWithPhone | number: "1.0"}}</span>
        </td>
        <td class="text-center">
          <span>{{state.phoneTotalWithoutPhone | number: "1.0"}}</span>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</div>

<!--div
1. How many activists are in the CRM total.
2. How many activists are on the weekly email list.
3. How many activists are on the weekly call out list.
4. How many contact reports have been added into the system and totals by person entering them.
5. Growth broken down by state. Where are we adding people?
-->