<div class="row h-100 overflow-auto view-frame">
  <div class="col-12 h-100 overflow-auto d-flex flex-column">
    <div class="row">
      <div class="col-12" style="padding-top: 5px;">
        <mat-checkbox class="w-100 ellipsis" [ngModel]="hideDuplicates$ | async"
                      (ngModelChange)="hideDuplicates$.next($event)">
          <span class="ellipsis">Hide Confirmed Duplicates</span>
        </mat-checkbox>
      </div>
    </div>
    <div class="row overflow-auto">
      <div class="col-6 h-100 overflow-auto p-2 d-flex flex-column">
        <ul class="h-100 overflow-auto list-frame m-0">
          <li [ngClass]="{'selected': selectedDuplicateIndex === dupIndex}"
              *ngFor="let dup of duplicateRowDisplay$ | async; let dupIndex = index;"
              (click)="selectDuplicatePair(dup, dupIndex)">
            <span class="ellipsis">{{displayDuplicatePairs(dup)}}</span>
          </li>
        </ul>
      </div>
      <div class="col-6 h-100 overflow-auto">
        <div class="row overflow-auto" *ngIf="selectedDuplicateRow$ | async as dupRow">
          <div class="col-12 p-2">
            <span>First & Last Match</span>
            <ul class="list-frame">
              <li *ngFor="let dup of dupRow._duplicates.firstLast"
                  [ngClass]="{'selected': (selectedPerson$ | async).person_id === dup.person_id}"
                  (click)="getPerson(dup.person_id)">
                <span>{{dup.first_name + " " + dup.last_name}}</span>
              </li>
            </ul>
            <span>Last Name Only Match</span>
            <ul class="list-frame">
              <li *ngFor="let dup of dupRow._duplicates.last"
                  [ngClass]="{'selected': (selectedPerson$ | async).person_id === dup.person_id}"
                  (click)="getPerson(dup.person_id)">
                {{dup.first_name + " " + dup.last_name}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>