import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }
  
  // STRING UTILITY FUNCTIONS *******************************************************************
  displayText = (text) => {
    return this.toTitleCase(text.replaceAll("_"," "));
  }
  toTitleCase = (text) => {
    return text.split(" ").map((word) => word.toLowerCase().charAt(0).toUpperCase() + word.substring(1)).join(" ");
  }
  getPersonName = (personObj) => {
    return personObj.first_name + " " + personObj.last_name;
  }
  displayNull = () => {
    return null;
  }
  
  // ARRAY FUNCTIONS ****************************************************************************
  addItemToArray = (array$: BehaviorSubject<any[]>, newItem: any) => {
    let array = array$.getValue();
    array.push(newItem);
    array$.next(array);
  }
  removeItemFromArray = (array$: BehaviorSubject<any[]>, id: any, idField: string) => {
    let array = array$.getValue();
    array$.next(array.filter(element => element[idField] !== id));
  }
  addItem = (array: any[], item: any, field: string) => {
    let index = array.findIndex(itm => itm[field] === item[field]);
    
    if (index > -1) array[index] = item;
    if (index === -1) array.push(item);
    return array;
  }
}