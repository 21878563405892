<ng-container *ngIf="peopleSvc.solicitors$ | async as solicitors">
  <ng-container  *ngIf="{filter: filter$ | async} as current">
    <div class="metric-table">
      <table>
        <thead>
          <tr>
            <th rowspan="2"><span>Solicitor</span></th>
            <th colspan="6"><span>Contact Attempts</span></th>
          </tr>
          <tr>
            <th><span>Today</span></th>
            <th><span>Yesterday</span></th>
            <th><span>This Week</span></th>
            <th><span>Last 7 Days</span></th>
            <th><span>This Month</span></th>
            <th><span>Last 30 Days</span></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let solicitor of solicitors">
            <td><span>{{solicitor.name}}</span></td>
            <td><span>5</span></td>
            <td><span>20</span></td>
            <td><span>40</span></td>
            <td><span>60</span></td>
            <td><span>100</span></td>
            <td><span>1,000</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="log-table">
      <div [formGroup]="filterForm">
        <div [matMenuTriggerFor]="solicitorMenu">
          <div class="solicitor-filter" id="solicitor-filter"
               [ngClass]="{'float': ((current.filter || {}).solicitors || []).length > 0}">
            <div class="solicitor-list" id="solicitor-list">
              <div class="label"><span>Solicitor Filter</span></div>
              <div *ngFor="let solicitor of (filter || {}).solicitors; let last = last"
                   style="margin-bottom: 5px;">
                <span>{{solicitor.name}}</span>
                <span *ngIf="!last">,</span>
              </div>
              <div fxFlex></div>
              <button mat-icon-button (click)="clearSolicitors(filter, filter$)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-menu class="solicitor-menu" #solicitorMenu="matMenu">
          <button mat-menu-item (click)="toggleAllSolicitors(solicitors, filter, filter$)">
            <span>{{checkSolicitorFilter(filter, solicitors)?"Select":"Deselect"}} All Solicitors</span>
          </button>
          <button mat-menu-item *ngFor="let solicitor of peopleSvc.solicitors$ | async"
                  (click)="toggleSolicitor(solicitor, filter, filter$)"
                  [ngClass]="{'selected': solicitorSelected(solicitor, filter)}">
            <mat-icon>done</mat-icon>
            <span>{{solicitor.name}}</span>
          </button>
        </mat-menu>
      </div>
      <app-pagination item-name="Attempted Contacts"
                      [page-size]="(current.filter || {}).pageSize"
                      [page-index]="(current.filter || {}).pageIndex"></app-pagination>
      <table>
        <thead>
          <tr>
            <th><span>Title</span></th>
            <th><span>Donor</span></th>
            <th><span>Date</span></th>
            <th><span>Contacted By</span></th>
            <th><span>Status</span></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contactLog of contactLogs">
            <td><span>{{contactLog.title}}</span></td>
            <td><span>{{contactLog.name}}</span></td>
            <td><span>{{contactLog.contact_datetime | date: 'short'}}</span></td>
            <td><span>{{contactLog.contacted_by}}</span></td>
            <td><span>{{contactLog.status}}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div></div>
    <div id="solicitor-panels">
      <div id="panel-controls">
        <div id="timeframeControl" [matMenuTriggerFor]="scheduleMenu">
          <mat-icon>schedule</mat-icon>
          <span>{{((panelTimeframe$ | async) || {}).label}}</span>
          <div style="flex: 1"></div>
          <mat-icon class="dropdown">arrow_drop_down</mat-icon>
        </div>
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input
            [formGroup]="filterForm" [rangePicker]="filterDateRangePicker">
            <input matStartDate placeholder="Start date" formControlName="startDate">
            <input matEndDate placeholder="End date" formControlName="endDate">
          </mat-date-range-input>
          <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="filterDateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #filterDateRangePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <mat-menu #scheduleMenu="matMenu">
        <button mat-menu-item *ngFor="let option of timeframeOptions"
                (click)="panelTimeframe$.next(option)">
          <span>{{option.label}}</span>
        </button>
      </mat-menu>
      <div id="panel-row">
        <app-solicitor-list *ngFor="let solicitor of solicitors" [solicitor]="solicitor"
                            [date-filter]="filterForm.valueChanges"></app-solicitor-list>
      </div>
    </div>
  </ng-container>
</ng-container>