<div fxFlexFill fxLayout="column" fxLayoutGap="10px"
     *ngIf="filter$ | async as filter" class="overflow-auto">
  <app-pagination item-name="Email Messages" [total-records]="totalRecords$ | async"
                  [page-index]="filter.pageIndex" [page-size]="filter.pageSize"
                  (change-page-size)="updatePageSize($event, filter)"
                  (page-increment)="pageIncrement(filter, $event)"></app-pagination>
  <table *ngIf="messages$ | async as messages">
    <thead>
      <tr>
        <th style="width: 200px;"><span>Sender</span></th>
        <th><span>Email Subject</span></th>
        <th *ngIf="messages.length > 0" style="width: 130px;"><span>Target Email</span></th>
        <th style="width: 130px; text-align: center;"><span>Date Sent</span></th>
        <th style="width: 50px; text-align: center;"><span>Opens</span></th>
        <th style="width: 60px; text-align: center;"><span>Status</span></th>
      </tr>
    </thead>
    <tbody class="overflow-auto">
      <tr *ngFor="let message of messages">
        <td [title]="message.sender" style="width: 200px;"><span class="ellipsis">{{message.sender}}</span></td>
        <td [title]="message.subject"><span class="ellipsis">{{message.subject}}</span></td>
        <td [title]="message.recipient" *ngIf="messages.length > 0" style="width: 130px;">
          <span class="ellipsis">{{message.recipient}}</span>
        </td>
        <td style="width: 130px; text-align: center;"><span>{{message.date_sent | date: 'short'}}</span></td>
        <td style="width: 50px; text-align: center;"><span>{{getOpens(message)}}</span></td>
        <td style="width: 60px; text-align: center;"><span>{{message.Status}}</span></td>
      </tr>
    </tbody>
  </table>
</div>