import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export class Action {
  action_id: number;
  name: string;
  person_link: number;
  action_lead: string;
  status: string;
  date_completed: string;
  complete: number;
  obe: number;
  notes: string;
  date_created: string;
  user_created: string;
}

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  url: string = "https://slingofdavid.com:3000/";

  constructor(private http: HttpClient) { }
  
  getFilteredActions = (filter: any) => {
    return this.http.post(this.url + "actions/filter", filter);
  }
  create = (action: Action) => {
    return this.http.post(this.url + "actions", action);
  }
  update = (action: Action) => {
    return this.http.put(this.url + "actions", action);
  }
  delete = (id: number) => {
    return this.http.delete(this.url + "actions", {params: {id: id}});
  }
}