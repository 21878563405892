<div fxFlexFill fxLayout="column" *ngIf="filter$ | async as filter">
  <div fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
    <button mat-flat-button color="primary" [disabled]="filteredNoteCount > 700"
            [title]="filteredNoteCount > 700?'Set filter to reduce filtered note count below 700 to access printable reports':'Access Note Reports'"
            [matMenuTriggerFor]="reportMenu">
      <span>Reports</span>
    </button>
    <mat-menu #reportMenu="matMenu">
      <button mat-menu-item (click)="getReport(filter)">
        <span>Download Report</span>
      </button>
      <button mat-menu-item disabled>
        <span>Email PDF Report to Myself</span>
      </button>
      <button mat-menu-item disabled>
        <span>Download Spreadsheet Report</span>
      </button>
    </mat-menu>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="spinner">
      <mat-spinner diameter="25"></mat-spinner>
      <span>Preparing Report...</span>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
    <div style="width: 180px;">
      <div>
        <span class="bold">Total Note Count: </span>
        <span>{{totalNoteCount | number: '1.0'}}</span>
      </div>
      <div>
        <span class="bold">Filtered Note Count: </span>
        <span>{{filteredNoteCount | number: '1.0'}}</span>
      </div>
    </div>
    <div>
      <div style="padding-left: 24px;">
        <span class="bold">Notes Per Page: </span>
        <span [matMenuTriggerFor]="perPageMenu"
              class="pointer">{{filter.pageSize || 0 | number: '1.0'}}</span>
        <mat-menu #perPageMenu>
          <button mat-menu-item *ngFor="let option of notesPerPageOptions"
                  (click)="updatePageSize(option, filter)">
            <span>{{option}}</span>
          </button>
        </mat-menu>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-icon-button [disabled]="filter.pageIndex === 0"
                (click)="previousPage(filter)">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <div>
          <span>Page {{filter.pageIndex + 1}} of {{totalPages(filter)}}</span>
        </div>
        <button mat-icon-button [disabled]="(filter.pageIndex + 1) === totalPages(filter)"
                (click)="nextPage(filter)">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button [matMenuTriggerFor]="historyMenu" disabled>
        <mat-icon>history</mat-icon>
      </button>
      <mat-menu #historyMenu="matMenu">
        <button mat-menu-item *ngFor="let report of userReportLog"
                (click)="selectReportDate(report)">
          <span>{{report.date_accessed | date: 'short'}}</span>
        </button>
      </mat-menu>
      <button mat-icon-button [matMenuTriggerFor]="sortMenu">
        <mat-icon>sort_by_alpha</mat-icon>
      </button>
      <mat-menu #sortMenu="matMenu">
        <button mat-menu-item
                [ngClass]="{'indent': filter.groupBy !== 'staffer'}">
          <mat-icon [ngClass]="{'hide': filter.groupBy !== 'staffer'}">done</mat-icon>
          <span>Group by Staffer</span>
        </button>
        <button mat-menu-item
                [ngClass]="{'indent': filter.groupBy !== 'activist'}">
          <mat-icon [ngClass]="{'hide': filter.groupBy !== 'activist'}">done</mat-icon>
          <span>Group by Activist</span>
        </button>
        <button mat-menu-item
                [ngClass]="{'indent': filter.orderBy !== 'last_update'}">
          <mat-icon [ngClass]="{'hide': filter.orderBy !== 'last_update'}">done</mat-icon>
          <span>Sort by Last Time Updated</span>
        </button>
        <button mat-menu-item
                [ngClass]="{'indent': filter.orderBy !== 'activist_last_name'}">
          <mat-icon [ngClass]="{'hide': filter.orderBy === 'activist_last_name'}">done</mat-icon>
          <span>Sort by Activist Last Name</span>
        </button>
      </mat-menu>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button [matMenuTriggerFor]="savedFilterMenu">
        <mat-icon>filter</mat-icon>
      </button>
      <mat-menu #savedFilterMenu="matMenu">
        <button mat-menu-item disabled>
          <span>Since Last Report: 9/9/99</span>
        </button>
        <button mat-menu-item (click)="updateDateFilter(filter, {startDate: startOfWeek()})">
          <span>This Week</span>
        </button>
        <button mat-menu-item (click)="updateDateFilter(filter, {startDate: lastNoOfDays(7)})">
          <span>Last 7 Days</span>
        </button>
        <button mat-menu-item (click)="updateDateFilter(filter, lastWeek())">
          <span>Last Week</span>
        </button>
        <button mat-menu-item (click)="updateDateFilter(filter, {startDate: startOfMonth()})">
          <span>This Month</span>
        </button>
        <button mat-menu-item (click)="updateDateFilter(filter, {startDate: lastNoOfDays(30)})">
          <span>Last 30 Days</span>
        </button>
        <button mat-menu-item (click)="updateDateFilter(filter, lastMonth())">
          <span>Last Month</span>
        </button>
      </mat-menu>
      <div fxLayout="row" fxLayoutAlign="start center"
           [formGroup]="dateRangeForm">
        <mat-form-field>
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="dateRangePicker">
            <input matStartDate placeholder="Start Date" autocomplete="off"
                   (dateChange)="updateDateRange('start', $event, filter)" formControlName="startDate">
            <input matEndDate placeholder="End Date" autocomplete="off"
                   (dateChange)="updateDateRange('end', $event, filter)" formControlName="endDate">
          </mat-date-range-input>
          <button mat-icon-button matSuffix (click)="dateRangeForm.reset()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field style="width: 240px;">
        <mat-label>Text Search</mat-label>
        <input matInput type="text" [formControl]="textSearch" name="textSearch">
        <button mat-icon-button matSuffix (click)="textSearch.reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button mat-icon-button [matMenuTriggerFor]="staffMenu">
        <mat-icon>people</mat-icon>
      </button>
      <mat-menu #staffMenu="matMenu">
        <button mat-menu-item (click)="clearStaffFilter(filter)"
                [ngClass]="{'indent': filter.preparedBy.length > 0}">
          <mat-icon [ngClass]="{'hide': filter.preparedBy.length > 0}">done</mat-icon>
          <span>Show All</span>
        </button>
        <button mat-menu-item *ngFor="let staff of staffOptions" class="indent"
                (click)="toggleStaffFilter(staff.prepared_by, filter)"
                [ngClass]="{'indent': !filter.preparedBy.includes(staff.prepared_by)}">
          <mat-icon [ngClass]="{'hide': !filter.preparedBy.includes(staff.prepared_by)}">done</mat-icon>
          <span>{{staff.prepared_by || "None"}} </span>
          <span>({{staff.count}})</span>
        </button>
      </mat-menu>
      <div>
        <span class="bold">Filter Contains Notes Prepared By: </span>
        <span>{{displayNoteAuthors(filter)}}</span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <span class="bold">Report Format: </span>
      <span>{{filter.reportFormat}}</span>
    </div>
  </div>
  <table fxFlex class="note-table">
    <thead>
      <tr>
        <th class="title-col"><span>Title</span></th>
        <th class="title-col"><span>Content</span></th>
        <th><span>Person</span></th>
        <th><span>CRA Staff</span></th>
        <th><span>Date Last Updated</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let note of notes">
        <td class="title-col"><span class="ellipsis">{{note.title}}</span></td>
        <td class="title-col">
          <span class="ellipsis" [title]="note.content">{{note.content}}</span>
        </td>
        <td><span class="ellipsis">{{note.first_name + " " + note.last_name}}</span></td>
        <td><span class="ellipsis">{{note.prepared_by}}</span></td>
        <td><span class="ellipsis">{{note.last_updated | date: 'short'}}</span></td>
      </tr>
    </tbody>
  </table>
</div>