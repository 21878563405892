import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { EmailService } from '../email.service';

@Component({
  selector: 'app-sent-items',
  templateUrl: './sent-items.component.html',
  styleUrls: ['./sent-items.component.scss']
})
export class SentItemsComponent implements OnInit {
  @Output('edit-email') editEmail: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(public emailSvc: EmailService,
              private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit(): void {}
  
  archiveEmail = (id: number) => {
    this.emailSvc.archiveSentItem(id).subscribe((sentItems: any[]) => {
      this.emailSvc.sentItems$.next(sentItems);
    });
  }
  openSentItemSummary = (id: number) => {
    this.router.navigate([], {relativeTo: this.route, queryParams: {id: id, view: 'summary'}, queryParamsHandling: 'merge'});
  }
}