import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { filter, switchMap } from 'rxjs/operators';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as moment from 'moment';
import 'moment-timezone';

import { ContactLogService } from '../contact-log.service';
import { DatetimefxService } from '@services/datetimefx.service';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-contact-log-dialog',
  templateUrl: './contact-log-dialog.component.html',
  styleUrls: ['./contact-log-dialog.component.scss']
})
export class ContactLogDialogComponent implements OnInit {
  contactLogForm: FormGroup;
  typeOptions: string[] = [
    "In-person",
    "Phone call",
    "Text",
    "Email",
    "Form Letter",
    "HW Note",
    "Twitter Message",
    "Gettr Message",
    "Facebook Message"
  ];
  titleOptions: string[] = [
    "Initial Contact",
    "Follow-up",
    "Ask",
    "Update",
    "Donor Update Email",
    "Renewal Meeting",
    "Event"
  ];
  statusOptions: string[] = [
    "Pending", "Completed"
  ];
  viewMode: string;
  contactLog: any;

  constructor(@Inject(MAT_DIALOG_DATA) public entryData: any,
              private fb: FormBuilder,
              private contactLogSvc: ContactLogService,
              private dialogSvc: DialogService,
              public dialogRef: MatDialogRef<ContactLogDialogComponent>,
              private dtf: DatetimefxService
              ) { }
  
  save = (contactLog: any, filter: any) => {
    if (contactLog.contact_datetime) {
      contactLog.contact_datetime = moment(contactLog.contact_datetime).tz("UTC").format("YYYY-MM-DD HH:mm:ss");
    } else {
      if (contactLog.hasOwnProperty("contact_datetime")) delete contactLog.contact_datetime;
    }
    if (!contactLog.contact_log_id) {
      contactLog.contact_link = this.entryData.person.person_id;
      
      this.contactLogSvc.create(contactLog).subscribe((response: any) => {
        this.dialogRef.close(response);
      })
    } else {
      this.contactLogSvc.update(contactLog).subscribe((response: any) => {
        this.dialogRef.close(response);
      });
    }
  }
  delete = (contactLog: any) => {
    let title: string = "Confirm Contact Log Entry Delete",
        msg: string = `Are you sure you want to delete the ${contactLog.contact_datetime?moment(contactLog.contact_datetime).format('MMM Do, YYYY') + " ":""}contact log entry titled '${contactLog.title}'?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.contactLogSvc.delete(contactLog.contact_log_id))
    )
    .subscribe((response: any) => this.dialogRef.close(true));
  }

  ngOnInit(): void {
    this.viewMode = this.entryData.viewMode || "edit";
    this.contactLogForm = this.fb.group({
      contact_log_id: [],
      title: [''],
      contact_datetime: [new Date()],
      contact_notes: [''],
      contact_type: [''],
      contacted_by: [''],
      status: [],
      date_completed: [],
      prepared_by: [''],
      contact_link: [this.entryData.person]
    });
    this.contactLog = this.entryData.contactLog;
    if (typeof this.entryData.contactLog !== "undefined") {
      if (this.entryData.contactLog !== null) this.contactLogForm.patchValue(this.entryData.contactLog);
    }
  }
}