import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { filter, switchMap } from 'rxjs/operators';

import { ActionService, Action } from '../action.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { DatetimefxService } from '@services/datetimefx.service';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit {
  person: any = {};
  action: any = {};
  actionForm: FormGroup;
  viewMode: string = "view";
  statusOptions: any[] = ["Pending", "Assigned", "Complete"];

  constructor(@Inject(MAT_DIALOG_DATA) public entry: any,
              private fb: FormBuilder,
              private actionSvc: ActionService,
              private dialogSvc: DialogService,
              public dialogRef: MatDialogRef<ActionDialogComponent>,
              private dtf: DatetimefxService) { }

  ngOnInit(): void {
    this.actionForm = this.fb.group({
      action_id: [],
      name: [''],
      status: [],
      action_lead: [],
      date_completed: [],
      person_link: [],
      notes: ['']
    });
    if (this.entry.viewMode) this.viewMode = this.entry.viewMode;
    if (this.entry.action !== null) {
      if (Object.keys(this.entry.action).length > 0) {
        if (typeof this.entry.action.action_id !== "undefined") {
          this.actionForm.patchValue(this.entry.action);
          this.action = this.entry.action;
        }
      }
    }
    if (typeof this.entry.person !== "undefined") {
      this.person = this.entry.person;
      this.actionForm.get('person_link').setValue(this.person.person_id);
    }
  }
  
  save = (action: Action) => {
    if (action.date_completed) action.date_completed = this.dtf.local_datetime(action.date_completed);
    
    if (action.action_id) {
      this.actionSvc.update(action).subscribe((response: any) => this.dialogRef.close(true));
    } else {
      this.actionSvc.create(action).subscribe((response: any) => this.dialogRef.close(true));
    }
  }
  delete = (action: any) => {
    let title: string = "Confirm Action Delete",
        msg: string = `Are you sure you want to delete the action titled '${action.name}'?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.actionSvc.delete(action.action_id))
    ).subscribe((response: any) => this.dialogRef.close(true));
  }
}