import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { mergeMap } from 'rxjs/operators';

import { ListService } from '../list.service';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-list-manager',
  templateUrl: './list-manager.component.html',
  styleUrls: ['./list-manager.component.scss']
})
export class ListManagerComponent implements OnInit {
  listForm: FormGroup;
  selectedList: any = {list_id: null};

  constructor(public listSvc: ListService,
              private fb: FormBuilder,
              private dialogSvc: DialogService,
              ) { }

  ngOnInit(): void {
    this.listForm = this.fb.group({
      list: ['']
    });
  }
  
  createList = (list) => {
    this.listSvc.createList(list).subscribe((httpResponse: any) => {
      this.listSvc.lists.next(httpResponse);
    });
  }
  deleteList = (list) => {
    if (this.selectedList.list_id) {
      this.dialogSvc.confirmDialog("Confirm List Delete", `Are you sure you want to delete the '${list.list}' list?`)
      .pipe(mergeMap((resp) => {if (resp) return this.listSvc.deleteList(list.list_id)}))
      .subscribe((httpResponse: any) => this.listSvc.lists.next(httpResponse.lists));
    }
  }

}