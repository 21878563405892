<div class="container-fluid h-100 d-flex flex-column overflow-auto">
  <div class="row h-100 flex-shrink-1 overflow-auto">
    <div [ngClass]="{'d-none': !showSideBar}" class="col-lg-4 col-sm-12 h-100 overflow-auto d-flex flex-column">
      <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
      <div class="row">
        <div class="col-12">
          <mat-paginator (page)="getFileUploadPage($event)"
               [length]="(fileSvc.uploadedFiles$ | async).length"
               [pageSize]="fileListPageSize"
               [pageSizeOptions]="tablePageSizeOptions">
          </mat-paginator>
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">
                  <span>Uploaded Files</span>
                  <button mat-icon-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </th>
                <th scope="col"><span>Upload Date</span></th>
                <th scope="col" class="text-center"><span>Action</span></th>
              </tr>
            </thead>
            <tbody>
              <tr [ngClass]="{'selected': selFile.imported_list_id === file.imported_list_id}"
                  *ngFor="let file of fileSvc.uploadedFiles$ | async | slice:(fileListPageIndex * fileListPageSize):((fileListPageIndex + 1) * fileListPageSize)">
                <td scope="row">
                  <span style="cursor: pointer;" (click)="setSelectedFile(file)">{{file.file_name}}</span>
                  <div *ngIf="file.imported_list_id === selFile.imported_list_id">
                    <div class="sheet-tab pointer" *ngFor="let sheet of file.sheets"
                         [ngClass]="{'selected-sheet': sheet.worksheet_name === selSheet}"
                         (click)="selSelectedWorksheet(file.imported_list_id, sheet.worksheet_name)">
                      <span><b>Sheet: </b>{{sheet.worksheet_name}}</span>
                    </div>
                  </div>
                </td>
                <td><span>{{file.date_created | date: 'short'}}</span></td>
                <td class="text-center"><button class="small-icon-btn" mat-icon-button [matMenuTriggerFor]="fileActionMenu"><mat-icon>more_vert</mat-icon></button></td>
                <mat-menu class="compact-menu" #fileActionMenu="matMenu">
                  <button mat-menu-item (click)="deleteFile(file)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete and Remove File</span>
                  </button>
                </mat-menu>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" style="margin-bottom: 15px;">
        <div class="col-12">
          <button mat-flat-button color="primary" [disabled]="!selFile.imported_list_id"
                  (click)="importFileIntoTemporaryTable(selFile.imported_list_id, {sheetName: selSheet, firstRow: 1})"
                  title="Import File Into Temporary Table">
            <mat-icon>publish</mat-icon>
          </button>
          <button mat-flat-button color="primary"
                  (click)="showNewColumnForm = !showNewColumnForm"
                  title="Add New Column to Temporary Table">
            <mat-icon>add_circle</mat-icon>
          </button>
          <button mat-flat-button color="primary"
                  (click)="importList()">
            <span>Import Primary Table</span>
          </button>
          <button mat-flat-button color="primary"
                  (click)="importSecondaryTables()">
            <span>Import Secondary Tables</span>
          </button>
          <button mat-icon-button color="primary"
                  (click)="importDistinctTable()">
            <mat-icon>quiz</mat-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div><span><span class="fw-bold">Total Rows: </span><span>{{tempTotalTableRowCount | number: '0.'}}</span></span></div>
          <div><span><span class="fw-bold">Suspected Duplicates: </span><span>{{suspectedDuplicateCount | number: '0.'}}</span></span></div>
          <div><span><span class="fw-bold">Confirmed Duplicates: </span><span>{{confirmedDuplicateCount | number: '0.'}}</span></span></div>
          <div><span><span class="fw-bold">Rows Synced for Importation: </span><span>{{syncedRows | number: '0.'}}</span></span></div>
          <div><span><span class="fw-bold">Remaning Non-Duplicates for Import: </span><span>{{remainingRows | number: '0.'}}</span></span></div>
        </div>
        <div class="col">
          <div><span><span class="fw-bold">Total Warnings: </span><span>{{tempTableWarnings | number: '0.'}}</span></span></div>
          <div><span><span class="fw-bold">Records with Warnings: </span><span>{{tempTableWarningRows | number: '0.'}}</span></span></div>
          <div><span><span class="fw-bold">Total Errors: </span><span>{{tempTableErrors | number: '0.'}}</span></span></div>
          <div><span><span class="fw-bold">Records with Errors: </span><span>{{tempTableErrorRows | number: '0.'}}</span></span></div>
        </div>
      </div>
      <div class="row overflow-auto" *ngIf="showNewColumnForm">
        <form class="col-12" [formGroup]="newColumnForm">
          <div>
            <mat-form-field appearance="legacy" class="w-100">
              <mat-label>New Column Name</mat-label>
              <input matInput formControlName="columnName" name="columnName">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="legacy" class="w-100">
              <mat-label>Data Type</mat-label>
              <input matInput formControlName="dataType" name="dataType">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="legacy" class="w-100">
              <mat-label>Data Type Options</mat-label>
              <input matInput formControlName="dataTypeOptions" name="dataTypeOptions">
            </mat-form-field>
          </div>
          <div>
            <button mat-flat-button color="primary"
                    (click)="addColumnToTempTable(newColumnForm.value)">
              <span>Add New Column</span>
            </button>
          </div>
        </form>
      </div>
      <div class="row flex-grow-1 flex-shrink-1 overflow-auto" cdkDropListGroup>
        <div class="col-3 h-100 overflow-auto d-flex flex-column">
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Add New Value for Import</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
          </div>
          <ul class="listbox w-100 overflow-auto flex-shrink-1"
              cdkDropList cdkDropListSortingDisabled>
            <li *ngFor="let column of tempHeaders"
                style="margin: 0; padding: 0;"
                class="pointer ellipsis w-100"
                [ngClass]="{'selected': selTempColumn === column.name}"
                (click)="selTempColumn = column.name;"
                cdkDrag [cdkDragData]="column">
              <span [title]="fileSvc.tableNameDisplay(column.name)">
                {{fileSvc.tableNameDisplay(column.name)}}
                <span *ngFor="let dest of destinationFields(column); let last = last; let first = first;">
                  <span *ngIf="first"> ---> </span>
                  <span (dblclick)="removeMapping(dest)">{{dest.to.TABLE_NAME + "." + dest.to.COLUMN_NAME}}</span>
                  <span *ngIf="!last">, </span>
                </span>
              </span>
            </li>
          </ul>
        </div>
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Import Destination Table</mat-label>
                <mat-select [(value)]="importTable" (selectionChange)="getTable($event)">
                  <mat-option *ngFor="let tbl of getImportTables()" [value]="tbl">
                    <span>{{fileSvc.tableNameDisplay(tbl)}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <ul class="listbox">
              <li *ngFor="let column of destinationTable.columns"
                  [title]="column.COLUMN_NAME"
                  cdkDropList [cdkDropListData]="column"
                  (cdkDropListDropped)="mapField($event)">
                <span>{{fileSvc.tableNameDisplay(column.COLUMN_NAME)}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-6" *ngIf="importSequence | async as importSeq">
          <div *ngFor="let sequence of importSeq; let i = index" style="margin-top: 10px;"
               class="w-100 sequence-container"
               [ngClass]="{'selected-sequence': i === sequenceDisplayIndex && tableView === 'sequence'}">
            <ng-container [ngSwitch]="i">
              <div *ngSwitchCase="0" class="d-flex flex-row">
                <span class="flex-grow-1 flex-shrink-1 fw-bold text-decoration-underline">Primary Import</span>
                <button mat-icon-button class="small-icon-btn"
                        (click)="getFieldMapData(selFile.imported_list_id, selSheet, i)">
                  <mat-icon>table_view</mat-icon>
                </button>
              </div>
              <div *ngSwitchDefault class="d-flex flex-row">
                <span class="flex-grow-1 flex-shrink-1 fw-bold text-decoration-underline">Secondary Import {{i}}</span>
                <button mat-icon-button class="small-icon-btn"
                        (click)="getFieldMapData(selFile.imported_list_id, selSheet, i)">
                  <mat-icon>table_view</mat-icon>
                </button>
                <button class="small-icon-btn" mat-icon-button color="warn">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div>
                <mat-slide-toggle color="primary" [(ngModel)]="sequence.distinct"
                                  (change)="saveFieldMap()">
                  <span>DISTINCT</span>
                </mat-slide-toggle>
              </div>
            </ng-container>
            <ul style="margin: 0;">
              <li *ngFor="let fldMap of sequence.fieldMappings; let mapIndex = index;"
                  (dblclick)="removeFieldMapping(i, mapIndex)" style="margin: 0;">
                <span>{{fldMap.from.name}} --> {{fldMap.to.TABLE_NAME}}.{{fldMap.to.COLUMN_NAME}}</span>
              </li>
            </ul>
          </div>
          <button color="primary" mat-icon-button
                  title="Add New Import Sequence"
                  (click)="addImportSequence()">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg col-sm-12 h-100 overflow-auto d-flex flex-column">
      <div class="row table-selectors">
        <div class="col-auto">
          <button mat-icon-button (click)="showSideBar = !showSideBar">
            <mat-icon>{{showSideBar?"close":"menu_open"}}</mat-icon>
          </button>
        </div>
        <div *ngFor="let tblView of tableViewOptions" [ngSwitch]="tblView.value" class="col text-center">
          <span *ngSwitchCase="'file'"
                [ngClass]="{'selected': tableView === tblView.value}"
                (click)="tableView = tblView.value">
            {{tblView.label}}
          </span>
          <span *ngSwitchCase="'temporary'"
                [ngClass]="{'selected': tableView === tblView.value}"
                (click)="tableView = tblView.value">
            {{tblView.label}}
          </span>
          <span *ngSwitchCase="'duplicate'"
                [ngClass]="{'selected': tableView === tblView.value}"
                (click)="tableView = tblView.value">
            {{tblView.label}}
          </span>
          <span *ngSwitchDefault
                (click)="tableView = tblView.value"
                [ngClass]="{'selected': tableView === tblView.value}">
            {{tblView.label}}
          </span>
        </div>
      </div>
      <div [ngSwitch]="true" class="row overflow-auto flex-grow-1 flex-shrink-1" *ngIf="tempTableData$ | async as tempData">
        <div *ngSwitchCase="tableView === 'file'" class="col-12 h-100 overflow-auto d-flex flex-column">
          <mat-spinner *ngIf="loadingStatus.fileTable" style="margin: auto;"></mat-spinner>
          <mat-paginator (page)="fileTablePageChange($event)"
                         [length]="fileRowCount"
                         [pageSize]="fileTablePageSize"
                         [pageSizeOptions]="tablePageSizeOptions">
          </mat-paginator>
          <div class="file-table flex-shrink-1 overflow-auto"
               [ngClass]="{'single-line': tempTableSingleLineCell}"
               [innerHTML]="fileHtml"></div>
        </div>
        <div *ngSwitchCase="tableView === 'temporary' || tableView === 'import'" class="col-12 h-100 overflow-auto d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="flex-grow-1 flex-shrink-1" style="padding: 5px;">
              <button mat-icon-button [disabled]="!undoStore.length" (click)="undoAction(tempData)"><mat-icon>undo</mat-icon></button>
              <button mat-icon-button [disabled]="!redoStore.length" (click)="redoAction(tempData)"><mat-icon>redo</mat-icon></button>
              <button mat-icon-button color="primary" (click)="saveRecords()"
                      [disabled]="!getUnsavedRecordCount()"><mat-icon [matBadge]="getUnsavedRecordCount()" matBadgeColor="warn">save</mat-icon></button>
              <mat-slide-toggle style="margin-left: 10px;" [(ngModel)]="tempAutoSave" color="primary">AutoSave</mat-slide-toggle>
              <button mat-icon-button><mat-icon [matBadge]="tempTableErrors" matBadgeColor="warn" color="warn">error</mat-icon></button>
              <button mat-icon-button><i [matBadge]="tempTableWarnings" matBadgeColor="warn" style="color: orange;" class="material-icons material-icons-two-tone">warning</i></button>
              <mat-checkbox style="margin-left: 10px;" [ngModel]="hideConfirmedDuplicates$ | async"
                            (ngModelChange)="hideConfirmedDuplicates$.next($event)">Hide Confirmed Duplicates</mat-checkbox>
              <mat-checkbox style="margin-left: 10px;" [(ngModel)]="tempTableSingleLineCell">Single Line</mat-checkbox>
            </div>
            <mat-paginator class="flex-grow-1 flex-shrink-1"
                           [length]="tempRowCount"
                           [pageSize]="tempTablePageSize"
                           [pageSizeOptions]="tablePageSizeOptions"
                           (page)="getTempTablePage($event)">
            </mat-paginator>
          </div>
          <div class="overflow-auto w-100 flex-shrink-1" [ngClass]="{'flex-grow-1': loadingStatus.tempTable}">
            <mat-spinner *ngIf="loadingStatus.tempTable" style="margin: auto;"></mat-spinner>
            <table *ngIf="!loadingStatus.tempTable" class="table table-hover table-sm overflow-auto sticky-header h-100 w-100">
              <thead>
                <tr style="z-index: 10;">
                  <th><mat-checkbox title="Select all displayed records"
                                    [checked]="allValidSelected"
                                    [indeterminate]="someValidSelected()"
                                    (change)="selectAllValidRows($event.checked)"></mat-checkbox></th>
                  <th *ngFor="let tempCol of tempHeaders">
                    <div>
                      <span class="pointer" [matMenuTriggerFor]="validColumnActionMenu">{{fileSvc.displayText(tempCol.name)}}</span>
                    </div>
                    <mat-menu class="compact-menu" #validColumnActionMenu="matMenu">
                      <button mat-menu-item (click)="clearAllSelectedRows()">Clear All Selected Rows</button>
                      <button mat-menu-item [matMenuTriggerFor]="moveDestinationFieldMenu">Move Selected {{fileSvc.displayText(tempCol.name)}}</button>
                      <mat-menu class="compact-menu" #moveDestinationFieldMenu="matMenu">
                        <button mat-menu-item *ngFor="let field of moveFields(tempCol.name)"
                                (click)="moveSelected(tempData, tempCol.name, field.name)">
                          <span>{{fileSvc.displayText(field.name)}}</span>
                        </button>
                      </mat-menu>
                      <!-- <mat-option mat-menu-item (click)="selectRowsWithInvalidValues(fldMap)">Select Rows with Invalid {{fileSvc.displayText(fldMap.from.name)}}</mat-option>
                      <mat-option mat-menu-item (click)="selectRowsWithSpaces(fldMap, 1)">Select Items with One Space</mat-option>
                      <mat-option mat-menu-item (click)="selectRowsWithPhoneSymbols(fldMap)">Select Rows with Phone Symbols</mat-option> -->
                      <mat-divider></mat-divider>
                      <!-- <mat-option mat-menu-item (click)="splitSelectedFirstLastNames(fldMap.from.name)">Split Selected Rows Into First and Last Name</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedFirstLastAtFirstSpace(fldMap.from.name)">Split Selected Rows Into First and Last Name at First Space</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedFirstLastAtNthCharacter(fldMap.from.name, 2, ' ')">Split Selected Rows at Second Space</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedFirstLastAtNthCharacter(fldMap.from.name, 3, ' ')">Split Selected Rows at Third Space</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedPrefixFirstName(fldMap.from.name)">Split Selected Rows Into Prefix and First Name</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedPrefixFirstMiddleLast(fldMap.from.name)">Split Selected Rows Into Prefix, First, Middle, and Last</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedPrefixFirstLast(fldMap.from.name)">Split Selected Rows Into Prefix, First and Last Name</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedPrefixLastName(fldMap)">Split Selected Rows Into Prefix and Last Name</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedFirstMiddleName(fldMap.from.name)">Split Select Rows Into First and Middle Name</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedFirstMiddleLastNames(fldMap.from.name)">Split Selected Rows Into First, Middle, and Last Names</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedFirstMiddleLastSuffix(fldMap.from.name)">Split Selected Rows Into First, Middle, Last, and Suffix</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedLastNameSuffix(fldMap.from.name)">Split Selected Rows Into Last Name and Suffix</mat-option>
                      <mat-option mat-menu-item (click)="splitSelectedFieldSuffixAtFirstComma(fldMap.from.name)">Split Select Rows Into {{fileSvc.displayText(fldMap.from.name)}} and Suffix at Comma</mat-option> -->
                      <button mat-menu-item (click)="trimSelectedValues(tempCol.name)">Trim Selected Values</button>
                      <button mat-menu-item (click)="splitSelectedColumnIntoAddressCityStateZip(tempCol.name)">Split Select Rows Into Address, City, State and Zip</button>
                      <mat-divider></mat-divider>
                      <button mat-menu-item (click)="moveSelectedToNotes(tempCol.name)">Move Selected {{fileSvc.displayText(tempCol.name)}} Cell Value to Notes</button>
                      <button mat-menu-item (click)="removePhoneSymbols(tempCol.name)">Remove Phone Symbols from Selected Rows</button>
                      <button mat-menu-item (click)="allCapsSelected(tempCol.name)">Convert Selected to All Caps</button>
                      <button mat-menu-item (click)="convertSelectedToProperCase(tempData, tempCol.name)">
                        <span>Convert {{fileSvc.displayText(tempCol.name)}} in selected rows to Proper Case</span>
                      </button>
                      <button mat-menu-item (click)="deleteCellContents(tempCol.name)">Delete Cell Contents</button>
                    </mat-menu>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th class="pointer" *ngFor="let tempCol of tempHeaders" [matMenuTriggerFor]="selectDestination">
                    <div *ngFor="let dest of getFieldDestinations(tempCol.name)" style="min-height: 20px;">
                      <span class="ellipsis">{{dest.to.TABLE_NAME + "." + dest.to.COLUMN_NAME}}</span>
                    </div>
                    <mat-menu #selectDestination="matMenu">
                      <ng-container *ngFor="let tbl of getImportTableSchema()">
                        <button mat-menu-item [matMenuTriggerFor]="fieldMenu">{{fileSvc.displayText(tbl.TABLE_NAME)}}</button>
                        <mat-menu #fieldMenu="matMenu">
                          <button mat-menu-item *ngFor="let col of tbl.columns">{{fileSvc.displayText(col.COLUMN_NAME)}}</button>
                        </mat-menu>
                      </ng-container>
                    </mat-menu>
                  </th>
                </tr>
              </thead>
              <tbody class="overflow-auto">
                <tr *ngFor="let row of tempData; let index = index"
                    [ngClass]="{'selected-row': row._selected, 'unsaved': row._unsaved}">
                  <td><mat-checkbox [(ngModel)]="row._selected"></mat-checkbox></td>
                  <td *ngFor="let col of tempHeaders"
                      [ngClass]="{'warning': getWarningClass(row, col), 'error': getErrorClass(row, col)}">
                    <span class="pointer" (click)="openTemporaryTableDialog(row, index)"
                          [ngClass]="{'ellipsis': tempTableSingleLineCell}">{{row[col.name]}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngSwitchCase="tableView === 'duplicate'" class="col-12 h-100 overflow-auto">
          <app-deduplication-desktop [selected-file]="selFile"
                                     [selected-sheet]="selSheet"
                                     [temp-table-data]="tempTableData$"
                                     [import-sequence]="importSequence"
                                     [duplicate-rows]="duplicateRows$"
                                     (update-temp-table-row)="updateDuplicateRow($event)">
          </app-deduplication-desktop>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hoverFormVisible"
       style="background-color: white; border: solid 1px gray; border-radius: 3px; box-shadow: 10px 5px 5px black; position: absolute;"
       [ngStyle]="{'top': hoverFormY + 'px', 'left': hoverFormX + 'px'}">
    <ul>
      <li><b>Source Column:</b> {{hoverObj.from.name}}</li>
      <li><b>Destintion Table:</b> {{hoverObj.to.TABLE_NAME}}</li>
      <li><b>Destination Column:</b> {{hoverObj.to.COLUMN_NAME}}</li>
    </ul>
  </div>
</div>