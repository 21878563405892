import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { of } from 'rxjs';
import { filter, debounceTime, switchMap } from 'rxjs/operators';

import * as moment from 'moment';
import 'moment-timezone';

import { DonationsService } from '@modules/donor-management/donations.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { DocumentService, Document } from '@modules/documents/document.service';
import { UtilityService } from '@services/utility.service';
import { DatetimefxService } from '@services/datetimefx.service';

@Component({
  selector: 'app-donation-dialog',
  templateUrl: './donation-dialog.component.html',
  styleUrls: ['./donation-dialog.component.scss']
})
export class DonationDialogComponent implements OnInit {
  addDonationTagControl: FormControl = new FormControl();
  donation: any = {donationTags: [], documents: []};
  donationForm: FormGroup;
  defaultRecipient: string;
  viewMode: string = "edit";
  donorSearchText: string = "";
  searchResults = [];
  giftTypes: string[] = ["Check", "Anedot", "Wire Transfer", "Stock Gift", "In-Kind", "Other"];
  donationTags: any[] = [];
  documents: Document[] = [];
  
  constructor(@Inject(MAT_DIALOG_DATA) public entry: any,
              private fb: FormBuilder,
              public donationSvc: DonationsService,
              private dialogSvc: DialogService,
              private documentSvc: DocumentService,
              private dtf: DatetimefxService,
              public dialogRef: MatDialogRef<DonationDialogComponent>,
              public utilitySvc: UtilityService,
              private peopleSvc: PeopleService
              ) { }

  ngOnInit(): void {
    this.donationForm = this.fb.group({
      donation_id: [],
      recipient: [this.donationSvc.defaultRecipient],
      amount_pledged: [],
      amount_paid: [],
      gift_type: [''],
      date: [new Date()],
      notes: [],
      contact_link: []
    });
    if (this.entry.viewMode) this.viewMode = this.entry.viewMode;
    if (typeof this.entry.donation === "object") {
      if (Object.keys(this.entry.donation).length > 0) {
        if (typeof this.entry.donation.donation_id !== "undefined") {
          this.donationForm.patchValue(this.entry.donation);
          this.donation = this.entry.donation;
        }
      }
    }
    if (typeof this.entry.person !== "undefined") {
      this.donationForm.get('contact_link').setValue(this.entry.person);
    }
    this.donationForm.get('contact_link').valueChanges.pipe(
      debounceTime(300),
      filter((text: any) => typeof text === "string"),
      switchMap((text: string) => {
        if (text) return this.peopleSvc.searchAll(text);
        return of([]);
      })
    ).subscribe((response: any[]) => {
      this.searchResults = response;
    });
    this.addDonationTagControl.valueChanges.pipe(
      debounceTime(300),
      filter((text: string) => typeof text === "string"),
      switchMap((text: string) => text?this.donationSvc.searchDonationTags(text):of([]))
    ).subscribe((response: any[]) => {
      this.donationTags = response;
    });
  }
  
  autoDisplay = (person: any) => {
    if (person.person_id) return this.utilitySvc.getPersonName(person);
    return "";
  }
  displayNull = () => {
    return "";
  }
  
  save = (donation: any) => {
    if (donation.amount_pledged === "") delete donation.amount_pledged;
    if (donation.amount_paid === "") delete donation.amount_paid;
    donation.date = moment(donation.date).format("YYYY-MM-DD HH:mm:ss");
    if (typeof donation.contact_link === "object" && donation.contact_link.person_id > 0) donation.contact_link = donation.contact_link.person_id;
    donation.documents = this.donation.documents;
    this.donationSvc.save(donation).subscribe(resp => this.dialogRef.close(resp));
  }
  delete = (donation: any) => {
    let title: string = "Confirm Donation Delete",
        msg: string = `Are you sure you want to delete the donation made on ${moment(donation.date).format('MMMM Do, YYYY')}?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.donationSvc.delete(donation.donation_id))
    ).subscribe((response: any) => this.dialogRef.close(true));
  }
  
  selectDonorTag = (donation: any, tag: any) => {
    if (typeof this.donation.donationTags === "undefined") this.donation.donationTags = [];
    if (donation.donation_id) {
      if (tag.id) {
        this.donationSvc.addDonationTag(tag.id, donation.donation_id).subscribe((response: any) => {
          this.donation.donationTags.push(response);
          this.addDonationTagControl.reset('');
        })
      } else {
        this.donationSvc.createAndLinkDonationTag(tag, donation.donation_id).subscribe((response: any) => {
          this.donation.donationTags.push(response);
          this.addDonationTagControl.reset('');
        });
      }
    } else {
      if (tag.id) {
        this.donation.donationTags.push(tag);
        this.addDonationTagControl.reset('');
      } else {
        this.donationSvc.createDonationTag(tag).subscribe((response: any) => {
          this.donation.donationTags.push(response);
          this.addDonationTagControl.reset('');
        });
      }
    }
  }
  removeDonorTag = (tag: any) => {
    if (tag.joinId) {
      this.donationSvc.removeDonationTag(tag.joinId).subscribe((response: any) => {
        
      });
    } else {
      this.donation.donationTags = this.donation.donationTags.filter((tg: any) => tg.id !== tag.id);
    }
  }
  
  onFileSelected = (event: any, id: number) => {
    const file: File = event.target.files[0];
    
    if (file) {
      this.documentSvc.uploadFile(file, id, "donations").subscribe((response: any) => {
        this.donation.documents.push(response);
      });
    }
  }
  downloadFile = (documentKey: string, name: string) => {
    this.documentSvc.getS3File(documentKey).subscribe((response: Blob) => {
      this.documentSvc.downloadFile(response, name);
    });
  }
}