<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <table>
        <thead>
          <tr>
            <th><span>Date Sent</span></th>
            <th><span>Subject</span></th>
            <th><span>Recipient Count</span></th>
            <th><span>AWS Response</span></th>
            <td><span>Actions</span></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of emailSvc.sentItems$ | async">
            <td><span>{{result.date_sent | date: 'short'}}</span></td>
            <td style="max-width: 500px;"><span class="ellipsis">{{result.subject}}</span></td>
            <td style="text-align: right;"><span>{{parse(result.recipients).length | number: '1.0'}}</span></td>
            <td><span>{{result.aws_response}}</span></td>
            <td>
              <button mat-icon-button [matMenuTriggerFor]="emailAction">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #emailAction="matMenu">
                <button mat-menu-item (click)="fixThrottled(result)">
                  <span>Resend to Throttled Emails</span>
                </button>
                <button mat-menu-item (click)="seeThrottled(result.id)">
                  <span>See Throttled Emails</span>
                </button>
                <button mat-menu-item (click)="getEmailReport(result.subject)">
                  <span>Get Email Report</span>
                </button>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button mat-flat-button color="warn" (click)="fixAWSResponses()">
        <span>Transform AWS Responses</span>
      </button>
      <button mat-flat-button color="warn" (click)="fixSendResults()">
        <span>Transform Send Results</span>
      </button>
      <button mat-flat-button color="warn" (click)="insertMessages()">
        <span>Insert Messages</span>
      </button>
      <button mat-icon-button color="warn" [matMenuTriggerFor]="eventMenu">
        <mat-icon>transform</mat-icon>
      </button>
      <mat-menu #eventMenu="matMenu">
        <button mat-menu-item *ngFor="let event of awsEvents"
                (click)="transformAWSResponses(event)">
          <span>{{event}}</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="row" class="overflow-auto">
    <div class="col-12" class="overflow-auto">
      <p><span class="bold">SOURCE: </span>{{response.source | json}}</p>
      <p><span class="bold">RESULT: </span>{{response.output | json}}</p>
      <p style="white-space: pre-wrap"><span class="bold">TEXT: </span>{{response.text}}</p>
    </div>
  </div>
</div>