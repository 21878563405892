import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { Observable } from 'rxjs';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgxMaskModule } from 'ngx-mask';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AuthModule } from '@modules/auth/auth.module';
import { ActivistNotesModule } from '@modules/activist-notes/activist-notes.module';
import { CorrespondenceManagerModule } from '@modules/correspondence-manager/correspondence-manager.module';
import { DashboardsModule } from '@modules/dashboards/dashboards.module';
import { DialogsModule } from '@modules/dialogs/dialogs.module';
import { DonorManagementModule } from '@modules/donor-management/donor-management.module';
import { EmailEditorModule } from '@modules/email-editor/email-editor.module';
import { FileImporterModule } from '@modules/file-importer/file-importer.module';
import { ImporterModule } from '@modules/importer/importer.module'
import { ListManagementModule } from '@modules/list-management/list-management.module';
import { PersonProfilesModule } from '@modules/person-profiles/person-profiles.module';
import { PipesModule } from '@pipes/pipes.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from '@services/config.service';

import { AuthCookieInterceptor } from '@modules/auth/auth-cookie.interceptor'
import { UnauthorizedInterceptor } from '@modules/auth/unauthorized.interceptor';

export function initializeApp(configSvc: ConfigService): () => Observable<any> {
  return configSvc.load();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSidenavModule,
    MatSlideToggleModule,
    ClipboardModule,
    NgxMaskModule.forRoot(),
    AngularEditorModule,
    DragDropModule,
    AppRoutingModule,
    AuthModule,
    ActivistNotesModule,
    CorrespondenceManagerModule,
    DashboardsModule,
    DialogsModule,
    DonorManagementModule,
    EmailEditorModule,
    FileImporterModule,
    ImporterModule,
    ListManagementModule,
    PersonProfilesModule,
    PipesModule,
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthCookieInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
