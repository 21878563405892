<div id="summary-container">
  <table id="summary-table">
  <thead>
    <tr>
      <th class="center"><span>Month</span></th>
      <th class="center"><span>Count</span></th>
      <th><span>Sum</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let result of results(); let index = index" (click)="selIndex.set(index)"
        [ngClass]="{'selected': selIndex() === index}">
      <td class="center">
        <span *ngIf="result.month && result.year">{{result.month}}/{{result.year}}</span>
        <span *ngIf="!(result.month && result.year)">Empty Date</span>
      </td>
      <td class="center"><span>{{result.count | number: '1.0'}}</span></td>
      <td class="right"><span>{{result.sum | currency}}</span></td>
    </tr>
  </tbody>
</table>
</div>
<app-pagination/>
<div id="donation-container">
  <table id="donation-table">
    <thead>
      <tr>
        <th><span>First Name</span></th>
        <th><span>Last Name</span></th>
        <th><span>Date</span></th>
        <th><span>Amount</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let donation of donations()">
        <td><span>{{donation.first_name}}</span></td>
        <td><span>{{donation.last_name}}</span></td>
        <td class="center"><span>{{donation.date | date: 'shortDate'}}</span></td>
        <td class="right"><span>{{donation.amount_paid | currency}}</span></td>
      </tr>
    </tbody>
  </table>
</div>