<ng-container *ngIf="displayMode === 'view'">
  <div fxFlex>
    <div *ngIf="address.label" fxLayout="row" xLayoutAlign="start center" fxLayoutGap="5px">
      <span>
        <span class="header">{{address.label}}</span>
        <span class="header" *ngIf="address.primary_address">&nbsp;(Primary)</span>
      </span>
      <a target="_blank" class="map-icon sm-icon-btn"
         [href]="'https://www.google.com/maps/place/' + peopleSvc.getFullAddress(address).replace(' ', '+')">
        <mat-icon>map</mat-icon>
      </a>
    </div>
    <div *ngIf="address.address">{{address.address}}</div>
    <div *ngIf="address.address2">{{address.address2}}</div>
    <div *ngIf="address.city || address.state || address.zip">
      <span>{{address.city}}</span>
      <span>{{(address.city && address.state)?", ":""}}</span>
      <span>{{address.state}}</span>
      <span>{{((address.city || address.state) && address.zip?" ":"") + address.zip}}</span>
    </div>
  </div>
  <button mat-icon-button class="sm-icon-btn address-menu" [matMenuTriggerFor]="addressMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #addressMenu="matMenu">
    <button mat-menu-item (click)="displayMode = 'edit'">
      <span>Edit '{{address.address}}' Address</span>
    </button>
    <button mat-menu-item (click)="setPrimary(address, person)" *ngIf="!address.primary_address">
      <span>Set '{{address.address}}' as {{peopleSvc.getPersonName(person)}}'s Primary Address</span>
    </button>
    <button mat-menu-item (click)="delete(address, person)">
      <span>Delete '{{address.address}}' Address</span>
    </button>
  </mat-menu>
</ng-container>
<div *ngIf="displayMode === 'edit'" [formGroup]="addressForm" class="address-form">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field fxFlex>
      <mat-label>Label</mat-label>
      <input matInput [matAutocomplete]="addressLabel"
             type="text" formControlName="label">
    </mat-form-field>
    <mat-autocomplete #addressLabel>
      <mat-option *ngFor="let label of addressLabels" [value]="label">
        <span>{{label}}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-checkbox formControlName="primary_address" color="primary" class="margin-below">
      <span>Primary</span>
    </mat-checkbox>
    <button mat-icon-button color="warn" title="Cancel new entry" (click)="reset(address, person)">
      <mat-icon>cancel</mat-icon>
    </button>
    <button mat-icon-button color="primary" [disabled]="addressForm.pristine"
            title="Save new entry" (click)="save(addressForm.value, person)">
      <mat-icon>save</mat-icon>
    </button>
  </div>
  <div>
    <mat-form-field style="width: 100%;">
      <mat-label>Address</mat-label>
      <input matInput type="text" formControlName="address" name="address">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field style="width: 100%;">
      <mat-label>Address 2</mat-label>
      <input matInput type="text" formControlName="address2" name="address2">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" class="w-100">
    <mat-form-field fxFlex="40">
      <mat-label>City</mat-label>
      <input matInput type="text" formControlName="city" name="city">
    </mat-form-field>
    <span>,</span>
    <mat-form-field fxFlex="15">
      <mat-label>State</mat-label>
      <input matInput type="text" formControlName="state" name="state">
    </mat-form-field>
    <mat-form-field fxFlex="25">
      <mat-label>Zip</mat-label>
      <input matInput type="text" formControlName="zip" name="zip" minlength="5" maxlength="5">
    </mat-form-field>
    <span>-</span>
    <mat-form-field fxFlex="20">
      <mat-label>Zip-4</mat-label>
      <input matInput type="text" name="zip4" formControlName="zip4"
             minlength="4" maxlength="4">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>County</mat-label>
      <input matInput type="text" name="county" formControlName="county">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Congressional District</mat-label>
      <input matInput type="text" name="congressional_district" formControlName="congressional_district">
    </mat-form-field>
  </div>
</div>