import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PeopleService } from '@modules/person-profiles/people.service';

@Component({
  selector: 'app-user-email-preferences',
  templateUrl: './user-email-preferences.component.html',
  styleUrls: ['./user-email-preferences.component.scss']
})
export class UserEmailPreferencesComponent implements OnInit {
  email: any;

  constructor(private route: ActivatedRoute,
              private peopleSvc: PeopleService) { }

  ngOnInit(): void {
    console.log(this.route.snapshot.data);
  }

}