<div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
  <div class="login-container">
    <div style="margin-bottom: 25px;">
      <img src="assets/config/logo.png">
    </div>
    <form [formGroup]="loginForm">
      <div>
        <mat-form-field>
          <mat-label>E-mail Address</mat-label>
          <input matInput type="text" formControlName="email" 
                 name="email" (blur)="emailErrorMsg(loginForm.get('email'))">
          <button mat-icon-button matSuffix (click)="loginForm.get('email').reset()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div *ngIf="(authSvc.error | async).type === 'username'" class="error">
          <span>{{(authSvc.error | async).msg}}</span>
        </div>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput [type]="showPassword?'text':'password'"
                 formControlName="password" name="password">
          <button [title]="'Click to ' + (showPassword?'hide':'show') + ' your password'" 
                  matSuffix mat-icon-button (click)="showPassword=!showPassword">
            <mat-icon>{{showPassword?"visibility":"visibility_off"}}</mat-icon>
          </button>
        </mat-form-field>
        <div *ngIf="(authSvc.error | async).type === 'password'" class="error">
          <span>{{(authSvc.error | async).msg}}</span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center">
        <button mat-flat-button color="primary" (click)="login(loginForm.value)"
                [disabled]="loginForm.pristine || !loginForm.valid || !loginForm.get('password').value">
          <span>Login</span>
        </button>
      </div>
    </form>
  </div>
</div>