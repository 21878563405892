import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export type Document = {
  id?: number;
  name?: string;
  notes?: string;
  Location?: string;
  Bucket?: string;
  document_key?: string;
  ServerSideEncryption?: string;
  ETag?: string;
  VersionId?: string;
  date_created?: string;
  user_created?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  url: string = "https://slingofdavid.com:3000/";

  constructor(private http: HttpClient) { }
  
  uploadFile = (file: File, linkedItemId: number, linkedItemClass: string) => {
    const formData = new FormData();
    formData.append("file", file);
    
    return this.http.post(this.url + "documents", formData, {params: {id: linkedItemId, itemClass: linkedItemClass}});
  }
  getS3File = (documentKey: string) => {
    return this.http.get(this.url + "documents/", {params: {documentKey: documentKey}, responseType: 'blob'});
  }
  downloadFile = (response: Blob, fileName: string) => {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(response);
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }
}