<div class="row h-100 overflow-auto">
  <app-duplicate-list #duplicateList
                      class="col-2 h-100"
                      [import-sequence]="importSequence$"
                      [selected-duplicate-row]="selectedDuplicateRow$"
                      [first-last-duplicates]="firstLastDuplicates$"
                      [last-duplicates]="lastDuplicates$"
                      [duplicate-rows]="duplicateRows$"
                      [selected-person]="selectedPerson$"
                      (update-selected-person)="selectedPerson$.next($event)"></app-duplicate-list>
  <app-import-duplicate-display #importDuplicateDisplay
                                class="col-5 h-100"
                                (scroll-event)="scrollEvent($event)"
                                (updated-person)="selectedPerson$.next($event)"
                                (updated-duplicate-row)="updateDuplicateRow($event)"
                                [imported-list-id]="selectedFile.imported_list_id"
                                [sheet-name]="selectedSheet"
                                [import-sequence]="importSequence$"
                                [duplicate-row]="selectedDuplicateRow$"
                                [selected-person]="selectedPerson$">
  </app-import-duplicate-display>
  <app-person class="col-5" [person]="selectedPerson$"></app-person>
</div>