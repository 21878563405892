import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  settings: BehaviorSubject<any> = new BehaviorSubject<any>({apiUrl: ""});
  
  constructor(private http: HttpClient) {
    this.settings.subscribe((settings: any) => {
      console.log("Settings Loaded: ", settings);
    })
  }
  
  load = () => {
    const jsonFile = `assets/config/config.app.json`;
    
    return () => this.http.get(jsonFile).pipe(
      tap((response: any) => {
        this.settings.next(response);
      }),
      catchError(err => {
        return throwError(`Could not load file '${jsonFile}': ${err}`);
      })
    );
  }
  loadSetting = (target: any, key: string) => {
    this.settings.subscribe((settings: any) => {
      target = settings[key];
    });
  }
}