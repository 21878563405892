import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { PeopleService } from '../people.service';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-duplicate-person-view',
  templateUrl: './duplicate-person-view.component.html',
  styleUrls: ['./duplicate-person-view.component.scss']
})
export class DuplicatePersonViewComponent implements OnInit {
  @Input('person') person: any = {};
  @Input('primary') primary$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input('unsaved-primary') unsavedPrimary$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Output('set-primary') setPrimary: EventEmitter<any> = new EventEmitter<any>();
  @Output('update-primary') updatePrimary: EventEmitter<any> = new EventEmitter<any>();
  @Output('remove-person') removePerson: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('update-unsaved-primary') updateUnsavedPrimary: EventEmitter<any> = new EventEmitter<any>();
  fields: any[] = [
    {label: "Prefix", value: "prefix", defaultClick: "replace"},
    {label: "First Name", value: "first_name", defaultClick: "replace"},
    {label: "Middle Name", value: "middle_name", defaultClick: "replace"},
    {label: "Last Name", value: "last_name", defaultClick: "replace"},
    {label: "Suffix", value: "suffix", defaultClick: "replace"},
    {label: "Preferred Name", value: "preferred_name", defaultClick: "replace"},
    {label: "Spouse", value: "spouse", defaultClick: "replace"},
    {label: "Name on Envelope", value: "name_on_envlope", defaultClick: "replace"},
    {label: "Company", value: "company", defaultClick: "replace"},
    {label: "Occupation", value: "occupation", defaultClick: "replace"},
    {label: "Notes", value: "notes", defaultClick: "replace"},
    {label: "Image URL", value: "img_url", defaultClick: "replace"},
    {label: "Signup Date", value: "signup_date", defaultClick: "replace", date: true},
    {label: "Verified", value: "verified", defaultClick: "replace"},
    {label: "Date Verified", value: "date_verified", defaultClick: "replace", date: true},
    {label: "User Verified", value: "user_verified", defaultClick: "replace"}
  ];
  
  constructor(private peopleSvc: PeopleService,
              private dialogSvc: DialogService) {}
  
  ngOnInit() {
    
  }
  
  clearUnsaved = () => {
    this.dialogSvc.confirmDialog("Clear Unsaved Changes", "Are you sure you want to clear unsaved changes?").pipe(
      filter((response: boolean) => response)
    ).subscribe(() => this.unsavedPrimary$.next({}));
  }
  saveDuplicate = (unsavedPrimary: any) => {
    console.log("UNSAVED PRIMARY: ", unsavedPrimary);
    this.peopleSvc.saveUnsavedPrimary(unsavedPrimary).subscribe((response: any) => {
      this.updatePrimary.emit(response);
      this.updateUnsavedPrimary.emit({});
    });
  }
  deletePerson = (person: any) => {
    let title: string = "Confirm Delete",
        message: string = `Are you sure you want to delete ${person.first_name} ${person.last_name}'s record?`;
    
    this.dialogSvc.confirmDialog(title, message).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.peopleSvc.deletePerson(person.person_id))
    ).subscribe((response: any) => this.removePerson.emit(true));
  }
  addNonDuplicate = (person1_id: number, person2_id: number) => {
    this.peopleSvc.addNonDuplicate(person1_id, person2_id).subscribe((response) => {
      this.removePerson.emit(true);
    });
  }
  
  update = (field: any, person: any, primary: any, unsavedPrimary: any, unsavedPrimary$: BehaviorSubject<any>, override: boolean) => {
    if (person[field.value] !== primary[field.value] && (field.value !== 'notes' || override)) {
      unsavedPrimary[field.value] = person[field.value];
      if (!unsavedPrimary.person_id) unsavedPrimary.person_id = primary.person_id;
      this.updateUnsavedPrimary.emit(unsavedPrimary);
    } else if (person.person_id === primary.person_id && unsavedPrimary[field.value] !== primary[field.value]) {
      delete unsavedPrimary[field.value];
      if (Object.keys(unsavedPrimary).length === 1 && unsavedPrimary.hasOwnProperty("person_id")) unsavedPrimary = {};
      this.updateUnsavedPrimary.emit(unsavedPrimary);
    }
  }
  addText = (primary: any, unsavedPrimary: any, key: string, text: string) => {
    unsavedPrimary[key] = (unsavedPrimary[key] || primary[key]) + "; " + text;
    this.updateUnsavedPrimary.emit(unsavedPrimary);
  }
  replaceElementw = (item: any, unsavedPrimary: any, itemType: string, key: string, idField: string, primary: any) => {
    let unsavedPrimaryItemIndex: number = unsavedPrimary[itemType].findIndex((upItem: any) => upItem[idField] === item[idField]);
    
    if (unsavedPrimaryItemIndex > -1) {
      unsavedPrimary[itemType][unsavedPrimaryItemIndex][key] = item[key];
    } else {
      let updatedItem: any = primary[itemType].find((primaryItem: any) => primaryItem[idField] === item[idField]);
      
      updatedItem[key] = item[key];
      unsavedPrimary[itemType].push(updatedItem);
    }
    console.log("Unsaved Primary: ", unsavedPrimary);
    this.updateUnsavedPrimary.emit(unsavedPrimary);
  }
  replaceElement = (primaryItem: any, updateItem: any, unsavedPrimary: any, itemType: string, key: string, primary: any) => {
    let item: any = JSON.parse(JSON.stringify(primaryItem));
    item[key] = updateItem[key];
    if (!unsavedPrimary.person_id) unsavedPrimary.person_id = primary.person_id;
    if (!unsavedPrimary[itemType]) {
      unsavedPrimary[itemType] = [item];
    } else {
      unsavedPrimary[itemType].push(item);
    }
    this.updateUnsavedPrimary.emit(unsavedPrimary);
  }
  
  duplicateEmails = (email: any, primary: any, unsavedPrimary: any) => {
    let primaryEmails: any[] = [
      ...(primary.email_addresses || []),
      ...(unsavedPrimary.email_addresses || [])
    ];
    
    return !(primaryEmails || []).some((primaryEmail: any) => primaryEmail.email === email.email);
  }
  duplicatePhones = (phone: any, primary: any, unsavedPrimary: any) => {
    let primaryPhones: any[] = [
      ...(primary.phone_numbers || []),
      ...(unsavedPrimary.phone_numbers || [])
    ];
    
    return !(primaryPhones || []).some((primaryPhone: any) => primaryPhone.phone_number === phone.phone_number);
  }
  duplicateAddresses = (address: any, primary: any, unsavedPrimary, key: string) => {
    let primaryAddresses: any[] = [
      ...(primary.addresses || []),
      ...(unsavedPrimary.addresses || [])
    ];
    
    return !(primaryAddresses || []).some((primaryAddress: any) => primaryAddress[key] === address[key]);
  }
  duplicateReviews = (review: any, primary: any, unsavedPrimary: any, key: string) => {
    let primaryReviews: any[] = [
      ...(primary.reviews || []),
      ...(unsavedPrimary.reviews || [])
    ];
    
    return !(primaryReviews || []).some((primaryReview: any) => primaryReview[key] === review[key]);
  }
  duplicateDonations = (donation: any, primary: any, unsavedPrimary: any) => {
    let primaryDonations: any[] = [
      ...(primary.donations || []),
      ...(unsavedPrimary.donations || [])
    ];
    return !(primaryDonations || []).some((primaryDonation: any) => primaryDonation.date === donation.date && primaryDonation.amount_pledged === donation.amount_pledged && primaryDonation.amount_paid && donation.amount_paid);
  }
  duplicateContactLog = (contactLog: any, primary: any, unsavedPrimary: any, key: string) => {
    let primaryContactLog: any[] = [
      ...(primary.contact_log || []),
      ...(unsavedPrimary.contact_log || [])
    ];
    
    return !(primaryContactLog || []).some((primaryLog: any) => primaryLog[key] === contactLog[key]);
    
  }
  duplicateLists = (list: any, primary: any, unsavedPrimary: any) => {
    let primaryLists: any[] = [
      ...(primary.lists || []),
      ...(unsavedPrimary.lists || [])
    ];
    
    return !(primaryLists || []).some((lst: any) => lst.list === list.list);
  }
  
  
  addItem = (item: any, itemType: string, unsavedPrimary: any, primaryId: number) => {
    if (!unsavedPrimary.person_id) unsavedPrimary.person_id = primaryId
    if (typeof unsavedPrimary[itemType] === "undefined") {
      unsavedPrimary[itemType] = [item];
    } else {
      unsavedPrimary[itemType].push(item);
    }
    this.updateUnsavedPrimary.emit(unsavedPrimary);
  }
  removeItem = (unsavedPrimary: any, itemType: string, index: number) => {
    unsavedPrimary[itemType] = unsavedPrimary[itemType].filter((item: any, idx: number) => idx !== index);
    if (unsavedPrimary[itemType].length === 0) delete unsavedPrimary[itemType];
    if (Object.keys(unsavedPrimary).length === 1 && unsavedPrimary.hasOwnProperty("person_id")) unsavedPrimary = {};
    this.updateUnsavedPrimary.emit(unsavedPrimary);
  }
}