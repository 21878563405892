import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { CorrespondenceDialogComponent } from './correspondence-dialog/correspondence-dialog.component';
import { CorrespondenceMasterComponent } from './correspondence-master/correspondence-master.component';
import { CorrespondenceTableComponent } from './correspondence-table/correspondence-table.component';

@NgModule({
  declarations: [
    CorrespondenceDialogComponent,
    CorrespondenceMasterComponent,
    CorrespondenceTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
  ],
  exports: [
    CorrespondenceDialogComponent,
    CorrespondenceTableComponent
  ]
})
export class CorrespondenceManagerModule { }