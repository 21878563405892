import { Component, ViewChild } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';

import { ImporterService } from '../importer.service';
import { SpreadsheetPreviewComponent } from '../spreadsheet-preview/spreadsheet-preview.component';

@Component({
  selector: 'app-import-frame',
  templateUrl: './import-frame.component.html',
  styleUrls: ['./import-frame.component.scss']
})
export class ImportFrameComponent {
  @ViewChild('spreadsheetPreview') spreadsheetPreview: SpreadsheetPreviewComponent;
  sheet$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  file: any;
  importSteps: string[] = [
    "Upload File",
    "Import Worksheet",
    "Map Fields",
    "Insert Data",
    "Deduplicate Data",
    "Tag Data"
  ];
  selectedSheet$: Subject<any> = new Subject<any>;
  selectedView: string = "Preview";
  
  constructor(private importSvc: ImporterService) {}
  
  onFileSelected = (event: any) => {
    const file: File = event.target.files[0];
    
    if (file) {
      this.importSvc.uploadFile(file).subscribe((response: any) => this.file = response);
    }
  }
  selectSheet = (sheet: any, selectedSheet: any) => {
    if (sheet.id === (selectedSheet || {}).id) {
      this.selectedSheet$.next(null);
    } else {
      this.selectedSheet$.next(sheet);
      this.getSheetData(sheet.id);
    }
  }
  getSheetData = (id: number) => {
    this.importSvc.getSheetData(id).subscribe((response: any) => {
      this.spreadsheetPreview.sheetPreviewData = response.data;
      this.spreadsheetPreview.sheetPreviewHeaders = response.headers;
    });
  }
  importWorksheet = (sheetId: number) => {
    this.importSvc.importWorksheet(sheetId).subscribe((response: any) => {
      this.selectedView = "Field Mapper";
      this.sheet$.next(response);
    });
  }
}