<div id="nav-bar" *ngIf="showNavBar">
  <button *ngIf="authSvc.isLoggedIn()" mat-icon-button
          color="accent" (click)="drawer.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <div fxFlex></div>
  <app-search-bar *ngIf="authSvc.isLoggedIn()"></app-search-bar>
  <button mat-icon-button class="account-btn-icon">
    <mat-icon (click)="userProfileDialog()">account_circle</mat-icon>
  </button>
</div>
<mat-drawer-container [hasBackdrop]="true">
  <mat-drawer #drawer mode="over" class="slg-sidebar-wrapper" fxLayout="column">
    <div *ngFor="let menuItem of menu"  class="list-group">
      <a [routerLink]="[menuItem.path]"
         class="list-group-item list-group-item-action">{{menuItem.label}}</a>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>