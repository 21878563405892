import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, filter, tap } from 'rxjs/operators';

import { PeopleService } from '@modules/person-profiles/people.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  searchControl: FormControl = new FormControl();
  searchResults: BehaviorSubject<any[]> = new BehaviorSubject([]);
  spin: boolean = false;
  
  constructor( public peopleSvc: PeopleService,
               public router: Router
              ) { }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      tap(() => {
        this.spin = true;
        this.searchResults.next([]);
      }),
      switchMap(value => {
        if (value && typeof value === "string") {
          return this.peopleSvc.searchAll(value.trim());
        } else {
          return of([]);
        }
        this.searchControl.reset('');
      })
    ).subscribe((results: any[]) => {
      if (results) {
        if (results.length > 0) {
          this.searchResults.next(results);
        } else {
          this.searchResults.next([{first_name: "No Results", last_name: "Found"}]);
        }
      } else {
        this.searchResults.next([]);
      }
      this.spin = false;
    });
  }
  selectItem = (ev) => {
    this.searchControl.reset();
    this.router.navigateByUrl(`/activists/${ev.option.value.person_id}`);
  }
  displayFn = (person?: any) => {
    return person ? person.first_name + " " + person.last_name : undefined;
  }
}