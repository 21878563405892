import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { BehaviorSubject, of } from 'rxjs';
import { switchMap, startWith, debounceTime, filter } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Input('user') user$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  userForm: FormGroup;
  userGroups: string[] = ["Field Staff", "HQ Staff", "Administrator"];
  formError$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  duplicateUsername: boolean = false;
  @Output('cancel') cancelButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('new-user') newUser: EventEmitter<any> = new EventEmitter<any>();

  constructor(private route: ActivatedRoute,
              private authSvc: AuthService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.user$.subscribe((user: any) => {
      if (this.userForm) {
        this.userForm.reset(user);
        this.userForm.markAsPristine();
      }
    });
    this.route.paramMap.pipe(
      switchMap((map: any) => this.authSvc.getUser(map.get('id')))
    ).subscribe((user: any) => {
      this.user$.next(user);
    });
    this.userForm = this.fb.group({
      user_id: [''],
      first_name: ['', Validators.required],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      confirm_email: ['', [Validators.email]],
      user_group: ['Field Staff'],
      solicitor: [false],
      regions: [],
      create_people: [false],
      edit_people: [false],
      delete_people: [false],
      view_donations: [false],
      create_donations: [false],
      edit_donations: [false],
      delete_donations: [false],
      create_lists: [false],
      edit_lists: [false],
      delete_lists: [false],
      download_lists: [false],
      view_users: [false],
      create_users: [false],
      edit_users: [false],
      delete_users: [false],
      draft_emails: [false],
      send_emails: [false],
      approved: [0],
      single_use_id: [],
      date_created: [],
      user_created: []
    });
    this.userForm.get('email').valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      switchMap((text: string) => {
        if (!!text) {
          return this.authSvc.checkUsername(text);
        } else {
          return of({result: false})
        }
      })
    ).subscribe((response: any) => {
      this.duplicateUsername = response.result;
    });
  }
  
  saveUser = (user: any) => {
    this.authSvc.saveUser(user).subscribe((user: any) => {
      this.user$.next(user);
      this.newUser.emit(user);
    });
  }
  cancel = (user: any) => {
    if (user.id) {
      this.userForm.reset(user);
    } else {
      this.cancelButton.emit(true);
    }
  }
  displayName = (value) => {
    return value.name;
  }
}