import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, of } from 'rxjs';
import { filter, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { NotesService, ActivistNote } from '../notes.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { Person } from '@modules/person-profiles/person.model';
import { ReviewDialogComponent } from '../review-dialog/review-dialog.component';

@Component({
  selector: 'app-activist-notes-table',
  templateUrl: './activist-notes-table.component.html',
  styleUrls: ['./activist-notes-table.component.scss']
})
export class ActivistNotesTableComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  notes$: BehaviorSubject<ActivistNote[]> = new BehaviorSubject<ActivistNote[]>([]);
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 5,
    pageIndex: 0,
    preparedBy: [],
    orderBy: [{field: `reviews.date_created`, order: `DESC`}]
  });
  pageSizeOptions: number[] = [5,10,15,20];
  totalRecords$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalPages: number = 0;

  constructor(private dialogSvc: DialogService,
              private dialog: MatDialog,
              private noteSvc: NotesService,
              private peopleSvc: PeopleService) { }

  ngOnInit(): void {
    this.person$.pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([person, filter]: [any, any]) => {
      if (person.person_id) {
        filter.id = person.person_id;
        this.filter$.next(filter);
      } else {
        this.notes$.next([]);
        this.totalRecords$.next(0);
      }
    });
    this.filter$.pipe(
      filter((filter: any) => !!filter.id),
      switchMap((filter: any) => this.noteSvc.getFilteredNotes(filter))
    ).subscribe((response: any) => {
      this.notes$.next(response.notes);
      this.totalRecords$.next(response.count);
    });
    this.filter$.pipe(
      distinctUntilChanged((prev: any, curr: any) => prev.pageSize === curr.pageSize),
      withLatestFrom(this.totalRecords$)
    ).subscribe(([filter, totalRecords]: [any, number]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
    this.totalRecords$.pipe(withLatestFrom(this.filter$))
    .subscribe(([totalRecords, filter]: [number, any]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
  }
  
  updatePageSize = (pageSize: number, filter: any) => {
    filter.pageSize = pageSize;
    this.filter$.next(filter);
  }
  pageIncrement = (filter: any, pageIncrement: number) => {
    filter.pageIndex = filter.pageIndex + pageIncrement;
    this.filter$.next(filter);
  }
  setPage = (page: number, filter: any) => {
    filter.pageIndex = page;
    this.filter$.next(filter);
  }
  
  delete = (note: any, noteFilter: any) => {
    let title: string = "Confirm Note Delete",
        msg: string = `Are you sure you want to delete the note titled '${note.title}'?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.noteSvc.deleteNote(note.review_id))
    ).subscribe((response: any) => this.filter$.next(noteFilter));
  }
  
  openDialog = (note: ActivistNote, person: any, filter: any, viewMode: string) => {
    let data = {note: note, person: person, filter: filter, viewMode: viewMode},
        dialogRef: MatDialogRef<ReviewDialogComponent> = this.dialog.open(ReviewDialogComponent, {data: data});
    
    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response) this.filter$.next(filter);
    });
  }
}