import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ImportDesktopComponent } from './import-desktop/import-desktop.component';
import { ImportFrameComponent } from './import-frame/import-frame.component';
import { FieldMapperComponent } from './field-mapper/field-mapper.component';
import { SpreadsheetPreviewComponent } from './spreadsheet-preview/spreadsheet-preview.component';

@NgModule({
  declarations: [
    ImportDesktopComponent,
    ImportFrameComponent,
    FieldMapperComponent,
    SpreadsheetPreviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    DragDropModule,
  ],
  exports: [
    ImportDesktopComponent,
    ImportFrameComponent,
  ]
})
export class ImporterModule { }
