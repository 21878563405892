import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PersonComponent } from '@modules/person-profiles/person/person.component';
import { LoginComponent } from '@modules/auth/login/login.component';
import { UsersComponent } from '@modules/auth/users/users.component';
import { UserProfileComponent } from '@modules/auth/user-profile/user-profile.component';
import { ImportFilesComponent } from '@modules/file-importer/import-files/import-files.component';
import { DonationsComponent } from '@modules/donor-management/donations/donations.component';
import { ListManagerComponent } from '@modules/list-management/list-manager/list-manager.component';
import { DonorsComponent } from '@modules/donor-management/donors/donors.component';
import { CorrespondenceMasterComponent } from '@modules/correspondence-manager/correspondence-master/correspondence-master.component';
import { EmailFrameComponent } from '@modules/email-editor/email-frame/email-frame.component';
import { UserEmailPreferencesComponent } from '@modules/email-editor/user-email-preferences/user-email-preferences.component';
import { SignupFormComponent } from '@modules/person-profiles/signup-form/signup-form.component';
import { NewUserComponent } from '@modules/auth/new-user/new-user.component';
import { EditUserPageComponent } from '@modules/auth/edit-user-page/edit-user-page.component';
import { ActivistNoteDashboardComponent } from '@modules/activist-notes/activist-note-dashboard/activist-note-dashboard.component';
import { ImportFrameComponent } from '@modules/importer/import-frame/import-frame.component';
import { DonorDashboardComponent } from '@modules/donor-management/donor-dashboard/donor-dashboard.component';
import { MainDashboardComponent } from '@modules/dashboards/main-dashboard/main-dashboard.component';
import { DeduplicatorComponent } from '@modules/person-profiles/deduplicator/deduplicator.component';
import { SolicitorDashboardComponent } from '@modules/contact-logs/solicitor-dashboard/solicitor-dashboard.component';
import { AnnualPlanningComponent } from '@modules/donor-management/annual-planning/annual-planning.component';
import { GiftDashboardComponent } from '@modules/donor-management/gift-dashboard/gift-dashboard.component';
import { CallDashboardComponent } from '@modules/dashboards/call-dashboard/call-dashboard.component';
import { DonationDashboardComponent } from '@modules/donor-management/donation-dashboard/donation-dashboard.component';

import { PersonResolverService } from '@modules/person-profiles/person-resolver.service';
import { EmailUnsubscribeResolver } from '@modules/email-editor/email-unsubscribe.resolver';
import { AuthGuardService } from '@modules/auth/auth-guard.service';
import { IsAdministratorGuard } from '@modules/auth/is-administrator.guard';

const routes: Routes = [
  { path: '', redirectTo: '/activists/new', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupFormComponent },
  { path: 'activists/:id', component: PersonComponent, resolve: {person: PersonResolverService}, canActivate: [AuthGuardService] },
  { path: 'donations', component: DonationsComponent, canActivate: [AuthGuardService] },
  { path: 'correspondence', component: CorrespondenceMasterComponent, canActivate: [AuthGuardService] },
  { path: 'donors', component: DonorDashboardComponent, canActivate: [AuthGuardService, IsAdministratorGuard] },
  { path: 'list-manager', component: ListManagerComponent, canActivate: [AuthGuardService]},
  { path: 'users', component: UsersComponent, canActivate: [AuthGuardService, IsAdministratorGuard] },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuardService] },
  { path: 'import-files', component: ImportFilesComponent, canActivate: [AuthGuardService, IsAdministratorGuard] },
  { path: 'email-editor', component: EmailFrameComponent, canActivate: [AuthGuardService]  },
  { path: 'unsubscribe/:id', component: UserEmailPreferencesComponent, resolve: {unsub: UserEmailPreferencesComponent} },
  { path: 'new-user/:id', component: NewUserComponent },
  { path: 'user/:id', component: EditUserPageComponent, canActivate: [AuthGuardService] },
  { path: 'notes', component: ActivistNoteDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'import', component: ImportFrameComponent, canActivate: [AuthGuardService] },
  { path: 'dashboard', component: MainDashboardComponent, canActivate: [AuthGuardService]},
  { path: 'deduplicator', component: DeduplicatorComponent, canActivate: [AuthGuardService]},
  { path: 'solicitor-dashboard', component: SolicitorDashboardComponent, canActivate: [AuthGuardService]},
  { path: 'gift-dashboard', component: GiftDashboardComponent, canActivate: [AuthGuardService]},
  { path: 'annual-planning', component: AnnualPlanningComponent, canActivate: [AuthGuardService]},
  { path: 'call-dashboard', component: CallDashboardComponent, canActivate: [AuthGuardService]},
  { path: 'donation-dashboard', component: DonationDashboardComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }