import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from '@modules/auth/auth.service';

@Component({
  selector: 'app-email-frame',
  templateUrl: './email-frame.component.html',
  styleUrls: ['./email-frame.component.scss']
})
export class EmailFrameComponent implements OnInit {
  viewMode: string = "editor";
  viewModeOptions: any[] = [
    {label: "Editor", value: "editor"},
    {label: "Sent Items", value: "sent_items"},
//    {label: "Send Attempts", value: "send_attempts"},
//    {label: "Email Results", value: "results"}
  ];
  email$: Subject<any> = new Subject<any>();
  
  updateEmail = (email: any) => {
    this.viewMode = "editor";
    setTimeout(() => this.email$.next(email), 0);
  }

  constructor(public authSvc: AuthService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(
      filter((params: ParamMap) => !!params.get('view'))
    ).subscribe((params: ParamMap) => this.viewMode = params.get('view'));
  }
  setViewMode = (mode: string) => {
    this.router.navigate([], {relativeTo: this.route, queryParams: {view: mode}, queryParamsHandling: 'merge'});
  }

}