import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { DonorFilter, DonationsService } from '@modules/donor-management/donations.service';

@Component({
  selector: 'app-gift-dashboard',
  templateUrl: './gift-dashboard.component.html',
  styleUrls: ['./gift-dashboard.component.scss']
})
export class GiftDashboardComponent implements OnInit {
  donorFilter$: BehaviorSubject<DonorFilter> = new BehaviorSubject<DonorFilter>({
    pageSize: 30,
    pageIndex: 0,
    orderBy: "SUM(amount_paid) DESC",
    start: moment().startOf('year').format('YYYY-MM-DD')
  });
  donors: any[] = [];
  totalRecords: number = 0;
  totalDonations: number;
  
  constructor(private donationSvc: DonationsService) {}
  
  ngOnInit() {
    this.donorFilter$.pipe(
      switchMap((donorFilter: DonorFilter) => this.donationSvc.getDonorsAndDonations(donorFilter))
    ).subscribe((response: any) => {
      console.log("RESPONSE: ", response);
      this.donors = response.donors;
      this.totalRecords = response.count;
      this.totalDonations = response.total;
    });
  }
  
  cellRemainder = (number: number) => {
    return Array(12 - number);
  }
  changePage = (filter: DonorFilter, increment: number, filter$: BehaviorSubject<DonorFilter>) => {
    filter.pageIndex += increment;
    filter$.next(filter);
  }
  changePageSize = (pageSize: number, filter: DonorFilter, filter$: BehaviorSubject<DonorFilter>) => {
    filter.pageSize = pageSize;
    filter$.next(filter);
  }
}