import { Component } from '@angular/core';

import { DonationsService } from '../donations.service';

@Component({
  selector: 'app-annual-planning',
  templateUrl: './annual-planning.component.html',
  styleUrls: ['./annual-planning.component.scss']
})
export class AnnualPlanningComponent {
  donors: any[];
  year: string = "2024";
  
  constructor(private donationSvc: DonationsService) {}
  
  fixRank = (year: string, quarter: string) => {
    this.donationSvc.fixAnnualPlanningRank(year, quarter).subscribe((response: any) => {
      this.donors = response;
    });
  }  
}