import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';

import { DialogService } from '@modules/dialogs/dialog.service';
import { PeopleService } from '../people.service';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss']
})
export class PersonFormComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Output('person-updated') personUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output('cancel') cancelled: EventEmitter<any> = new EventEmitter();
  @Output('person-created') personCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  disableSave: boolean = false;
  
  personForm: FormGroup;
  phoneForm: FormGroup;
  emailForm: FormGroup;
  addressForm: FormGroup;
  listForm: FormGroup;
  
  showNewAddress: boolean = false;
  showNewPhone: boolean = false;
  showNewEmail: boolean = false;
  
  prefixOptions: string[] = ["Mr.", "Mrs.", "Ms.", "Miss", "Dr."];
  suffixOptions: string[] = ["Jr.", "Sr.", "PhD"];
  phoneLabels: string[] = ["Home", "Mobile", "Work", "Fax"];
  emailLabels: string[] = ["Home", "Work", "Other"];
  addressLabels: string[] = ["Home", "Work", "Other"];
  staffLeads: string[] = ["Heather Cordasco"];
  
  constructor(private fb: FormBuilder,
              private router: Router,
              private dialogSvc: DialogService,
              private peopleSvc: PeopleService) {}
  
  get addresses(): FormGroup {
    return this.fb.group({
      label: [''],
      primary_address: [false],
      address_id: [],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip:[''],
      zip4: [''],
      county: [''],
      congressional_district: [''],
      contact_link: [],
      _slingupdate: [false]
    })
  }
  get phones(): FormGroup {
    return this.fb.group({
      phone_id: [],
      phone_number: [''],
      label: [''],
      primary_phone: [false],
      contact_link: [],
      _slingupdate: [false]
    });
  }
  get emailAddresses(): FormGroup {
    return this.fb.group({
      email_address_id: [],
      email: [''],
      label: [''],
      primary_email: [false],
      contact_link: [],
      _slingupdate: [false]
    });
  }
  
  ngOnInit() {
    this.personForm = this.fb.group({
      person_id: [],
      prefix: [''],
      first_name: [''],
      middle_name: [''],
      last_name: [''],
      suffix: [''],
      preferred_name: [''],
      spouse: [''],
      notes: [''],
      staff_lead: [''],
      addresses: this.fb.array([this.addresses]),
      phones: this.fb.array([this.phones]),
      email_addresses: this.fb.array([this.emailAddresses]),
    });
    this.listForm = this.fb.group({
      list: ['']
    });
    this.person$.pipe(
      filter((person: any) => !!person.person_id)
    ).subscribe((person: any) => {
      this.personForm.patchValue(person);
      this.personForm.get('email_addresses').reset();
      if (person.email_addresses.length > 0) person.email_addresses.forEach((email_address: any, index: number) => {
        (this.personForm.get('email_addresses') as FormArray).push(this.emailAddresses);
        (this.personForm.get('email_addresses') as FormArray).at(index).patchValue(email_address);
      });
      this.personForm.get('phones').reset();
      person.phones.forEach((phone: any, index: number) => {
        (this.personForm.get('phones') as FormArray).push(this.phones);
        (this.personForm.get('phones') as FormArray).at(index).patchValue(phone);
      });
      this.personForm.get('addresses').reset();
      person.addresses.forEach((address: any, index: number) => {
        (this.personForm.get('addresses') as FormArray).push(this.addresses);
        (this.personForm.get('addresses') as FormArray).at(index).patchValue(address);
      });
    });
  }
  
  // PERSON FORM FUNCTIONS **********************************************************************
  set_updateslingValues = () => {
    let phoneArr = this.personForm.get('phones') as FormArray;
    let emailAddressArr = this.personForm.get('email_addresses') as FormArray;
    let addressArr = this.personForm.get('addresses') as FormArray;
    
    phoneArr['controls'].forEach((el: any, idx: number) => {
      phoneArr.at(idx).get('_slingupdate').setValue(!el.pristine);
    });
    emailAddressArr['controls'].forEach((el: any, idx: number) => {
      emailAddressArr.at(idx).get('_slingupdate').setValue(!el.pristine);
    });
    addressArr['controls'].forEach((el: any, idx: number) => {
      addressArr.at(idx).get('_slingupdate').setValue(!el.pristine);
    });
  }
  savePerson = (person: any) => {
    this.disableSave = true;
    if (person.person_id) {
      this.peopleSvc.updatePerson(person).subscribe((response: any) => {
        this.person$.next(response);
        this.personUpdated.emit(response);
        this.disableSave = false;
      });
    } else {
      this.set_updateslingValues();
      this.peopleSvc.createPerson(person)
      .subscribe((response: any) => {
        this.router.navigateByUrl("/activists/" + response.person_id);
        this.personCreated.emit(true);
        this.disableSave = false;
      });
    }
  }
  cancel = (person: any) => {
    this.personForm.patchValue(person);
    this.cancelled.emit(true);
  }
  
  // ADDRESS FUNCTIONS **************************************************************************
  addAddress = (address: any, person: any) => {
    if (person.person_id > 0) {
      address.contact_link = person.person_id;
      this.peopleSvc.addAddress(address).subscribe((addresses: any) => {
        person.addresses = addresses;
        this.person$.next(person);
        this.addressForm.reset();
        this.showNewAddress = false;
      });
    }
  }
  deleteAddress = (addressIndex: number, person: any) => {
    let addressArr: FormArray = this.personForm.get('addresses') as FormArray,
        currAddress: any = this.personForm.get('addresses')['controls'][addressIndex].value,
        personId = person.person_id;
    
    if (addressArr.at(addressIndex).dirty || currAddress.address_id) {
      this.dialogSvc.confirmDialog("Confirm Delete Address", "Are you sure you want to delete this address?")
      .pipe(
        filter((confirm: boolean) => confirm),
        map(() => {if (addressArr.length > 1) {addressArr.removeAt(addressIndex)} else addressArr.at(addressIndex).reset()}),
        filter(() => !!currAddress.address_id),
        switchMap(() => this.peopleSvc.deleteAddress(currAddress.address_id, personId)))
      .subscribe((addresses: any) => {
        person.addresses = addresses;
        this.person$.next(person);
        this.personForm.patchValue(person);
       });
    } else {
      if (addressArr.length > 1) addressArr.removeAt(addressIndex);
    }
  }
  addAddressForm = () => {
    (this.personForm.get('addresses') as FormArray).push(this.addresses);
  }
  setPrimaryAddress = (addressIndex) => {
    (this.personForm.get('addresses') as FormArray).controls.forEach((address: any, index: number) => {
      if (addressIndex !== index && address.value.primary_address) {
        address.patchValue({primary_address: false});
        address.markAsDirty();
      }
    });
  }
  
  // PHONE NUMBER FUNCTIONS *********************************************************************
  addPhoneNumber = (phone: any, person: any) => {
    if (person.person_id > 0) {
      phone.contact_link = person.person_id;
      this.peopleSvc.addPhoneNumber(phone).subscribe((response: any[]) => {
        person.phones = response;
        this.person$.next(person);
        this.phoneForm.reset();
        this.showNewPhone = false;
      });
    }
  }
  deletePhone = (phoneIndex: number, person: any) => {
    let phoneArr = this.personForm.get('phones') as FormArray,
        currPhone: any = phoneArr['controls'][phoneIndex].value,
        title: string = "Confirm Delete Phone Number",
        msg: string = "Are you sure you want to delete this phone number?";
    
    if (phoneArr.at(phoneIndex).dirty || currPhone.phone_id) {
      this.dialogSvc.confirmDialog(title, msg).pipe(
        filter((confirm: boolean) => confirm),
        map(() => {if (phoneArr.length > 1) {phoneArr.removeAt(phoneIndex)} else phoneArr.at(phoneIndex).reset()}),
        filter(() => currPhone.phone_id),
        switchMap(() => this.peopleSvc.deletePhoneNumber(currPhone.phone_id, person.person_id))
      ).subscribe((response: any[]) => {
        person.phones = response;
        this.person$.next(person);
      });
    } else {
      if (phoneArr.length > 1) phoneArr.removeAt(phoneIndex);
    }
  }
  addPhoneForm = () => {
    (this.personForm.get('phones') as FormArray).push(this.phones);
  }
  setPrimaryPhone = (phoneIndex: number) => {
    (this.personForm.get('phones') as FormArray).controls.forEach((phone: any, index: number) => {
      if (phoneIndex !== index && phone.value.primary_phone) {
        phone.patchValue({primary_phone: false});
        phone.markAsDirty();
      }
    });
  }
  
  // EMAIL ADDRESS FUNCTIONS ********************************************************************
  addEmailAddress = (email: any, person: any) => {    
    if (person.person_id > 0) {
      email.contact_link = person.person_id;
      this.peopleSvc.addEmailAddress(email).subscribe((emails: any[]) => {
        person.email_addresses = emails;
        this.person$.next(person);
        this.emailForm.reset();
        this.showNewEmail = false;
      });
    }
  }
  deleteEmailAddress = (emailAddressIndex: number, person: any) => {
    let emailAddressArr: FormArray = this.personForm.get('email_addresses') as FormArray,
        currEmailAddress = this.personForm.get('email_addresses')['controls'][emailAddressIndex].value,
        title: string = "Confirm Delete Email Address",
        msg: string = "Are you sure you want to delete this email address?";
    
    if (emailAddressArr.at(emailAddressIndex).dirty || currEmailAddress.email_address_id) {
      this.dialogSvc.confirmDialog(title, msg).pipe(
        filter((confirm: boolean) => confirm),
        map(() => {if (emailAddressArr.length > 1) {emailAddressArr.removeAt(emailAddressIndex)} else emailAddressArr.at(emailAddressIndex).reset()}),
        filter(() => currEmailAddress.email_address_id),
        switchMap(() => this.peopleSvc.deleteEmailAddress(currEmailAddress.email_address_id, person.person_id)))
      .subscribe((emailAddresses: any[]) => {
        person.email_addresses = emailAddresses;
        this.personUpdated.emit(person);
       });
    } else {
      if (emailAddressArr.length > 1) emailAddressArr.removeAt(emailAddressIndex);
    }
  }
  addEmailAddressForm = () => {
    (this.personForm.get('email_addresses') as FormArray).push(this.emailAddresses);
  }
  setPrimaryEmailAddress = (emailAddressIndex) => {
    (this.personForm.get('email_addresses') as FormArray).controls.forEach((email: any, index: number) => {
      if (emailAddressIndex !== index && email.value.primary_email) {
        email.patchValue({primary_email: false});
        email.markAsDirty();
      }
    });
  }
  toggleEmailMute = (person: any) => {
    let updatedEmailMuteStatus: number = person.mute_email === 0?1:0,
        updateObj: any = {
          person_id: person.person_id,
          mute_email: updatedEmailMuteStatus
        };
    
    this.peopleSvc.updatePerson(updateObj).subscribe((response: any) => {
      this.person$.next(response);
    });
  }
}