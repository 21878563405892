import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { startWith, debounceTime, switchMap, mergeMap, tap, map } from 'rxjs/operators';
import { AngularEditorConfig, AngularEditorComponent } from '@kolkov/angular-editor';
import * as moment from 'moment';

import { EmailService } from '../email.service';
import { EmailPreviewDialogComponent } from '../email-preview-dialog/email-preview-dialog.component';
import { AuthService } from '@modules/auth/auth.service';

export interface UploadResponse {
    imageUrl: string;
}

@Component({
  selector: 'app-email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.scss']
})
export class EmailEditorComponent implements OnInit {
  @ViewChild("recipientInput") recInput: ElementRef;
  @ViewChild("editor") editor: AngularEditorComponent;
  @Input('email-update') emailUpdate: Subject<any> = new Subject<any>();
  assetUrl: string = "https://slingofdavid.com/";
  emailTemplates: any[] = [
    {
      name: "Center for Renewing America",
      format: {
        header: {
          backgroundColor: "#ffffff",
          imageUrl: this.assetUrl + "assets/email/cra_logo.png",
          imageWidth: 208.68
        },
        footer: {
          backgroundColor: "#ffffff",
          color: "black",
          organizationName: "Center for Renewing America",
          facebookUrl: "https://www.facebook.com/amrenewcenter",
          facebookImageUrl: this.assetUrl + "assets/email/facebook.png",
          twitterUrl: "http://www.twitter.com/",
          twitterImageUrl: this.assetUrl + "assets/email/twitter.png",
          websiteUrl: "https://americarenewing.com/",
          websiteImageUrl: this.assetUrl + "assets/email/link.png",
          unsubscribeUrl: "https://americanrestorationcenter.us1.list-manage.com/unsubscribe?u=10a708d6740341bfdc2a52a46&amp;id=340a9f3c7d&amp;e=__test_email__&amp;c=f66e7b1901",
          safeUnsubscribeUrl: "https://www.google.com/url?q=https://americanrestorationcenter.us1.list-manage.com/unsubscribe?u%3D10a708d6740341bfdc2a52a46%26id%3D340a9f3c7d%26e%3D__test_email__%26c%3Df66e7b1901&amp;source=gmail&amp;ust=1627074728352000&amp;usg=AFQjCNE1U87u_Vlvqs1bpHHD5sI2PlrgNA",
          updatePreferenceUrl: "https://americanrestorationcenter.us1.list-manage.com/profile?u=10a708d6740341bfdc2a52a46&amp;id=340a9f3c7d&amp;e=__test_email__&amp;c=f66e7b1901",
          safeUpdatePreferenceUrl: "https://www.google.com/url?q=https://americanrestorationcenter.us1.list-manage.com/profile?u%3D10a708d6740341bfdc2a52a46%26id%3D340a9f3c7d%26e%3D__test_email__%26c%3Df66e7b1901&amp;source=gmail&amp;ust=1627074728352000&amp;usg=AFQjCNFUe-RbEZi9Dm1aRvt8S2bgQqoKrQ"
        }
      }
    },
    {
      name: "Citizens for Renewing America",
      format: {
        header: {
          backgroundColor: "#003764",
          imageUrl: this.assetUrl + "assets/email/citizens.png",
          imageWidth: 180.48
        },
        footer: {
          backgroundColor: "#003764",
          color: "#ffffff",
          organizationName: "Citizens for Renewing America",
          facebookUrl: "https://www.facebook.com/amrenewcenter",
          facebookImageUrl: this.assetUrl + "assets/email/facebook.png",
          twitterUrl: "http://www.twitter.com/",
          twitterImageUrl: this.assetUrl + "assets/email/twitter.png",
          websiteUrl: "https://americarenewing.com/",
          websiteImageUrl: this.assetUrl + "assets/email/link.png",
          unsubscribeUrl: "https://americanrestorationcenter.us1.list-manage.com/unsubscribe?u=10a708d6740341bfdc2a52a46&amp;id=340a9f3c7d&amp;e=__test_email__&amp;c=f66e7b1901",
          safeUnsubscribeUrl: "https://www.google.com/url?q=https://americanrestorationcenter.us1.list-manage.com/unsubscribe?u%3D10a708d6740341bfdc2a52a46%26id%3D340a9f3c7d%26e%3D__test_email__%26c%3Df66e7b1901&amp;source=gmail&amp;ust=1627074728352000&amp;usg=AFQjCNE1U87u_Vlvqs1bpHHD5sI2PlrgNA",
          updatePreferenceUrl: "https://americanrestorationcenter.us1.list-manage.com/profile?u=10a708d6740341bfdc2a52a46&amp;id=340a9f3c7d&amp;e=__test_email__&amp;c=f66e7b1901",
          safeUpdatePreferenceUrl: "https://www.google.com/url?q=https://americanrestorationcenter.us1.list-manage.com/profile?u%3D10a708d6740341bfdc2a52a46%26id%3D340a9f3c7d%26e%3D__test_email__%26c%3Df66e7b1901&amp;source=gmail&amp;ust=1627074728352000&amp;usg=AFQjCNFUe-RbEZi9Dm1aRvt8S2bgQqoKrQ"
        }
      }
    }
  ];
  email: any = {
    topSet: "",
    header: "",
    bodyTop: "",
    bodyBottom: "",
    footer: "",
    bodySet: ""
  };
  activeTemplate$: BehaviorSubject<any> = new BehaviorSubject<any>(this.emailTemplates[0]);
  contentTemplates: any = {
    notes: `<div style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small; text-align: center;
                        background-color: rgb(255, 255, 255);">
              <font face="Arial" color="#e60f0f" size="6">
                <b>Citizens for Renewing America</b>
              </font>
            </div>
            <div style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small; text-align: center;
                        background-color: rgb(255, 255, 255);">
              <font size="4" style="">The America First Wing of the America First Movement</font>
              <br><br>
            </div>
            <div style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small; text-align: center;
                        background-color: rgb(255, 255, 255);">
              <font size="5">Call Summary Update</font>
            </div>
            <div style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small; text-align: center;
                        background-color: rgb(255, 255, 255);">
              <span>${moment().format('LL')}</span>
            </div>
            <div style="background-color:rgb(220,220,220); padding:10px 20px 10px 20px; border-radius: 8px; margin-top: 20px;">
              <ul style="color: rgb(34,34,34);font-family: Arial, Helvetica, sans-serif;font-size: small;">
                <li>
                  <span>If you are receiving these emails but are not yet on the Thursday call, please follow this link to sign up for the call, or respond to this email with your first and last name, phone number, and area code. For Example: (John Doe, 999-999-9999, 11111)</span>
                </li>
                <li><span>If you were forwarded this email and want to be added to our email and call list, please sign up here</span></li>
                <li><span>Please share that link with your friends who support the America First agenda, and invite them to our calls</span></li>
                <li><span>Get more info at Center for Renewing America and Citizens for Renewing America</span></li>
                <li>Follow us on social at @amrenewing and @amrenewcitizen</li>
              </ul>
              <b>Contact our State Directors in Florida, Tennessee, and Arizona</b><br>
              <ul style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small;">
                <li><span style="letter-spacing: 0.0178571em;">Michelle Martin - Florida</span><br></li>
                <li><span style="letter-spacing: 0.0178571em;">Leslie White - Arizona</span><br></li>
              </ul>
            </div>
            <div style="background-color: rgb(250,0,0); color: rgb(255,255,255); text-align: center; padding: 20px; margin-top: 40px;
                        border-radius: 8px; width: 100%;">
              <div style="font-size: 28px;"><span>CRA LATEST NEWS</span></div>
            </div>
            <br><br><br>
            <div style="background-color: rgb(220,220,220); border-radius: 8px; padding: 20px; margin-top: 25px;">
            <div style="color: rgb(255,0,0); font-size: 18px; with: 100%; border-bottom: solid 2px red; padding-bottom: 5px;">
              <span>CRA PAST NEWS</span>
            </div>
            <br><br>`
  }  
  
  emailForm: FormGroup;
  toSearch$: BehaviorSubject<string> = new BehaviorSubject("");
  fromOptions: any[] = [
    {name: '"Russ Vought, President"', email: "info@americarenewing.com"},
    {name: 'Wade Miller', email: "Joseph@citizensrenewingamerica.com"},
    {name: 'Ashlea Frazier', email: "info@americarenewing.com"},
    {name: 'Rachel Semmel', email: "info@americarenewing.com"},
  ];
  replyToOptions: any[] = [
    {name: "Wade Miller", email: "Joseph@citizensrenewingamerica.com"},
    {name: "Ashlea Frazier", email: "ashlea@renewingamerica.com"},
    {name: "Rachel Semmel", email: "rachel@renewingamerica.com"},
    {name: "Michelle Martin", email: "michelle@citizensrenewingamerica.com"}
  ]
  searchResults$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
  recipientInput$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  recentEmailTemplates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  organization: string = "Citizens";

  // HTML VARIABLES ******************************************************************************************
  recipients: any[] = [];

  constructor(private fb: FormBuilder,
              private emailSvc: EmailService,
              private authSvc: AuthService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private http: HttpClient) { }

  ngOnInit(): void {
    this.emailUpdate.subscribe((email: any) => {
      this.emailForm.patchValue(email);
      this.emailForm.markAsDirty();
    });
    this.activeTemplate$.subscribe((activeTemplate: any) => {
      this.email = {
        topSet: `<div style="width:100%;" align="center" width="100%">
                   <table style="max-width:800px;border-collapse:collapse;height:100%;margin:0;padding:0;width:100%;"
                        width="100%" height="100%" cellspacing="0" cellpadding="0" border="0" 
                        align="center">
                   <tbody>`,
        header:     `<tr>
                       <td style="background:${activeTemplate.format.header.backgroundColor} none no-repeat 50% 50% cover;background-color:${activeTemplate.format.header.backgroundColor};border-top:0;border-bottom:0;padding-top:3px;padding-bottom:3px"
                           valign="top" align="center">
                         <img alt="" style="max-width:869px;padding-bottom:0;display:inline!important;vertical-align:bottom;border:0;height:auto;outline:none;text-decoration:none"
                              src="${activeTemplate.format.header.imageUrl}" width="${activeTemplate.format.header.imageWidth}" align="middle" tabindex="0">
                         <br>
                       </td>
                     </tr>`,
        bodyTop:    `<tr>
                        <td style="background:transparent none no-repeat center/cover;border-top:0;border-bottom:0;padding-top:0px;padding-bottom:0px" valign="top" align="center">
                          <table style="border-collapse:collapse;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                            <tbody>
                              <tr>
                               <td style="padding:9px 18px 9px;" valign="top">`,
        bodyBottom:           `</td>
                             </tr>
                           </tbody>
                         </table>
                       </td>
                     </tr>`,
        footer:     `<tr>
                       <td style="background:#ffffff none no-repeat center/cover;background-color:#ffffff;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:0px;padding-bottom:0px"
                           valign="top" align="center">
                       <table style="border-collapse:collapse;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                         <tbody>
                           <tr width="450px" style="width:450px;max-width:450px">
                             <td width="24" valign="middle" align="center" style="padding-top: 20px;">
                               <a rel="noreferrer nofollow noopener" href="${activeTemplate.format.footer.facebookUrl}" target="_blank">
                                 <img alt="Facebook" style="display:block;border:0;height:auto;outline:none;text-decoration:none"
                                      src="${activeTemplate.format.footer.facebookImageUrl}" width="24" height="24">
                               </a>
                               <br>
                             </td>
                             <td width="24" valign="middle" align="center" style="padding-top: 20px;">
                               <a rel="noreferrer nofollow noopener" href="${activeTemplate.format.footer.twitterUrl}"
                                  target="_blank">
                                 <img alt="Twitter" style="display:block;border:0;height:auto;outline:none;text-decoration:none"
                                      src="${activeTemplate.format.footer.twitterImageUrl}" width="24" height="24">
                               </a>
                               <br>
                             </td>
                             <td width="24" valign="middle" align="center" style="padding-top: 20px;">
                               <a rel="noreferrer nofollow noopener" href="${activeTemplate.format.footer.websiteUrl}"
                                  target="_blank" >
                                 <img alt="Website" style="display:block;border:0;height:auto;outline:none;text-decoration:none"
                                      src="${activeTemplate.format.footer.websiteImageUrl}" width="24" height="24">
                               </a>
                               <br>
                             </td>
                          </tr>
                           <tr>
                             <td style="padding-top:9px" valign="top" colspan="4">
                               <table style="max-width:100%;min-width:100%;border-collapse:collapse;background:${activeTemplate.format.footer.backgroundColor} none no-repeat center/cover;background-color:${activeTemplate.format.footer.backgroundColor};background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;padding-top:20px;"
                                      width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                                 <tbody>
                                   <tr>
                                     <td style="padding-top:20px;padding-right:18px;padding-bottom:9px;padding-left:18px;word-break:break-word;color:${activeTemplate.format.footer.color};font-family:Helvetica;font-size:12px;line-height:150%;text-align:center" valign="top"><div><i>Copyright © 2023 ${activeTemplate.format.footer.organizationName}, All rights reserved.</i>
                                       <br></div>
                                       <div><br></div>
                                       <div><span style="color:${activeTemplate.format.footer.color};"><b>Our mailing address is:</b></span><br></div>
                                       <div>300 Independence Ave. SE<br></div>
                                       <div>Washington,&nbsp;DC&nbsp;20003<br></div>
                                       <div><br></div>
                                       <div>If you no longer want to receive these emails,<br></div>
                                       <div>you can <a rel="noreferrer nofollow noopener"
                                                       href="${activeTemplate.format.footer.unsubscribeUrl}"
                                                       style="color:${activeTemplate.format.footer.color};font-weight:normal;text-decoration:underline" target="_blank"
                                                       data-saferedirecturl="${activeTemplate.format.footer.safeUnsubscribeUrl}">
                                                      <span>click here to unsubscribe from this list.</span>
                                                    </a>
                                       <br></div><div> &nbsp;<br></div>
                                     </td>
                                   </tr>
                                 </tbody>
                               </table>
                             </td>
                           </tr>
                         </tbody>
                       </table>
                     </td>
                   </tr>`,
        bottomSet: `</tbody></table></div>`
      }
    });

    this.emailForm = this.fb.group({
      sender: [''],
      replyTo: [''],
      subject: [''],
      html_header: [],
      html_content: [],
      html_footer: []
    });
    this.recipientInput$.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value && typeof value === "string") {
          return this.emailSvc.searchEmailRecipients(value.trim());
        } else {
          return of([]);
        }
      }))
    .subscribe((results: any[]) => this.searchResults$.next(results));
    this.emailForm.get('sender').valueChanges.subscribe((sender: string) => {
      let citizensDomain = "citizensrenewingamerica.com",
          centerDomain = "americarenewing.com";
      if (sender) {
        if (sender.toLowerCase().includes(centerDomain)) this.activeTemplate$.next(this.emailTemplates[0]);
        if (sender.toLowerCase().includes(citizensDomain)) this.activeTemplate$.next(this.emailTemplates[1]);
      }
    });
  }
  
  fileSelected = (event) => {
    let file = event.target.files[0],
        fileName = file.name;

    const formData = new FormData();
    formData.append("file", file, file.name);
    this.http.post<UploadResponse>("http://slingofdavid.com:3000/upload-email-image", formData, {observe: "events", reportProgress: true, withCredentials: false}).subscribe(response => {
      console.log(response);
   //   if (response.type === 4) this.editor.executeCommand('insertImage', response.body.imageUrl);
    })
  }
  consoleLog = (obj) => {
    console.log(obj);
  }
  
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '100%',
    minHeight: '500px',
    maxHeight: '75vh',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    sanitize: false,
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [],
    uploadUrl: 'https://slingofdavid.com:3000/upload-email-image',
    // upload: this.onFileSelected,
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      ["imageUpload"]
    ]
  };
  
  // RECIPIENT FUNCTIONS **************************************************************************************
  validateEmailAddress = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  recipientKeyup = (ev) => {
    if (this.validateEmailAddress(ev.target.value)) {
      console.log("VALID EMAIL: ", ev.target.value);
    } else {
      console.log("INVALID EMAIL: ", ev.target.value);
    }
  }
  recipientOptionSelected = (ev) => {
    this.emailForm.controls['to'].value.push(ev.option.value);
    this.recInput.nativeElement.value = "";
  }
  displayResult = (result: any) => {
    if (result.type === "person") return result.first_name + " " + result.last_name + " <" + result.email + ">";
    if (result.type === "list") return result.first_name;
  }
  addRecipientFromInput = (event: any) => {
    let person = event.option.value;
    
    console.log(event.option.value);
    console.log(this.recInput.nativeElement.value);
    if (event.option.value) {
      this.recipients.push(person);
      this.recInput.nativeElement.value = "";
    }
  }
  removeRecipient = (index: number) => {
    this.recipients.splice(index, 1);
  }
  
  // EMAIL FUNCTIONS ******************************************************************************************
  getRecentEmailTemplates = () => {
    
  }
  
  // PREVIEW FUNCTIONS ****************************************************************************************
  openPreviewDialog = () => {
    this.dialog.open(EmailPreviewDialogComponent, {data: this.emailForm.value, height: "90%"});
  }
  
   // SEND EMAIL FUNCTIONS *************************************************************************************
  checkPermission = (permission: string) => {
    return this.authSvc.checkPermission(permission);
  }
  sendSESEmail = (emailObj: any) => {
    emailObj.recipients = this.recipients;
    emailObj.html_header = this.email.topSet + this.email.header;
    emailObj.html_footer = this.email.footer + this.email.bottomSet;
    emailObj.html = this.email.topSet + this.email.header + this.email.bodyTop + emailObj.html_content + this.email.bodyBottom + this.email.footer + this.email.bottomSet;
    
    this.emailSvc.sendSESEmail(emailObj).subscribe((response: any) => {
      let snackBarRef = this._snackBar.open(`Email sent to ${emailObj.recipients.length} recipient${emailObj.recipients.length > 1?'s':''}!`, "OK", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 3000
      });
      this.emailForm.reset();
      this.recipients = [];
    });
  }
  
  // OTHER
  fixAWSResponses = () => {
    this.emailSvc.fixAWSResponses().subscribe((response: any) => {
      console.log(response);
    });
  }
}