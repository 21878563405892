import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { FilesService } from '../files.service';

@Component({
  selector: 'app-valid-table-cell',
  templateUrl: './valid-table-cell.component.html',
  styleUrls: ['./valid-table-cell.component.scss']
})
export class ValidTableCellComponent implements OnInit {
  @Input("cell-data") cellData: any;
  @Input("validation-object") valObject: any;
  @Input("key") field: any;
  @Output() updateRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() invalidCell: EventEmitter<any> = new EventEmitter<any>();
  cellForm: FormGroup;
  row: any = {};
  viewMode: string = "view";
  destinationField: any;
  invalid: boolean;
  
  constructor(private fb: FormBuilder,
              private fileSvc: FilesService) { }
  
  // VALIDATION CHECK FUNCTIONS ********************************************
  /* applyValidation = (value) => {
    this.fieldMap.forEach(seq => {
      let fldMap = seq.find(mp => mp.from.orgTable === this.field.orgTable && mp.from.name === this.field.name);
    });
    let valObj = this.fileSvc.fieldValidations.find(vl => {return vl.columns.find(col => {return (col.table === fldMap.to.TABLE_NAME && col.fields.indexOf(fldMap.to.COLUMN_NAME) > -1)})});
    return valObj.validation(value);
  } */
  
  // VALIDATION ACTION FUNCTIONS *******************************************
  splitName = (value) => {
    this.row.first_name = value.split(" ")[0].trim();
    this.row.last_name = value.split(" ")[1].trim();
    this.updateRow.emit(this.row);
  }
  splitFMLName = (value) => {
    this.row.first_name = value.split(" ")[0].trim();
    this.row.middle_name = value.split(" ")[1].trim();
    this.row.last_name = value.split(" ")[2].trim();
    this.updateRow.emit(this.row);
  }
  moveToNotes = (value) => {
    this.row.notes = value;
    this.row[this.field.name] = "";
    this.updateRow.emit(this.row);
  }
  confirmDestinationField = () => {
    console.log(this.destinationField);
  }
  editCell = () => {
    this.cellForm = this.fb.group({
      cellValue: [this.cellData]
    });
    this.viewMode = "edit";
  }
  
  // UTILITY FUNCTIONS *****************************************************
  countChars = (text, char) => {
    return typeof text === "string"?(text.length - text.replaceAll(text, char).length):0;
  }

  ngOnInit(): void {
    // this.destinationField = this.fieldMap.find(mp => mp.from.name === this.field.name && mp.from.orgTable === this.field.orgTable).to.COLUMN_NAME;
    // this.invalid = this.applyValidation(this.cellData);
  }

}