import { Component, OnInit, signal, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { filter, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { DonationsService } from '../donations.service';

@Component({
  selector: 'app-donation-dashboard',
  templateUrl: './donation-dashboard.component.html',
  styleUrls: ['./donation-dashboard.component.scss']
})
export class DonationDashboardComponent implements OnInit {
  private donationSvc: DonationsService = inject(DonationsService);
  results = signal<any[]>([]);
  selIndex = signal<number|null>(null);
  selIndex$ = toObservable(this.selIndex);
  donations = signal<any[]>([]);
  
  ngOnInit() {
    this.donationSvc.monthlySummary().subscribe((resp: any[]) => this.results.set(resp));
    this.selIndex$.pipe(
      filter((index: number) => this.results()[index]),
      filter((index: number) => !!this.results()[index].year),
      switchMap((index: number) => {
        let date: string = this.results()[index].month + '/' + 1 + '/' + this.results()[index].year;
        return this.donationSvc.monthlyDonations(moment(date));
    })).subscribe((resp: any[]) => this.donations.set(resp));
  }
}