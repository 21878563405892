import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-correspondence-master',
  templateUrl: './correspondence-master.component.html',
  styleUrls: ['./correspondence-master.component.scss']
})
export class CorrespondenceMasterComponent implements OnInit {
  correspondence: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor() { }

  ngOnInit(): void {
  }

}
