<div fxFlexFill fxLayout="column" fxLayoutGap="10px"
     *ngIf="filter$ | async as filter" class="overflow-auto">
  <app-pagination item-name="Actions" [total-records]="totalRecords$ | async"
                  [page-index]="filter.pageIndex" [page-size]="filter.pageSize"
                  (change-page-size)="updatePageSize($event, filter)"
                  (page-increment)="pageIncrement(filter, $event)"></app-pagination>
  <table *ngIf="person$ | async as person">
    <thead>
      <tr>
        <th style="align-text: left;">
          <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="5px">
            <span>Action Queue</span>
            <button mat-icon-button class="sm-icon-btn" color="primary"
                    [disabled]="!person.person_id"
                    (click)="openDialog({}, person, filter, 'edit')">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
        </th>
        <th><span>Action Lead</span></th>
        <th><span>Status</span></th>
        <th><span>Date Completed</span></th>
        <th>
          <div class="sm-icon-btn" style="vertical-align: bottom;">
            <mat-icon>assignment</mat-icon>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let action of actions$ | async">
        <td scope="row" class="pointer" (click)="openDialog(action, person, filter, 'view')">
          <span class="ellipsis">{{action.name}}</span>
        </td>
        <td class="text-center"><span>{{action.action_lead}}</span></td>
        <td class="pointer text-center" mat-button [matMenuTriggerFor]="statusMenu">
          <span>{{action.status}}</span>
        </td>
        <mat-menu class="compact-menu" #statusMenu="matMenu">
          <button mat-menu-item
                  *ngFor="let option of peopleSvc.actionStatusOptions"
                  (click)="updateActionStatus(action.action_id, {status: option})">
            <span>{{option}}</span>
          </button>
        </mat-menu>
        <td class="text-center"><span>{{action.date_completed | date:'shortDate'}}</span></td>
        <td class="text-center">
          <button mat-icon-button class="sm-icon-btn" [matMenuTriggerFor]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu class="compact-menu" #actionMenu="matMenu">
            <button mat-menu-item (click)="openDialog(action, person, filter, 'edit')">
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="delete(action, filter)">
              <span>Delete Action</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>