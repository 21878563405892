<div *ngIf="passwordSet || !singleUseIdActive">
  <span *ngIf="passwordSet">You have already set the password for this account. </span>
  <span *ngIf="!singleUseIdActive">The temporary code to set the password for this account has expired. </span>
  <span>Please go to the </span><a href='/login'><span>login page</span></a>
  <span> to login to your account.</span>
</div>
<div *ngIf="!passwordSet && singleUseIdActive">
  <span>Please set your new password for the Sling of David system below. Your username for login will be the email address where you received this link.</span>
</div>
<div *ngIf="singleUseIdActive">
  <div>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput type="password"
             [formControl]="password">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Confirm Password</mat-label>
      <input matInput type="password"
             [formControl]="confirmPassword">
    </mat-form-field>
  </div>
  <button mat-flat-button color="primary" (click)="setNewUserPassword(userId, password.value)"
          [disabled]="!password.value || password.value !== confirmPassword.value || !singleUseIdActive || passwordSet">
    <span>Set Password</span>
  </button>
</div>
<div *ngIf="!!username" class="success-message">
  <span>Congratulations! You have set the password for your account with the username {{username}}. </span>
  <span>Please go to the </span><a href='/login'><span>login page</span></a>
  <span> to login to your account.</span>
</div>