import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { FilesService } from '../files.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() requiredFileType: string;
  @Output('file-upload-complete') fileUploadComplete: EventEmitter<any> = new EventEmitter<any>();
  fileName = '';
  uploadProgress: number;
  uploadSub: Subscription;

  constructor(public fileSvc: FilesService
              ) {}

  onFileSelected = (event) => {
    this.uploadSub = this.fileSvc.fileUpload(event).pipe(finalize(() => this.reset())).subscribe((result: any) => {
      if (result.type === HttpEventType.UploadProgress) this.uploadProgress = Math.round(100 * (result.loaded / result.total));
      if (result.type === HttpEventType.Response) {
        this.fileSvc.uploadedFiles$.next(result.body.uploadedFileList[0]);
        this.fileUploadComplete.emit(result.body.newFile[0][0]);
      }
    });
  }
  
  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }

  ngOnInit(): void {
    this.fileSvc.getFileUploadList().subscribe(x => console.log(x));
  }

}