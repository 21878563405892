import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';

import { ListService } from '@modules/list-management/list.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { EmailAddressViewComponent } from '../email-address-view/email-address-view.component';

@Component({
  selector: 'app-person-view',
  templateUrl: './person-view.component.html',
  styleUrls: ['./person-view.component.scss']
})
export class PersonViewComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Output('person-updated') personUpdated: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('newEmailForm') newEmailForm: EmailAddressViewComponent;

  listControl: FormControl = new FormControl();
  
  showNewEmail: boolean = false;
  showNewPhone: boolean = false;
  showNewAddress: boolean = false;
  
  staffLeadControl: FormControl = new FormControl();
  editStaffLead: boolean = false;
  
  // LIST VARIABLES ***************************************************************
  selectedListMaster: any = {};
  listSearchText: string = "";
  listDropdown$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  
  constructor(private listSvc: ListService,
              private dialogSvc: DialogService,
              protected peopleSvc: PeopleService,
              private fb: FormBuilder) {}
  
  ngOnInit() {
    this.listControl.valueChanges.pipe(
      withLatestFrom(this.listSvc.lists)
    ).subscribe(([text, lists]: [string, any[]]) => {
      this.listDropdown$.next(lists.filter((lst: any) => lst.list.includes(text)));
    });
    this.listSvc.lists.subscribe(lsts => this.listDropdown$.next(lsts));
    this.person$.subscribe((person: any) => {
      this.staffLeadControl.setValue(person.staff_lead);
      if (typeof this.newEmailForm !== "undefined") this.newEmailForm.reset(this.newEmailForm.emailAddress, person);
    });
  }
  
  getPersonName = (person: any) => {
    let result = "";
    
    if (!!person.first_name || !!person.last_name) {
      if (person.first_name) result = person.first_name;
      if (person.last_name) result += " " + person.last_name;
    }
    return result.trim();
  }
  
  // LIST FUNCTIONS *****************************************************************************
  createList = (list: any) => {
    this.listSvc.createList(list).subscribe((lists: any[]) => {
      this.listSvc.lists.next(lists);
      this.listControl.reset('');
    })
  }
  deleteList = (list: any, person: any) => {
    let title: string = "Confirm List Delete Action",
        msg: string = `Are you sure you want to permanently delete the list titled '${list.list}' and all the list connections to people?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.listSvc.deleteList(list.list_id))
    ).subscribe((lists: any[]) => {
      this.listSvc.lists.next(lists);
      person.lists = person.lists.filter((lst: any) => lst.list_id ! === list.list_id);
      this.person$.next(person);
    });
  }
  
  addPersonToSelectedList = (ev: any, person: any) => {
    this.listSearchText = "";
    if (person.person_id) {
      if (ev.option.value.list_id) {
        this.listSvc.addPersonToList(person.person_id, ev.option.value.list_id).subscribe((response: any) => {
          person.lists.push(response);
          this.peopleSvc.personListUpdate$.next({
            action: "add",
            person: person,
            list: ev.option.value
          });
        });
      } else {
        let newList = ev.option.value;
        this.listSvc.createListAndAddPerson(ev.option.value, person.person_id).subscribe((response: any) => {
          person.lists.push(response.newList);
          this.listSvc.lists.next(response.lists);
          this.person$.next(person);
        });
      }
    }
  }
  removePersonFromList = (personListJoinId: number, list: any, person: any) => {
    let title: string = "Confirm List Removal",
        msg: string = `Are you sure you want to remove ${this.getPersonName(person)} from the ${list.list} list?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.listSvc.removePersonFromList(personListJoinId))
    ).subscribe((x: any) => {
      person.lists = person.lists.filter((listLink: any) => listLink.person_list_join_id !== list.person_list_join_id);
      this.peopleSvc.personListUpdate$.next({
        action: "remove",
        person: person,
        list: list
      });
      this.person$.next(person);
    });
  }
  addPersonToList = (list: any, person: any) => {
    if (person.person_id && list.list_id) {
      this.listSvc.addPersonToList(person.person_id, list.list_id).subscribe((newList: any) => {
        person.lists.push(newList);
        this.person$.next(person);
      });
    }
  }
  displayNull = () => {
    return '';
  }
  
  updateStaffLead = (updateValue: any, person: any) => {
    let update: any = {
      person_id: person.person_id,
      staff_lead: updateValue
    }
    
    this.peopleSvc.updatePerson(update).subscribe((response: any) => {
      this.personUpdated.emit(response);
      this.staffLeadControl.reset('');
      this.editStaffLead = false;
    });
  }
  closeStaffLeadForm = (control: FormControl) => {
    let title: string = "Confirm Clear Unsaved Changes",
        msg: string = "Are you sure you want to clear unsaved changes to the Staff Lead?";
    
    if (control.value && !control.pristine) {
      this.dialogSvc.confirmDialog(title, msg).pipe(
        withLatestFrom(this.person$)
      ).subscribe(([dialogResponse, person]: [any, any]) => {
        control.reset(person.staff_lead);
        this.editStaffLead = false;
      });
    } else {
      this.editStaffLead = false;
    }
  }
}