import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';

import { Subscription, BehaviorSubject } from 'rxjs';
import { switchMap, mergeMap, finalize, filter } from 'rxjs/operators';

import { FilesService } from '../files.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { PeopleService } from '@modules/person-profiles/people.service';

@Component({
  selector: 'app-import-files',
  templateUrl: './import-files.component.html',
  styleUrls: ['./import-files.component.scss']
})
export class ImportFilesComponent implements OnInit {
  /*  ACTIONS
      Remove File Table Page Change Load Function
      Recollect metadata on update
      Fic temp table update when adding column to temp table
  */
  
  // VIEW PROPERTIES **********************************************************************
  showSideBar: boolean = true;
  tableViewOptions: any[] = [
    {value: "file", label: "File Table"},
    {value: "temporary", label: "Temporary Table"},
    {value: "duplicate", label: "Duplicates"},
    {value: "import", label: "Records for Import"},
    {value: "destination", label: "Destination Table"}
  ];
  tableView: string = "file";
  loadingStatus: any = {
    fileTable: false,
    scanTable: false,
    tempTable: false,
    validTable: false
  };
  showNewColumnForm: boolean = false;
  tempTableSingleLineCell: boolean = true;
  
  // TABLE PAGINATION PROPERTIES **********************************************************
  tablePageSizeOptions: number[] = [5, 10, 25, 50, 75, 100];
  fileListPageSize: number = 5;
  fileListPageIndex: number = 0;
  fileTablePageSize: number = 20;
  fileTablePageIndex: number = 0;
  tempTablePageSize: number = 20;
  tempTablePageIndex: number = 0;
  
  // FILE UPLOAD PROPERTIES ***************************************************************
  uploadProgress: number;
  uploadSub: Subscription;
  
  // FILE PROPERTIES **********************************************************************
  fileHtml: string = "";
  fileRowCount: number = 0;
  fileHeaders: any[] = [];
  fileHeaderCount: number = 0;
  rowsWithBadCharacters: any[] = [];
  numberOfRowsWithBadCharacters: number = 0;
  
  // FIELD MAP PROPERTIES *****************************************************************
  fieldMap: any[] = [];
  importTable: string = "";
  destinationTable = {
    name: "",
    columns: [],
    recordCount: 0,
    data: []
  };
  newColumnForm: FormGroup;
  importSequence: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([
    {
      fieldMappings: [],
      distinct: false
    }
  ]);
  sequenceDisplayIndex: number;
  
  // FILE PROPERTIES **********************************************************************
  selFile: any = {};
  selSheet: string = "";

  // TEMPORARY TABLE PROPERTIES ***********************************************************
  tempTableData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  tempHeaders: any[] = [];
  tempTotalTableRowCount: number = 0;
  tempRowCount: number = 0;
  selTempColumn: string = "";
  
  // TEMPRORAY TABLE ACTION PROPERTIES ****************************************************
  tempAutoSave: boolean = false;
  undoStore: any[] = [];
  redoStore: any[] = [];
  allValidSelected: boolean = false;
  
  // TEMPORARY TABLE VALIDATION PRIPERTIES ************************************************
  tempTableErrors: number = 0;
  tempTableErrorRows: number = 0;
  tempTableWarnings: number = 0;
  tempTableWarningRows: number = 0;
  
  // DUPLICATE PROPERTIES *****************************************************************
  duplicateRows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  suspectedDuplicateCount: number = 0;
  confirmedDuplicateCount: number = 0;
  remainingRows: number = 0;
  syncedRows: number = 0;
    
  // FILTER PROPERTIES ********************************************************************
  tempTableFilter$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  hideConfirmedDuplicates$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  // HOVER FORM PROPERTIES ****************************************************************
  hoverObj: any = {};
  hoverFormVisible: boolean = false;
  hoverFormX: number = 0;
  hoverFormY: number = 0;

  constructor(public fileSvc: FilesService,
              private dialogSvc: DialogService,
              private fb: FormBuilder,
              public peopleSvc: PeopleService) {}

  ngOnInit(): void {
    this.fileSvc.getFileUploadList().subscribe(x => this.fileSvc.uploadedFiles$.next(x[0]));
    this.newColumnForm = this.fb.group({
      columnName: [''],
      dataType: ['VARCHAR(20)'],
      dataTypeOptions: ['']
    });
    this.hideConfirmedDuplicates$.subscribe(dups => {
      if (this.selFile.imported_list_id) {
        let filter = dups?{join: "AND", conditions: [{field: "sling_duplicate", operand: "<>", value: 1}]}:{};
        
        this.loadingStatus.tempTable = true;
        this.tempTableFilter$.next(filter);
      }
    })
    this.tempTableFilter$.pipe(
      filter(filter => this.selFile.imported_list_id),
      switchMap(filter => this.fileSvc.getTemporaryTableContent(this.selFile.imported_list_id, this.selSheet, this.tempTablePageIndex, this.tempTablePageSize, filter))
    ).subscribe((response: any) => this.updateTemporaryTableDisplayData(response));
  }
  
  // FILE UPLOAD FUNCTIONS *****************************************************************
  onFileSelected = (event) => {
    this.uploadSub = this.fileSvc.fileUpload(event).pipe(finalize(() => this.reset())).subscribe((result: any) => {
      if (result.type === HttpEventType.UploadProgress) this.uploadProgress = Math.round(100 * (result.loaded / result.total));
      if (result.type === HttpEventType.Response) {
        this.fileSvc.uploadedFiles$.next(result.body.uploadedFileList[0]);
        this.fileUploadComplete(result.body.newFile[0]);
      }
    });
  }
  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }
  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
  fileUploadComplete = (file: any) => {
    this.setSelectedFile(file);
  }
  
  // FILE LIST FUNCTIONS *******************************************************************
  getFileUploadPage = (event) => {
    this.fileListPageIndex = event.pageIndex;
  }
  deleteFile = (file) => {
    this.dialogSvc.confirmDialog("Delete File", `Are you sure you want to delete '${file.file_name}`)
      .pipe(mergeMap((resp) => {
        if (resp) return this.fileSvc.deleteFile(file.imported_list_id)
      }))
      .subscribe((x: any[]) => this.fileSvc.uploadedFiles$.next(x));
  }
  
  // FILE LOAD FUNCTIONS *******************************************************************
  setSelectedFile = (fileObj) => {
    this.selFile = fileObj;
    this.loadingStatus.fileTable = true;
    if (typeof fileObj.sheets !== "undefined") {
      if (fileObj.sheets.length > 0) this.selSheet = fileObj.sheets[0].worksheet_name;
    }
    this.loadFile(fileObj.imported_list_id, this.selSheet);
  }
  selSelectedWorksheet = (fileId: number, sheetName: string) => {
    this.selSheet = sheetName;
    if (typeof this.selFile.sheets !== "undefined") {
      if (this.selFile.sheets[this.selFile.sheets.findIndex(sht => sht.worksheet_name === this.selSheet)].field_map) {
        this.importSequence.next(JSON.parse(this.selFile.sheets[this.selFile.sheets.findIndex(sht => sht.worksheet_name === this.selSheet)].field_map));
      } else {
        this.importSequence.next([]);
      }
    }
    this.loadFile(fileId, sheetName);
  }
  loadFile = (fileId: number, sheetName?: string) => {
    let options: any = {
          fileTablePageSize: this.fileTablePageSize,
          fileTablePageIndex: this.fileTablePageIndex,
          tempTablePageSize: this.tempTablePageSize,
          tempTablePageIndex: this.tempTablePageIndex
        };
    if (sheetName) options.sheetName = sheetName;
    if (typeof this.selFile.sheets !== "undefined") {
      let sheetIndex = this.selFile.sheets.findIndex(sht => sht.worksheet_name === this.selSheet);
      if (this.selFile.sheets[sheetIndex].field_map) {
        this.importSequence.next(JSON.parse(this.selFile.sheets[sheetIndex].field_map));
      } else {
        this.importSequence.next([]);
      }
    }
    
    this.fileSvc.loadFile(fileId, options).subscribe((response: any) => {
      this.resetFileDisplay();
      this.fileHtml = response.fileContents.html;
      this.fileRowCount = response.fileContents.rowCount;
      this.fileHeaders = response.fileContents.fields;
      this.rowsWithBadCharacters = response.fileContents.rowsWithBadCharacters;
      this.loadingStatus.fileTable = false;
      this.updateTemporaryTableDisplayData(response);
    });
  }
  resetFileDisplay = () => {
    this.fileRowCount = 0;
    this.tempRowCount = 0;
    this.tempHeaders = [];
    this.tempTableData$.next([]);
  }
  
  // FILE TABLE FUNCTIONS ******************************************************************
  fileTablePageChange = (event) => {
    this.fileTablePageSize = event.pageSize;
    this.fileTablePageIndex = event.pageIndex;
    this.loadFile(this.selFile.imported_list_id, this.selSheet);
  }
  importFileIntoTemporaryTable = (fileId, options) => {
    if (typeof fileId !== "undefined") this.fileSvc.importFileIntoTemporaryTable(fileId, options).subscribe((response: any) => {
      this.tempTableData$.next(response.tempTable[0]);
      this.tempHeaders = response.tempTable[1].map(el => el.name);
      this.tableView = "temporary";
    });
  }
  
  // TEMPORARY TABLE FUNCTIONS *************************************************************
  getTemporaryTableData = (fileId: number, sheetName: string, pageIndex: number, pageSize: number, filter: any) => {
    this.fileSvc.getTemporaryTableContent(fileId, sheetName, pageIndex, pageSize, filter)
    .subscribe((response: any) => {
      if (typeof response.tempTable.data !== "undefined") this.tempTableData$.next(response.tempTable.data);
      if (typeof response.tempTable.filterCount !== "undefined") this.tempRowCount = response.tempTable.filterCount;
      this.loadingStatus.tempTable = false;
    });
  }
  updateTemporaryTableDisplayData = (response: any) => {
    if (typeof response.tempTable.counts !== "undefined") {
      this.tempTotalTableRowCount = (typeof response.tempTable.counts.totalRows !== "undefined")?response.tempTable.counts.totalRows:0;
      this.tempRowCount = (typeof response.tempTable.counts.totalRows !== "undefined")?response.tempTable.counts.totalRows:0;
      this.confirmedDuplicateCount = (typeof response.tempTable.counts.confirmedDuplicates !== "undefined")?response.tempTable.counts.confirmedDuplicates:0;
      this.remainingRows = (typeof response.tempTable.counts.remainingRows !== "undefined")?response.tempTable.counts.remainingRows:0;
      this.syncedRows = (typeof response.tempTable.counts.syncedRows !== "undefined")?response.tempTable.counts.syncedRows:0;
    }
    if (typeof response.tempTable.filterCount !== "undefined") this.tempRowCount = response.tempTable.filterCount;
    if (typeof response.tempTable.fields !== "undefined") this.tempHeaders = response.tempTable.fields;
    if (typeof response.tempTable.validation !== "undefined") {
      this.tempTableErrors = (typeof response.tempTable.validation.errorCount !== "undefined")?response.tempTable.validation.errorCount:0;
      this.tempTableWarnings = (typeof response.tempTable.validation.warningCount !== "undefined")?response.tempTable.validation.warningCount:0;
    }
    if (typeof response.tempTable.data !== "undefined") this.tempTableData$.next(response.tempTable.data);
    if (typeof response.duplicateRows !== "undefined") {
      this.duplicateRows$.next(response.duplicateRows);
      this.suspectedDuplicateCount = response.duplicateRows.length;
    } else {
      this.duplicateRows$.next([]);
    }
    this.loadingStatus.tempTable = false;
  }
  getTempTablePage = (event) => {
    let filter = this.tempTableFilter$.getValue();
    
    this.loadingStatus.tempTable = true;
    this.tempTablePageIndex = event.pageIndex;
    this.tempTablePageSize = event.pageSize;
    this.getTemporaryTableData(this.selFile.imported_list_id, this.selSheet, event.pageIndex, event.pageSize, filter);
  }
  
  // TEMPORARY TABLE ACTION FUNCTIONS ******************************************************
  someValidSelected = () => {
  //  if (this.validResults == null) return false;
  //  return this.validResults.filter(t => t._selected).length > 0 && !this.allValidSelected;
  }
  selectAllValidRows = (selected) => {
    let tempTableData = this.tempTableData$.getValue();
    if (tempTableData === null) return;
    tempTableData.forEach(row => row._selected = selected);
//    this.updateAllValidSelected();
  }
  clearAllSelectedRows = () => {
    let tempTableData = this.tempTableData$.getValue();
    tempTableData.map(row => row._selected = false);
    this.tempTableData$.next(tempTableData);
  }
  addUndoAction = (fileId: number, sheetName: string, primaryKey: string, recordId: number, field: string, oldValue: any, newValue: any, userName?: string) => {
    userName = userName || "user";
    return {
      fileId: fileId,
      sheetName: sheetName,
      primaryKey: primaryKey,
      recordId: recordId,
      field: field,
      oldValue: oldValue || "",
      newValue: newValue,
      dateUpdated: new Date(),
      userUpdated: userName
    }
  }
  undoAction = (dataSet: any) => {
    let actionSet: any[] = this.undoStore.pop();
    
    actionSet.forEach((action: any) => {
      let index: number = dataSet.findIndex(row => row[action.primaryKey] === action.recordId);

      dataSet[index][action.field] = action.oldValue;
    });
    this.redoStore.push(actionSet);
  }
  redoAction = (dataSet: any) => {
    let actionSet: any[] = this.redoStore.pop();
    
    actionSet.forEach((action: any) => {
      let index: number = dataSet.findIndex(row => row[action.primaryKey] === action.recordId);

      dataSet[index][action.field] = action.newValue;
    })
    this.undoStore.push(actionSet);
  }
  getUnsavedRecordCount = () => {
    let tempTableData = this.tempTableData$.getValue();
    return tempTableData.filter(row => row._unsaved).length;
  }
  saveRecords = () => {
    let tempTableData = this.tempTableData$.getValue(),
        records = tempTableData.filter(row => row._unsaved && !row._saving);
    
    records.map(row => row._saving === true);
    this.allValidSelected = false;
    this.fileSvc.saveUpdatedRecords(this.selFile.imported_list_id, this.selSheet, records).subscribe((response: any[]) => {
      this.updatedTempData(response);
    });
  }
  openTemporaryTableDialog = (dataObj: any, index: number) => {
    this.dialogSvc.temporaryTableDialog(dataObj).subscribe((response: any) => {
      if (response) {
        let tempTableData = this.tempTableData$.getValue();
        tempTableData[index] = response;
        this.tempTableData$.next(tempTableData);
      }
    });
  }
  updatedTempData = (updatedRows: any[]) => {
    let tempTableData = this.tempTableData$.getValue();
    updatedRows.forEach((row: any) => {
      let index = tempTableData.findIndex(tmp => tmp.sling_imported_record_id === row.sling_imported_record_id);
      tempTableData[index] = row;
      this.tempTableData$.next(tempTableData);
    });
  }
  
  // FIELD MAP FUNCTIONS *******************************************************************
  addColumnToTempTable = (colObj) => {
    let options = {
      sheetName: this.selSheet,
      tempTablePageSize: this.tempTablePageSize
    }
    this.loadingStatus.tempTable = true;
    this.fileSvc.addColumnToTempTable(this.selFile.imported_list_id, colObj, options)
    .subscribe((resp: any) => {
//      this.updateTemporaryTableView(resp);
      this.newColumnForm.reset();
    });
  }
  getTable = (ev) => {
    this.fileSvc.getTable(ev.value)
    .subscribe((resp: any) => {
      this.destinationTable.data = resp.data;
      this.destinationTable.columns = resp.columns;
      this.destinationTable.name = ev.value;
    });
  }
  getImportTables = () => {
    return this.fileSvc.tables.filter(tbl => tbl.substring(0,6).toLowerCase() !== "import");
  }
  destinationFields = (column) => {
    return this.fieldMap.filter(mp => mp.from.name === column.name);
  }
  getFieldDestinations = (columnName) => {
    let result = [],
        importSeq = this.importSequence.getValue();
    
    importSeq.forEach(seq => {
      result = result.concat(seq.fieldMappings.filter(fldMap => fldMap.from.name === columnName))
    });
    return result;
  }
  mapField = (ev) => {
    this.addFieldMapping(ev.item.data, ev.container.data);
    this.saveFieldMap();
  }
  addFieldMapping = (fromCol, toCol) => {
    let importSeq = this.importSequence.getValue();
    if (importSeq.length > 0) {
      let index = importSeq.findIndex(imp => imp.fieldMappings.every(fldMap => fldMap.to.TABLE_NAME === toCol.TABLE_NAME && fldMap.to.COLUMN_NAME !== toCol.COLUMN_NAME));
      let tableSeqs = importSeq.filter(imp => imp.fieldMappings.some(fldMap => fldMap.to.TABLE_NAME === toCol.TABLE_NAME));
      if (tableSeqs.length > 0) {
        let fldIndex = tableSeqs.findIndex(seq => seq.fieldMappings.every(fld => fld.to.COLUMN_NAME !== toCol.COLUMN_NAME));
        if (fldIndex > -1) {
          importSeq[index].fieldMappings.push({from: fromCol, to: toCol});
        } else {
          importSeq.push({fieldMappings: [{from: fromCol, to: toCol}], distinct: false});
        }
      } else if (importSeq[importSeq.length - 1].fieldMappings.length === 0) {
        importSeq[importSeq.length - 1].fieldMappings.push({from: fromCol, to: toCol});
      } else {
        importSeq.push({fieldMappings: [{from: fromCol, to: toCol}], distinct: false});
      }
    } else {
      importSeq.push({fieldMappings: [{from: fromCol, to: toCol}], distinct: false});
    }
    this.importSequence.next(importSeq);
  }
  addImportSequence = () => {
    let importSeq: any[] = this.importSequence.getValue();
    importSeq.push({fields: []});
    this.importSequence.next(importSeq);
  }
  getImportTableSchema = () => {
    let schema = this.fileSvc.schema$.getValue();
    return schema.filter(tbl => tbl.TABLE_NAME.substring(0,6).toLowerCase() !== "import")
  }
  getFieldMapData = (fileId: number, sheetName: string, fieldMapSequenceIndex: number) => {
    this.fileSvc.getFieldMapSequenceImport(fileId, sheetName, fieldMapSequenceIndex).subscribe((response: any) => {
      this.sequenceDisplayIndex = fieldMapSequenceIndex;
      this.tableView = 'sequence';
    })
  }
  removeFieldMapping = (seqIndex, mapIndex) => {
    let importSeq: any[] = this.importSequence.getValue();
    importSeq[seqIndex].fieldMappings.splice(mapIndex, 1);
    this.importSequence.next(importSeq);
    this.saveFieldMap();
  }
  saveFieldMap = () => {
    this.fileSvc.saveFieldMap(this.selFile.imported_list_id, this.selSheet, this.importSequence.getValue(), this.tempTablePageIndex, this.tempTablePageSize)
      .subscribe((response: any) => {
        this.fileSvc.uploadedFiles$.next(response.fileList);
    });
  }
  moveFields = (sourceField: string) => {
    return this.tempHeaders.filter(hdr => hdr.name !== sourceField);
  }
  logResult = (event, result: any) => {
    console.log("Event: ", event);
    console.log("Result Log: ", result);
  }
  
  // TEMPORARY TABLE VALIDATION FUNCTIONS **************************************************
  getWarningClass = (row, col) => {
    if (typeof row._rowValidation !== "undefined") {
      if (Array.isArray(row._rowValidation)) {
        let test = row._rowValidation.find(el => el.field === col.name);
        if (typeof test !== "undefined") {
          if (typeof test.warnings !== "undefined") {
            return (test.warnings > 0);
          }
        }
      }
    }
    return false;
  }
  getErrorClass = (row, col) => {
    if (typeof row._rowValidation !== "undefined") {
      if (Array.isArray(row._rowValidation)) {
        let test = row._rowValidation.find(el => el.field === col.name);
        if (typeof test !== "undefined") {
          if (typeof test.errors !== "undefined") {
            return (test.errors > 0);
          }
        }
      }
    }
    return false;
  }
  
  splitSelectedColumnIntoAddressCityStateZip = (field) => {
    let tempTableData = this.tempTableData$.getValue();
    tempTableData.filter(x => x._selected).map(el => {
      let undoActionBatch = [];
      let row: any = {}, undoAction: any,
          fileId: number = this.selFile.imported_list_id,
          sheetName: string = this.selSheet,
          primaryKey: string = "sling_imported_record_id",
          recordId: number = el.sling_imported_record_id;
      Object.assign(row, el);
      
      if (row[field].indexOf("  ") > -1) {
        do {
          row[field].replaceAll("  ", " ")
        } while (row[field].match(/  /g) > 0);
        undoActionBatch.push(this.addUndoAction(fileId, sheetName, primaryKey, recordId, field, row[field], el[field]));
      }
      if (typeof row[field] !== "undefined") {
        if (row[field].split(",")[0]) {
          el.address = row[field].split(",")[0].trim();
          undoActionBatch.push(this.addUndoAction(fileId, sheetName, primaryKey, recordId, "address", row.address, el.address));
          el._unsaved = true;
        }
        if (row[field].split(",")[1]) {
          el.city = row[field].split(",")[1].trim();
          undoActionBatch.push(this.addUndoAction(fileId, sheetName, primaryKey, recordId, "city", row.city, el.city));
          el._unsaved = true;
        }
        if (row[field].split(",")[2]) {
          if (row[field].split(",")[2].trim().split(" ")[0]) {
            el.state = row[field].split(",")[2].trim().split(" ")[0].trim();
            undoActionBatch.push(this.addUndoAction(fileId, sheetName, primaryKey, recordId, "state", row.state, el.state));
            el._unsaved = true;
          }
          if (row[field].split(",")[2].trim().split(" ")[1]) {
            el.zip = row[field].split(",")[2].trim().split(" ")[1].trim();
            undoActionBatch.push(this.addUndoAction(fileId, sheetName, primaryKey, recordId, "zip", row.zip, el.zip));
            el._unsaved = true;
          }
        }
      }
      this.undoStore.push(undoActionBatch);
      if (this.tempAutoSave) this.saveRecords();
    });
  }
  trimSelectedValues = (field) => {
    let tempTableData = this.tempTableData$.getValue();
    tempTableData.filter(x => x._selected).map(el => {
      if (el[field] !== el[field].trim()) {
        this.undoStore.push([this.addUndoAction(this.selFile.imported_list_id, this.selSheet, "sling_imported_record_id", el.sling_imported_record_id, field, el[field], el[field].trim())]);
        el[field] = el[field].trim();
        el._unsaved = true;
        this.fileSvc.validateRow(el, this.importSequence.getValue(), "sling_imported_record_id")
        if (this.tempAutoSave) this.saveRecords();
      }
    });
  }
  
  convertSelectedToProperCase = (tempData: any[], field: string) => {
    tempData.filter(x => x._selected).map(el => {
      if (typeof el[field] === "string") {
        let old_value: string = el[field],
            wordArray: string[] = el[field].split(" "),
            newWordArray: string[] = [];
        
        newWordArray = wordArray.map((word: string) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
        el[field] = newWordArray.join(" ");
        if (old_value !== el[field]) el._unsaved = true;
      }
    });
    this.tempTableData$.next(tempData);
  }
  moveSelected = (tempData: any[], sourceField: string, destinationField: string) => {
    tempData.filter(x => x._selected).map(el => {
      let oldSourceValue: string = el[sourceField],
          oldDestinationValue: string = el[destinationField];
      
      el[destinationField] = el[sourceField];
      el[sourceField] = "";
      if (el[sourceField] !== oldSourceValue || el[oldDestinationValue] !== el[destinationField]) el._unsaved = true;
    });
  }
  /*
  splitSelectedFirstLastNames = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);
      
      if (row[field]) row[field] = row[field].trim().replaceAll("  ", " ");
      if (row[field].split(" ")[0]) el.first_name = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.last_name = row[field].split(" ")[1].trim();
    });
  }
  splitSelectedFirstLastAtFirstSpace = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el.first_name = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.last_name = row[field].split(/ (.+)/)[1].trim();
    });
  }
  splitSelectedFirstLastAtNthCharacter = (field, n, delimiter) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {}, tokens = [];
      Object.assign(row, el);
      
      el.first_name = row[field].split(delimiter).slice(0, n).join(delimiter);
      el.last_name = row[field].split(delimiter).slice(n).join(delimiter);
    });
  }
  splitSelectedPrefixFirstName = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el.prefix = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.first_name = row[field].split(" ")[1].trim();
    });
  }
  splitSelectedPrefixFirstMiddleLast = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el.prefix = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.first_name = row[field].split(" ")[1].trim();
      if (row[field].split(" ")[2]) el.middle_name = row[field].split(" ")[2].trim();
      if (row[field].split(" ")[3]) el.last_name = row[field].split(" ")[3].trim();
    });
  }
  splitSelectedPrefixLastName = (fldMap) => {
    let field = fldMap.from.name;
    
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el.prefix = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.last_name = row[field].split(" ")[1].trim();
      if (field !== "prefix" && field !== "last_name") el[field] = "";
    });
  }
  splitSelectedFirstMiddleLastNames = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el.first_name = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.middle_name = row[field].split(" ")[1].trim();
      if (row[field].split(" ")[2]) el.last_name = row[field].split(" ")[2].trim();
    });
  }
  splitSelectedFirstMiddleLastSuffix = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el.first_name = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.middle_name = row[field].split(" ")[1].trim();
      if (row[field].split(" ")[2]) el.last_name = row[field].split(" ")[2].trim();
      if (row[field].split(" ")[3]) el.suffix = row[field].split(" ")[3].trim();
    });    
  }
  splitSelectedLastNameSuffix = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el.last_name = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.suffix = row[field].split(" ")[1].trim();
    });
  }
  splitSelectedFirstMiddleName = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el.first_name = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.middle_name = row[field].split(" ")[1].trim();
    });
  }
  splitSelectedFieldSuffixAtFirstComma = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);

      if (row[field].split(" ")[0]) el[field] = row[field].split(/,(.+)/)[0].trim();
      if (row[field].split(" ")[1]) el.suffix = row[field].split(/,(.+)/)[1].trim();
    });
  }
  splitSelectedPrefixFirstLast = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);
      
      if (row[field]) row[field] = row[field].trim().replaceAll("  ", " ");
      if (row[field].split(" ")[0]) el.prefix = row[field].split(" ")[0].trim();
      if (row[field].split(" ")[1]) el.first_name = row[field].split(" ")[1].trim();
      if (row[field].split(" ")[2]) el.last_name = row[field].split(" ")[2].trim();
    });
  }
  allCapsSelected = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      if (typeof el[field] === "string") el[field] = el[field].toUpperCase();
    })
  }
  moveSelectedToNotes = (field) => {
    this.validResults.filter(x => x._selected).map(el => {
      let row = {};
      Object.assign(row, el);
      
      if (row[field]) {
        el.notes = row[field];
        el[field] = "";
      }
    });
  }
  removePhoneSymbols = (field) => {
    let tempTableData = this.tempTableData$.getValue();
    tempTableData.filter(x => x._selected).map(el => {
      let oldValue: string = el[field];
      el[field] = el[field].replaceAll(" ", "").replaceAll("-", "").replaceAll("_", "").replaceAll(".", "").replaceAll("(", "").replaceAll(")", "").trim();
      if (el[field] !== oldValue) {
        el._unsaved = true;
        this.undoStore.push([this.addUndoAction(this.selFile.imported_list_id, this.selSheet, "sling_imported_record_id", el.sling_imported_record_id, field, oldValue, el[field])])
      }
    });
    if (this.tempAutoSave) this.saveRecords();
  }
  deleteCellContents = (field) => {
    this.validResults.filter(x => x._selected).map(el => el[field] = "");
  }
*/

  // DUPLICATE FUNCTIONS *******************************************************************
  updateDuplicateRow = (updatedRow: any) => {
    let tempTableData = this.tempTableData$.getValue(), duplicateRows = this.duplicateRows$.getValue(),
        index: number = tempTableData.findIndex((row: any) => row.sling_imported_record_id === updatedRow.sling_imported_record_id),
        dupIndex: number = duplicateRows.findIndex((row: any) => row.sling_imported_record_id === updatedRow.sling_imported_record_id);
    
    tempTableData[index] = updatedRow;
    this.tempTableData$.next(tempTableData);
    duplicateRows[dupIndex] = updatedRow;
    this.duplicateRows$.next(duplicateRows);
  }

  // FILE IMPORT FUNCTIONS *****************************************************************
  importList = () => {
    this.fileSvc.importFile(this.selFile.imported_list_id, this.selSheet)
      .subscribe(x => console.log(x));
  }
  importSecondaryTables = () => {
    this.fileSvc.importSecondaryTables(this.selFile.imported_list_id, this.selSheet)
    .subscribe((response: any) => console.log(response));
  }
  importDistinctTable = () => {
    this.fileSvc.importDistinctFile(this.selFile.imported_list_id, this.selSheet)
    .subscribe(x => console.log(x));
  }
}