import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { filter, switchMap } from 'rxjs/operators';

import { PeopleService, PhoneNumber } from '../people.service';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-phone-number-view',
  templateUrl: './phone-number-view.component.html',
  styleUrls: ['./phone-number-view.component.scss']
})
export class PhoneNumberViewComponent implements OnInit {
  @Input('phone-number') phoneNumber: PhoneNumber;
  @Input('person') person: any;
  @Input('display-mode') displayMode: string = 'view';
  @Output('person-updated') personUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output('show-new-phone-form') showNewPhoneForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  phoneForm: FormGroup;
  phoneLabels: string[] = ["Home", "Mobile", "Work", "Fax", "Other"];
  
  constructor(private fb: FormBuilder,
              private peopleSvc: PeopleService,
              private dialogSvc: DialogService) {}
  
  ngOnInit() {
    this.phoneForm = this.fb.group({
      phone_id: [],
      phone_number: ['', [Validators.required]],
      primary_phone: [false],
      contact_link: [],
      label: []
    });
    if (this.person) this.phoneForm.get('contact_link').setValue(this.person.person_id);
    if (this.phoneNumber) this.phoneForm.patchValue(this.phoneNumber);
  }
  
  save = (phoneNumber: PhoneNumber, person: any) => {
    if (phoneNumber.phone_id) {
      this.peopleSvc.updatePhoneNumber(phoneNumber).subscribe((response: PhoneNumber[]) => {
        person.phones = response;
        this.personUpdated.emit(person);
      });
    } else {
      this.peopleSvc.addPhoneNumber(phoneNumber).subscribe((response: PhoneNumber[]) => {
        person.phones = response;
        this.personUpdated.emit(person);
        this.phoneForm.reset({contact_link: person.person_id});
      });
    }
  }
  setPrimary = (phone: PhoneNumber, person: any) => {
    let phoneUpdate: any = {
      phone_id: phone.phone_id,
      primary_phone: 1,
      contact_link: phone.contact_link
    }
    
    this.peopleSvc.updatePhoneNumber(phoneUpdate).subscribe((response: any[]) => {
      person.phones = response;
      this.personUpdated.emit(person);
    });
  }
  delete = (phoneNumber: PhoneNumber, person: any) => {
    let title: string = "Confirm Delete Phone Number",
        question: string = `Are you sure you want to delete '${this.peopleSvc.formatPhoneNumber(phoneNumber.phone_number)}' from ${this.peopleSvc.getPersonName(person)}'s profile?`;
    
    this.dialogSvc.confirmDialog(title, question).pipe(
      filter((response: boolean) => response),
      switchMap(() => this.peopleSvc.deletePhoneNumber(phoneNumber.phone_id, person.person_id))
    ).subscribe((response: PhoneNumber[]) => {
      person.phones = response;
      this.personUpdated.emit(person);
    });
  }
  
  resetForm = (phone: PhoneNumber, person: any) => {
    if (typeof phone !== "undefined") {
      if (phone.phone_id) {
        this.phoneForm.reset(phone);
        this.displayMode = 'view';
      } else {
        this.phoneForm.reset({contact_link: person.person_id});
        this.showNewPhoneForm.emit(false);
      }
    } else {
      this.phoneForm.reset({contact_link: person.person_id});
      this.showNewPhoneForm.emit(false);
    }
  }
  reset = (phone: PhoneNumber, person: any) => {
    if (this.phoneForm.pristine) {
      this.resetForm(phone, person);
    } else {
      let title: string = `Confirm Discard Changes`,
          question: string = `Are you sure you want to discard your changes?`;
      
      this.dialogSvc.confirmDialog(title, question).pipe(
        filter((response: boolean) => response)
      ).subscribe((response: boolean) => {
        this.resetForm(phone, person);
      });
    }
  }
}