<div class="container-fluid" style="width: 700px;">
  <h1>Correspondence</h1>
  <form [formGroup]="correspondenceForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Title</mat-label>
          <input matInput type="text" name="title" formControlName="title">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="w-100" style="margin-top: -15px;">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Add Recipient:</mat-label>
            <input matInput [matAutocomplete]="searchRecip" type="text"
                   name="recipientSearch" [ngModel]="searchQuery"
                   [ngModelOptions]="{standalone: true}"
                   (ngModelChange)="onInputChange($event)">
          </mat-form-field>
        </div>
        <mat-autocomplete #searchRecip="matAutocomplete"
                          (optionSelected)="recipients.push($event.option.value)"
                          [displayWith]="displayNull">
          <mat-option *ngFor="let result of searchResults" [value]="result">
            <span>{{peopleSvc.getPersonName(result)}}</span>
          </mat-option>
        </mat-autocomplete>
        <span style="font-weight: bold;">Recipients</span>
        <ul>
          <li *ngFor="let recip of recipients; let idx = index;">
            <span>{{peopleSvc.getPersonName(recip)}}&nbsp;</span>
            <span class="pointer" style="color: red;" (click)="removeRecipient(idx)">X</span>
          </li>
        </ul>
      </div>
      <div class="col-6">
        <div class="w-100" style="margin-top: -15px;">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Envelope Title</mat-label>
            <input matInput type="text" name="title" formControlName="envelope_title">
          </mat-form-field>
        </div>
        <div class="w-100" style="margin-top: -15px;">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Address</mat-label>
            <input matInput type="text" name="title" formControlName="address">
          </mat-form-field>
        </div>
        <div class="w-100" style="margin-top: -15px;">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Address 2</mat-label>
            <input matInput type="text" name="title" formControlName="address2">
          </mat-form-field>
        </div>
        <div class="w-100 d-flex flex-row" style="margin-top: -15px;">
          <mat-form-field appearance="legacy" class="flex-grow-1">
            <mat-label>City</mat-label>
            <input matInput type="text" name="title" formControlName="city">
          </mat-form-field>
          <mat-form-field appearance="legacy" style="width: 40px; margin-left: 5px;">
            <mat-label>State</mat-label>
            <input matInput type="text" name="title" formControlName="state">
          </mat-form-field>
          <mat-form-field appearance="legacy" style="width: 60px; margin-left: 5px;">
            <mat-label>Zip</mat-label>
            <input matInput type="text" name="title" formControlName="zip">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="w-100">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Content</mat-label>
            <textarea matInput name="content" formControlName="content"
                      style="height: 160px;"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close><span>Cancel</span></button>
    <button mat-flat-button color="primary" cdkFocusInitial
            (click)="saveCorrespondence(correspondenceForm.value)">
      <span>Save</span>
    </button>
  </mat-dialog-actions>
</div>