import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { filter, switchMap, debounceTime, tap } from 'rxjs/operators';

import { PeopleService } from '../people.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { PersonQuickViewComponent } from '../person-quick-view/person-quick-view.component';

@Component({
  selector: 'app-email-address-view',
  templateUrl: './email-address-view.component.html',
  styleUrls: ['./email-address-view.component.scss']
})
export class EmailAddressViewComponent implements OnInit {
  @Input('email-address') emailAddress: any;
  @Input('person') person: any;
  @Input('display-mode') displayMode: string = "view";
  @Output('person-updated') personUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output('show-new-email-form') showNewEmailForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  emailAddressForm: FormGroup;
  emailLabels: string[] = ["Home", "Work", "Other"];
  duplicates: any[] = [];
  
  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              private router: Router,
              private peopleSvc: PeopleService,
              private dialogSvc: DialogService) {}
  
  ngOnInit() {
    this.emailAddressForm = this.fb.group({
      email_address_id: [],
      email: ['', [Validators.required, Validators.email]],
      label: [],
      primary_email: [],
      contact_link: [],
    });
    if (this.person && (this.emailAddress || {}).contact_link !== this.person.person_id) this.emailAddressForm.get('contact_link').setValue(this.person.person_id);
    if (this.emailAddress) this.emailAddressForm.patchValue(this.emailAddress);
    this.emailAddressForm.get('email').valueChanges.pipe(
      debounceTime(500),
      filter((email: string) => !!email),
      switchMap((email: string) => this.peopleSvc.checkEmailAddress(email))
    ).subscribe((response: any[]) => {
      this.duplicates = response;
    });
  }
  
  save = (email: any, person: any) => {
    if (email.email_address_id) {
      this.peopleSvc.updateEmailAddress(email).subscribe((response: any[]) => {
        person.email_addresses = response;
        this.personUpdated.emit(person);
      });
    } else {
      this.peopleSvc.addEmailAddress(email).subscribe((response: any[]) => {
        person.email_addresses = response;
        this.emailAddressForm.reset();
        this.personUpdated.emit(person);
      });
    }
  }
  setPrimary = (email: any, person: any) => {
    let emailUpdate = {
      email_address_id: email.email_address_id,
      primary_email: 1,
      contact_link: email.contact_link
    }
    
    this.peopleSvc.updateEmailAddress(emailUpdate).subscribe((response: any[]) => {
      person.email_addresses = response;
      this.personUpdated.emit(person);
    });
  }
  delete = (email: any, person: any) => {
    let title: string = "Confirm Email Delete",
        question: string = `Are you sure you want to delete '${email.email}' from ${this.peopleSvc.getPersonName(person)}'s profile?`;
    
    this.dialogSvc.confirmDialog(title, question).pipe(
      filter((response: boolean) => response),
      switchMap(() => this.peopleSvc.deleteEmailAddress(email.email_address_id, person.person_id))
    ).subscribe((response: any[]) => {
      person.email_addresses = response;
      this.personUpdated.emit(person);
    });
  }
  
  resetForm = (email: any, person: any) => {
    if (typeof email !== "undefined") {
      if (email.email_address_id) {
        this.emailAddressForm.reset(email);
        this.displayMode = 'view';
      } else {
        this.emailAddressForm.reset({contact_link: person.person_id});
        this.showNewEmailForm.emit(false);
      }
    } else {
      this.emailAddressForm.reset({contact_link: person.person_id});
      this.showNewEmailForm.emit(false);
    }
  }
  reset = (email: any, person: any) => {
    if (this.emailAddressForm.pristine) {
      this.resetForm(email, person);
    } else {
      let title: string = `Confirm Changes`,
          question: string = `Are you sure you want to discard your changes?`;
      
      this.dialogSvc.confirmDialog(title, question).pipe(
        filter((response: boolean) => response)
      ).subscribe((response: boolean) => {
        this.resetForm(email, person);
      });
    }
  }
  
  openQuickView = (person: any) => {
    this.peopleSvc.getPerson(person.person_id).subscribe((response: any) => {
      this.dialog.open(PersonQuickViewComponent, {data: response});
    });
  }
  navigateToContact = (email: any, person: any) => {
    if (this.emailAddressForm.pristine) {
      this.router.navigate(['activists', person.person_id]);
    } else {
      if (this.emailAddressForm.pristine) {
        this.resetForm(email, person);
      } else {
        let title: string = `Confirm Discard Changes`,
            question: string = `Are you sure you want to discard your changes?`;

        this.dialogSvc.confirmDialog(title, question).pipe(
          filter((response: boolean) => response),
          tap(() => this.resetForm(email, person))
        ).subscribe((response: boolean) => {
          this.router.navigate(['activists', person.person_id]);
        });
      }
    }
  }
}