import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import * as moment from "moment";

import { ConfigService } from '@services/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: string;
  currentUser$: BehaviorSubject<any> = new BehaviorSubject({});
  users$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  error: BehaviorSubject<any> = new BehaviorSubject({type: "none", msg: ""});

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    if (this.isLoggedIn) this.currentUser$.next(JSON.parse(localStorage.getItem('currentUser')));
    this.configSvc.settings.subscribe((settings: any) => this.url = settings.apiUrl);
  }
  
  getCurrentUserName = () => {
    let currUser: any = this.currentUser$.getValue();
    if (currUser) return currUser.first_name + " " + currUser.last_name;
  }
  
  login = (loginObj) => {
    return this.http.post(this.url + "auth/login", {userLogin: loginObj}).pipe(
      tap(res => this.setSession),
      shareReplay()
    );
  }
  register = (regObj: any) => {
    return this.http.post(this.url + "auth/register", {newUser: regObj});
  }
  setSession = (authResult: any) => {
    const expiresAt = moment().add(authResult.expiresIn,'second');

    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
    this.setUser(authResult.user);
  }
  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem('currentUser');
  }
  
  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }
  isLoggedOut() {
    return !this.isLoggedIn();
  }
  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
  
  getUsers = () => {
    return this.http.get(this.url + "users");
  }
  getUser = (id: number) => {
    return this.http.get(this.url + "users/" + id);
  }
  createUser = (user: any) => {
    return this.http.post(this.url + "users", user);
  }
  saveUser = (user: any) => {
    if (user.user_id) {
      return this.http.put(this.url + 'users', user);
    } else {
      return this.http.post(this.url + 'users', user);
    }
  }
  deleteUser = (id: number) => {
    return this.http.delete(this.url + "users", {params: {id: id}});
  }
  
  setUser = (user) => {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
  activateUser = (userId: number, status: any) => {
    return this.http.get(this.url + "users/activation", {params: {id: userId, status: status}});
  }
  
  getUserStatusBySingleUseId = (id: string) => {
    return this.http.get(this.url + 'auth/new-user-status', {params: {id: id}});
  }
  changePassword = (userId: number, password: any) => {
    return this.http.put(this.url + 'auth/password/' + userId, password)
  }
  setNewUserPassword = (userId: string, password: string) => {
    return this.http.post(this.url + 'auth/new-user-password', {userId: userId, password: password});
  }
  
  checkUsername = (username: string) => {
    return this.http.get(this.url + "users/check-username", {params: {username: username}});
  }
  checkPermission = (permission: string) => {
    let currUser: any = this.currentUser$.getValue();
    
    if (typeof currUser !== "undefined" && currUser.hasOwnProperty(permission)) {
      return currUser[permission] === 1;
    } else {
      return false;
    }
    
  }
  checkPermissions = (permissions: string[]) => {
    let currUser: any = this.currentUser$.getValue();
    
    if (typeof currUser !== "undefined" && permissions.some((permission: string) => currUser.hasOwnProperty(permission))) {
      return permissions.some((permission: string) => currUser[permission] === 1);
    } else {
      return false;
    }
  }
}