<div class="row">
  <div *ngIf="authSvc.isLoggedIn() && authSvc.currentUser$ | async as user" class="col-auto">
    <div style="text-align: center;">
      <mat-icon style="color: gray; cursor: pointer; transform: scale(3); margin: 20px 0;">account_circle</mat-icon>
    </div>
    <h2 class="d-flex justify-content-center" style="margin-bottom: 0; padding-bottom: 0;">{{user.first_name + " " + user.last_name}}</h2>
    <h4 style="color: gray" class="d-flex justify-content-center" style="margin-top: -5px; padding-top: 0;">{{user.email}}</h4>
    <div class="d-flex justify-content-center" style="margin-bottom: 15px;">
      <button mat-stroked-button color="primary" (click)="navToUserProfile()">Manage Your Sling Account</button>
    </div>
    <div class="d-flex justify-content-center">
      <button mat-stroked-button color="primary" (click)="logout()">Sign Out of Your Account</button>
    </div>
  </div>
  <div *ngIf="authSvc.isLoggedOut()" class="cpl-auto">
    <div class="d-flex justify-content-center">
      <button mat-stroked-button color="primary" (click)="navToLogin()">Login To Your Account</button>
    </div>
  </div>
</div>