import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { DonationsService, PlanningFilter } from '../donations.service';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-annual-planning-notes-table-cell',
  templateUrl: './annual-planning-notes-table-cell.component.html',
  styleUrls: ['./annual-planning-notes-table-cell.component.scss']
})
export class AnnualPlanningNotesTableCellComponent implements OnInit {
  @Input('notes') planning_notes: string = "";
  @Input('filter') filter$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input('id') id: number|null;
  @Input('year') year: string = "2024";
  @Input('quarter') quarter: string = "Q1";
  @Output('update') update: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('notesInput', {static: false}) inputEl: ElementRef;
  edit: boolean = false;
  notesControl: FormControl = new FormControl();
  
  constructor(private donationSvc: DonationsService,
              private dialogSvc: DialogService) {}
  
  ngOnInit() {
    this.notesControl.setValue(this.planning_notes);
  }
  
  save = (note: string, id: number, filter: any) => {
    let payload: any = {planning_notes: note}
    
    this.donationSvc.updateAnnualPlanningItem(payload, id, filter).subscribe((response: any) => {
      this.update.emit(response);
    });
  }
  cancel = (control: FormControl, planning_notes: string) => {
    if (control.value !== planning_notes) {
      this.dialogSvc.confirmDialog("Cancel Notes", "Are you sure you want to cancel notes without saving?")
      .subscribe(() => {
        control.setValue(planning_notes);
        control.markAsPristine();
        this.edit = false;
      });
    } else {
      this.edit = false;
    }
  }
  toggleEdit = (value: boolean) => {
    this.edit = value;
    if (value) this.focusOnEdit();
  }
  focusOnEdit = () => {
    setTimeout(() => this.inputEl.nativeElement.focus());
  }
}