import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-person-quick-view',
  templateUrl: './person-quick-view.component.html',
  styleUrls: ['./person-quick-view.component.scss']
})
export class PersonQuickViewComponent implements OnInit {
  person$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(@Inject(MAT_DIALOG_DATA) public person: any) {}
  
  ngOnInit() {
    this.person$.next(this.person);
  }
}