import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, of } from 'rxjs';
import { filter, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { EmailService } from '@modules/email-editor/email.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { Person } from '@modules/person-profiles/person.model';

@Component({
  selector: 'app-email-results-table',
  templateUrl: './email-results-table.component.html',
  styleUrls: ['./email-results-table.component.scss']
})
export class EmailResultsTableComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  messages$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 5,
    pageIndex: 0,
    personId: 0,
    orderBy: [{field: `reviews.date_created`, order: `DESC`}]
  });
  pageSizeOptions: number[] = [5,10,15,20];
  totalRecords$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalPages: number = 0;

  constructor(private dialogSvc: DialogService,
              private dialog: MatDialog,
              private emailSvc: EmailService,
              private peopleSvc: PeopleService) { }

  ngOnInit(): void {
    this.person$.pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([person, filter]: [any, any]) => {
      if (person.person_id) {
        filter.personId = person.person_id;
        this.filter$.next(filter);
      } else {
        this.messages$.next([]);
        this.totalRecords$.next(0);
      }
    });
    this.filter$.pipe(
      filter((filter: any) => !!filter.personId),
      switchMap((filter: any) => this.emailSvc.getFilteredEmails(filter))
    ).subscribe((response: any) => {
      this.messages$.next(response.items);
      this.totalRecords$.next(response.count);
    });
    this.filter$.pipe(
      distinctUntilChanged((prev: any, curr: any) => prev.pageSize === curr.pageSize),
      withLatestFrom(this.totalRecords$)
    ).subscribe(([filter, totalRecords]: [any, number]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
    this.totalRecords$.pipe(withLatestFrom(this.filter$))
    .subscribe(([totalRecords, filter]: [number, any]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
  }
  
  updatePageSize = (pageSize: number, filter: any) => {
    filter.pageSize = pageSize;
    this.filter$.next(filter);
  }
  pageIncrement = (filter: any, pageIncrement: number) => {
    filter.pageIndex = filter.pageIndex + pageIncrement;
    this.filter$.next(filter);
  }
  setPage = (page: number, filter: any) => {
    filter.pageIndex = page;
    this.filter$.next(filter);
  }
  
  getOpens = (message: any) => {
    if (typeof message.responses !== "undefined") {
      return message.responses.filter((msg: any) => msg.eventType === 'Open').length;
    } else {
      return 0;
    }
  }
}