<ng-container *ngIf="filter$ | async as filter">
  <div *ngIf="edit" id="edit-control">
    <input #notesInput matInput type="text" placeholder="Add or Edit Donor Notes"
           [formControl]="notesControl">
    <button mat-icon-button class="sm-icon-btn" (click)="notesControl.setValue(''); focusOnEdit();">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button color="primary" class="sm-icon-btn"
            [disabled]="notesControl.value  === planning_notes"
            (click)="save(notesControl.value, id, filter)">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button color="warn" class="sm-icon-btn" (click)="cancel(notesControl, planning_notes)">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div id="notes" *ngIf="!edit">
    <div [title]="planning_notes"><span>{{planning_notes || " "}}</span></div>
    <button mat-icon-button class="sm-icon-btn" (click)="toggleEdit(true)">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</ng-container>