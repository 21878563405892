   
<table class="table table-hover table-sm">
  <thead>
    <tr>
      <th>Subject</th>
      <th>Date Sent</th>
      <th>Recipients</th>
      <th>Opens</th>
      <th><mat-icon class="sm-icon">assignment</mat-icon></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of emailSvc.sendAttempts$ | async">
      <td><span class="ellipsis">{{item.subject}}</span></td>
      <td><span>{{item.date_sent | date: 'short'}}</span></td>
      <td><span>{{item.recipient_count}}</span></td>
      <td><span>{{item.open_count}}</span></td>
      <td>
        <button mat-icon-button class="sm-icon-btn"
                (click)="editEmail.emit(item)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </tr>
  </tbody>
</table>