import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

import { SharedComponentsModule } from '@modules/shared-components/shared-components.module';

import { ContactLogDialogComponent } from './contact-log-dialog/contact-log-dialog.component';
import { ContactLogTableComponent } from './contact-log-table/contact-log-table.component';
import { SolicitorDashboardComponent } from './solicitor-dashboard/solicitor-dashboard.component';
import { SolicitorListComponent } from './solicitor-list/solicitor-list.component';

@NgModule({
  declarations: [
    ContactLogDialogComponent,
    ContactLogTableComponent,
    SolicitorDashboardComponent,
    SolicitorListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSelectModule,
    SharedComponentsModule,
  ],
  exports: [
    ContactLogDialogComponent,
    ContactLogTableComponent,
    SolicitorDashboardComponent,
    SolicitorListComponent,
  ]
})
export class ContactLogsModule { }