<div fxFlexFill fxLayout="column" fxLayoutGap="10px"
     *ngIf="filter$ | async as filter" class="overflow-auto">
  <app-pagination item-name="Donations" [total-records]="totalRecords$ | async"
                  [page-index]="filter.pageIndex" [page-size]="filter.pageSize"
                  (change-page-size)="updatePageSize($event, filter)"
                  (page-increment)="pageIncrement(filter, $event)"></app-pagination>
  <table *ngIf="person$ | async as person">
    <thead>
      <tr>
        <th><span>Date</span></th>
        <th>
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
            <span>Donation Recipient</span>
            <button color="primary" mat-icon-button class="sm-icon-btn"
                    [disabled]="!person.person_id || !authSvc.checkPermission('create_donations')"
                    (click)="openDialog({donationTags: []}, person, filter, 'edit')">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
        </th>
        <th class="text-end" style="width: 100px;"><span>Amount Pledged</span></th>
        <th class="text-end" style="width: 100px;"><span>Amount Paid</span></th>
        <th style="width: 80px" class="text-end">
          <div class="sm-icon-btn">
            <mat-icon>assignment</mat-icon>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="overflow-auto">
      <tr *ngFor="let donation of donations$ | async">
        <td>
          <span>{{donation.date | date: 'shortDate'}}</span>
        </td>
        <td scope="row" class="pointer" (click)="openDialog(donation, person, filter, 'view')">
          <span class="ellipsis">{{donation.recipient}}</span>
        </td>
        <td class="text-end" style="width: 100px;">
          <span *ngIf="donation.amount_pledged">$</span><span>{{donation.amount_pledged | mask: 'separator':','}}</span>
        </td>
        <td class="text-end" style="width: 100px;">
          <span *ngIf="donation.amount_paid">$</span><span>{{donation.amount_paid | mask: 'separator':','}}</span>
        </td>
        <td class="text-end" style="width: 80px">
          <button mat-icon-button class="sm-icon-btn" [matMenuTriggerFor]="donationActionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
        <mat-menu class="compact-menu" #donationActionMenu="matMenu">
          <button mat-menu-item (click)="delete(donation, filter)"
                  [disabled]="!authSvc.checkPermission('delete_donations')">
            <mat-icon>delete</mat-icon><span>Delete Donation</span>
          </button>
          <button mat-menu-item (click)="openDialog(donation, person, filter, 'edit')"
                  [disabled]="!authSvc.checkPermission('edit_donations')">
            <mat-icon>edit</mat-icon><span>Edit Donation</span>
          </button>
        </mat-menu>
      </tr>
      <tr style="border-top: solid 2px black;">
        <td></td>
        <td class="text-end">
          <span style="font-weight: bold;">Donor Totals:</span>
        </td>
        <td class="text-end" style="width: 100px;">
          <span>${{totalPledged | mask: 'separator':','}}</span>
        </td>
        <td class="text-end" style="width: 100px;">
          <span>${{totalPaid | mask: 'separator':','}}</span>
        </td>
        <td class="text-end" style="width: 80px">
          <span>${{((totalPledged - totalPaid) > 0?(totalPledged - totalPaid):0) | mask: 'separator':','}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>