export class Person {
  person_id: number;
  prefix: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  suffix: string;
  preferred_name: string;
  name_on_envelope: string;
  notes: string;
  img_url: string;
  verified: number;
  date_verified: Date;
  user_verified: string;
  date_created: Date;
  user_created: string;
  imported_record_table: string;
  imported_record_id: number;
  actions?: any[] = [];
  emails?: any[] = [];
  reviews?: any[] = [];
  addresses?: any[] = [];
  phones?: any[] = [];
  email_addresses?: any[] = [];
  donations?: any[] = [];
  contact_log?: any[] = [];
  lists?: any[] = [];
  correspondence?: any[] = [];
  issues?: string[] = [];
  _duplicates?: any = {_duplicates: {firstLast: [], last: []}};
  _validation?: any[] = [];
}
