import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { QuillModule } from 'ngx-quill'
import { PipesModule } from '@pipes/pipes.module';
import { EmailUnsubscribeResolver } from '@modules/email-editor/email-unsubscribe.resolver';

import { EmailEditorComponent } from './email-editor/email-editor.component';
import { EmailFrameComponent } from './email-frame/email-frame.component';
import { EmailPreviewDialogComponent } from './email-preview-dialog/email-preview-dialog.component';
import { EmailResultsComponent } from './email-results/email-results.component';
import { SendAttemptsComponent } from './send-attempts/send-attempts.component';
import { SentItemsComponent } from './sent-items/sent-items.component';
import { UserEmailPreferencesComponent } from './user-email-preferences/user-email-preferences.component';
import { SentItemSummaryComponent } from './sent-item-summary/sent-item-summary.component';

@NgModule({
  declarations: [
    EmailEditorComponent,
    EmailFrameComponent,
    EmailPreviewDialogComponent,
    EmailResultsComponent,
    SendAttemptsComponent,
    SentItemsComponent,
    UserEmailPreferencesComponent,
    SentItemSummaryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    MatMenuModule,
    AngularEditorModule,
    QuillModule.forRoot(),
    PipesModule,
    MatButtonToggleModule,
  ],
  providers: [
    EmailUnsubscribeResolver,
  ]
})
export class EmailEditorModule { }