import { Component, Input, OnInit } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ImporterService } from '../importer.service';

@Component({
  selector: 'app-field-mapper',
  templateUrl: './field-mapper.component.html',
  styleUrls: ['./field-mapper.component.scss']
})
export class FieldMapperComponent implements OnInit {
  @Input('sheet') sheet$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selectedTable$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  tables: any[] = [
    {
      label: "People",
      name: "people"
    },
    {
      label: "Email Addresses",
      name: "email_addresses"
    },
    {
      label: "Phone Numbers",
      name: "phones"
    },
    {
      label: "Addresses",
      name: "addresses"
    },
    {
      label: "Donations",
      name: "donations"
    }
  ];
  destinationFields: any[] = [];
  
  constructor(private importSvc: ImporterService) {}
  
  ngOnInit() {
    this.sheet$.subscribe((sheet: any) => {
      console.log("FIELD MAPPER SHEET: ", sheet);
    });
    this.selectedTable$.pipe(
      switchMap((table: any) => this.importSvc.getTableColumns(table.name))
    ).subscribe((response: any) => {
      this.destinationFields = response;
    });
  }
}