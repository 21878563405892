<ng-container *ngIf="filter$ | async as filter">
  <div *ngIf="{index: index$ | async} as list" fxLayout="column">
    <div>
      <div>
        <button mat-icon-button (click)="refreshDuplicates(filter)">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <app-pagination item-name="Duplicates" [total-records]="duplicateCount"
                    [page-index]="filter.pageIndex" [page-size]="filter.pageSize"
                    (change-page-size)="filter.pageSize=$event;filter$.next(filter);"
                    (page-increment)="filter.pageIndex=filter.pageIndex+$event;filter$.next(filter);"
                    (set-page)="filter.pageIndex=$event;filter$.next(filter);"></app-pagination>
    <div id="duplicate-list">
      <div *ngFor="let duplicate of duplicateList; let i = index"
           (click)="index$.next(i)"
           [ngClass]="{'selected': list.index === i}">
        <span>{{duplicate.first_name}} {{duplicate.last_name}}</span>
      </div>
    </div>
  </div>
  <app-duplicate-person-view *ngFor="let duplicate of duplicates; let i = index;" class="h-100"
                             [person]="duplicate" [primary]="primary$" [unsaved-primary]="unsavedPrimary$"
                             (set-primary)="primary$.next($event)"
                             (remove-person)="removePerson(i, duplicates, list.index || 0)"
                             (update-primary)="updatePerson($event, primary)"
                             (update-unsaved-primary)="unsavedPrimary$.next($event)">
  </app-duplicate-person-view>
</ng-container>