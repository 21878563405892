import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";

import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PeopleService } from './people.service';

@Injectable({
  providedIn: 'root'
})
export class PersonResolverService implements Resolve<any> {
    
  constructor(public peopleSvc: PeopleService
              ) { }
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.params.id === "new") return of({});
    return this.peopleSvc.getPerson(route.params.id).pipe(tap((person: any) => console.log(person)));
  }
}