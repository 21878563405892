import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { of, BehaviorSubject } from 'rxjs';
import { tap, debounceTime, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { DonationsService, PlanningFilter } from '../donations.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { PeopleService } from '@modules/person-profiles/people.service';

@Component({
  selector: 'app-annual-planning-table',
  templateUrl: './annual-planning-table.component.html',
  styleUrls: ['./annual-planning-table.component.scss']
})
export class AnnualPlanningTableComponent implements OnInit {
  filter$: BehaviorSubject<PlanningFilter>;
  @Input('quarter') quarter: string = "Q1";
  @Input('year') year: string = "2024";
  donors: any[] = [];
  donorCount: number = 0;
  donorSearchResults: any[] = [];
  addDonorControl: FormControl = new FormControl();
  searching: boolean = false;
  total: number = 0;
  yearlyTotals: any = {};
  quarters: string[] = ["Q1", "Q2", "Q3", "Q4"];
  
  constructor(private donationSvc: DonationsService,
              private dialogSvc: DialogService,
              protected peopleSvc: PeopleService) {}
  
  ngOnInit() {
    this.filter$ = new BehaviorSubject<PlanningFilter>({
      year: this.year,
      pageIndex: 0,
      pageSize: 35
    });
    this.filter$.pipe(
      switchMap((filter: PlanningFilter) => this.donationSvc.getAnnualPlanning(filter))
    ).subscribe((response: any) => {
      this.donors = response.donors;
      this.donorCount = response.count;
      this.total = response.total;
      this.yearlyTotals = response.yearlyTotals;
    });
    this.addDonorControl.valueChanges.pipe(
      debounceTime(300),
      tap(() => {
        this.donorSearchResults = [];
        this.searching = true;
      }),
      switchMap((text: string) => text.length > 2?this.peopleSvc.searchAll(text):of([]))
    ).subscribe((response: any) => {
      this.donorSearchResults = response;
      this.searching = false;
    });
  }

  pageChange = (increment: number, filter: PlanningFilter) => {
    filter.pageIndex = filter.pageIndex + increment;
    this.filter$.next(filter);
  }
  updatePageSize = (pageSize: number, filter: PlanningFilter) => {
    filter.pageSize = pageSize;
    this.filter$.next(filter);
  }
  
  calculateDays = (date: string) => {
    return date?moment(date).fromNow():"";
  }
  formatDate = (date: string) => {
    return moment(date).format("lll");
  }
  
  itemDropped = (event: CdkDragDrop<any>, listData: any[]) => {
    let payload: any = {
      id: event.item.data.id,
      newRank: event.currentIndex + 1,
      currRank: event.item.data.item_rank || (event.previousIndex + 1),
      year: event.item.data.year,
      quarter: event.item.data.quarter
    }
    
    moveItemInArray(listData, event.previousIndex, event.currentIndex);
    this.donationSvc.updateAnnualPlanningRank(payload).subscribe((response: any) => {
      this.donors = response;
    });
  }
  addDonorToAnnualPlanning = (id: number, filter: PlanningFilter) => {
    this.donationSvc.addDonorToAnnualPlanning(id, filter).subscribe((response: any) => {
      this.donors = response.donors;
      this.donorCount = response.count;
    });
  }
  displayNull = () => {
    return '';
  }
  update = (update: any, id: number, filter: PlanningFilter) => {
    this.donationSvc.updateAnnualPlanningItem(update, id, filter).subscribe((response: any) => {
      this.donors = response.donors;
      this.donorCount = response.count;
    });
  }
  itemUpdated = (data: any) => {
    this.donors = data.donors;
    this.donorCount = data.count;
  }
  
  updateStaffLead = (updateValue: any, donor: any, index: number) => {
    let update: any = {
      person_id: donor.person_id,
      staff_lead: updateValue
    }
    
    this.peopleSvc.updatePerson(update).subscribe((response: any) => {
      if (response.staff_lead === updateValue) {
        donor.staff_lead = updateValue;
        this.donors[index] = donor;
      }
    });
  }
  
  removeDonor = (id: number, name: string, filter: PlanningFilter) => {
    let title: string = "Remove Donor",
        msg: string = `Are you sure you want to remove ${name} and associated notes from the Annual Planning Dashboard?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      switchMap(() => this.donationSvc.removeDonorFromAnnualPlanning(id))
    ).subscribe(() => this.filter$.next(filter));
  }
}