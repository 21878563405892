<table class="table table-hover table-sm overflow-auto">
  <thead>
    <tr>
      <th>
        <span>User</span>
        <button class="sm-icon-btn" mat-icon-button [disabled]="!authSvc.checkPermission('create_users')"
                color="primary" (click)="openDialog()">
          <mat-icon>add_circle</mat-icon>
        </button>
      </th>
      <th><span>E-mail</span></th>
      <th><span>Group</span></th>
      <th class="center-text"><span>Approval Status</span></th>
      <th class="center-text"><span>Last Login</span></th>
      <th class="center-text"><span>Total Logins</span></th>
      <th class="center-text"><span>Action</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users$ | async; let index = index;">
      <td scope="row" [routerLink]="['/user', user.user_id]" class="pointer" [class.disable-links]="!authSvc.checkPermission('view_users')">
        <span>{{user.first_name + ' ' + user.last_name}}</span>
      </td>
      <td><span>{{user.email}}</span></td>
      <td [matMenuTriggerFor]="groupMenu" class="pointer"><span>{{user.user_group}}</span></td>
      <td class="center-text">
        <span>{{user.approved === 1? "Approved":"Unapproved"}}</span>
      </td>
      <td class="center-text">
        <span *ngIf="!!user.single_use_id && !user.passwordSet">Registration Pending</span>
        <span *ngIf="user.last_login">{{user.last_login | date: 'short'}}</span>
      </td>
      <td class="center-text"><span>{{user.total_logins || 0}}</span></td>
      <td class="center-text">
        <mat-icon class="action-btn" [matMenuTriggerFor]="actionMenu"
                  [class.disable-links]="!authSvc.checkPermission('edit_users')">more_vert</mat-icon>
      </td>
      <mat-menu class="compact-menu" #actionMenu="matMenu">
        <button mat-menu-item (click)="deleteUser(user)"
                [disabled]="!authSvc.checkPermission('delete_users')">Delete User</button>
        <button *ngIf="user.approved !== 1" mat-menu-item
                (click)="activateUser(user.user_id, 1)">
          <span>Activate User</span>
        </button>
        <button *ngIf="user.approved === 1" mat-menu-item [disabled]="!authSvc.checkPermission('edit_users')"
                (click)="activateUser(user.user_id, 0)">
          <span>Deactivate User</span>
        </button>
      </mat-menu>
      <mat-menu class="compact-menu" #groupMenu="matMenu">
        <div mat-menu-item>
          <span style="font-weight: bold; text-decoration: underline;">Set Group for {{user.first_name + ' ' + user.last_name}}</span>
        </div>
        <button *ngIf="userGroups.length === 0"
                mat-menu-item>
          <span>Loading User Groups...</span>
        </button>
        <button mat-menu-item *ngFor="let group of userGroups"
                (click)="updateUserGroup(user, group, index)">
          <span>{{group}}</span>
        </button>
      </mat-menu>
    </tr>
  </tbody>
</table>