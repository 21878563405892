import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { LoginComponent } from './login/login.component';
import { NewUserComponent } from './new-user/new-user.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileDialogComponent } from './user-profile-dialog/user-profile-dialog.component';
import { UsersComponent } from './users/users.component';
import { EditUserPageComponent } from './edit-user-page/edit-user-page.component';

@NgModule({
  declarations: [
    LoginComponent,
    NewUserComponent,
    UserDialogComponent,
    UserFormComponent,
    UserProfileComponent,
    UserProfileDialogComponent,
    UsersComponent,
    EditUserPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatCheckboxModule,
  ],
  exports: [
    LoginComponent,
    UsersComponent,
    EditUserPageComponent,
  ]
})
export class AuthModule { }