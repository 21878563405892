import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { switchMap, filter } from 'rxjs/operators';

import { EmailService } from '../email.service';

@Component({
  selector: 'app-sent-item-summary',
  templateUrl: './sent-item-summary.component.html',
  styleUrls: ['./sent-item-summary.component.scss']
})
export class SentItemSummaryComponent implements OnInit {
  sentItem: any;
  itemRecipients: any[];

  constructor(private emailSvc: EmailService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(
      filter((params: ParamMap) => !!params.get('id')),
      switchMap((params: ParamMap) => this.emailSvc.getSentItemSummary(+params.get('id')))
    ).subscribe((item: any) => this.sentItem = item);
  }

}