<table class="table table-hover table-sm">
  <thead>
    <tr>
      <th>Subject</th>
      <th>Date Sent</th>
      <th>Recipients</th>
      <th>Opens</th>
      <th><mat-icon class="sm-icon">assignment</mat-icon></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of emailSvc.sentItems$ | async">
      <td><span class="ellipsis pointer" (click)="openSentItemSummary(item.id)">{{item.subject}}</span></td>
      <td><span>{{item.date_sent | date: 'short'}}</span></td>
      <td><span>{{item.recipient_count}}</span></td>
      <td><span>{{item.open_count}}</span></td>
      <td>
        <button mat-icon-button class="sm-icon-btn" [matMenuTriggerFor]="actionMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="archiveEmail(item.id)">
            <span>Archive Sent Email</span>
          </button>
          <button mat-menu-item (click)="editEmail.emit(item)">
            <span>Load Sent Email in Editor</span>
          </button>
        </mat-menu>
      </td>
    </tr>
  </tbody>
</table>