<table class="table table-hover table-sm h-100 overflow-auto">
  <thead>
    <tr>
      <th scope="col">
        Correspondence
        <button mat-icon-button
                color="primary"
                (click)="openCorrespondenceDialog()"
                class="new-table-btn">
          <mat-icon>add_circle</mat-icon>
        </button>
      </th>
      <th scope="col">Sent To</th>
      <th scope="col">Date Sent</th>
    </tr>
  </thead>
  <tbody class="overflow-auto">
    <tr *ngFor="let corr of person.correspondence">
      <td scope="row" class="pointer"><span>{{corr.title}}</span></td>
      <td><span>{{corr.envelope_title}}</span></td>
      <td>{{corr.date_sent | date: 'shortDate'}}</td>
      <td><button mat-icon-button style="height: 14px; width: 14px; line-height: 14px;" [matMenuTriggerFor]="donationActionMenu"><mat-icon style="font-size: 14px">more_vert</mat-icon></button></td>
      <mat-menu class="compact-menu" #donationActionMenu="matMenu">
        <button mat-menu-item ><mat-icon>delete</mat-icon><span>Delete Correspondence</span></button>
        <button mat-menu-item ><mat-icon>edit</mat-icon><span>Edit Correspondence</span></button>
      </mat-menu>
    </tr>
  </tbody>
</table>