import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

import { Email } from './email.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  domain: string = "https://slingofdavid.com";
  port: string = "3000";
  url: string = this.domain + ":" + this.port + "/";
  emails: Email[] = [];
  emailTemplates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  sentItems$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  sendAttempts$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) {
    this.getSentItems(0, 100).subscribe((emails: any[]) => this.sentItems$.next(emails));
  }
  
  getSendAttempts = () => {
    return this.http.get<any[]>(this.url + "email/send-attempts");
  }
  getFilteredEmails = (filter: any) => {
    return this.http.post(this.url + "email/filtered", filter);
  }
    
  getSentItems = (pageIndex: number, pageSize: number) => {
    return this.http.get<any[]>(this.url + "email/sent-items", {params: {pageIndex: pageIndex, pageSize: pageSize}});
  }
  getSentItemSummary = (id: number) => {
    return this.http.get<any>(this.url + "email/sent-item-summary", {params: {id: id}});
  }  
  archiveSentItem = (id: number) => {
    return this.http.get<any[]>(this.url + 'email/archive/' + id);
  }
  
  searchEmailRecipients = (text: string) => {
    return this.http.get(this.url + "email/search-recipients", {params: {text: text}});
  }
  sendSESEmail = (emailObj) => {
    return this.http.post(this.url + "email/send-ses", emailObj);
  }
  getEmailReport = (subject: string) => {
    return this.http.post(this.url + "email/report", {subject: subject}, {responseType: 'blob'});
  }
      
  seeThrottled = (id: number) => {
    return this.http.get(this.url + "email/fix-throttle", {params: {id: id}});
  }
  fixThrottled = (emailObj: any) => {
    return this.http.post(this.url + "email/fix-throttle", emailObj);
  }
  
  fixAWSResponses = () => {
    return this.http.get(this.url + "email/transform-aws-responses");
  }
  transformAWSResponses = (event: string) => {
    return this.http.get(this.url + "email/transform-aws-event-data", {params: {event: event}});
  }
  fixSendResults = () => {
    return this.http.get(this.url + 'email/transform-send-results');
  }
  insertMessages = () => {
    return this.http.get(this.url + "email/insert-messages");
  }
}