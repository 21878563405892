import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { filter, switchMap } from 'rxjs/operators';

import { DialogService } from '@modules/dialogs/dialog.service';
import { NotesService } from '../notes.service';

@Component({
  selector: 'app-review-dialog',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.scss']
})
export class ReviewDialogComponent implements OnInit {
  viewMode: string = 'view';
  reviewForm: FormGroup;
  review = {};
  staffOptions: any[] = ["Leslie", "Aundrea", "Michelle", "Wade", "Heather", "Catherine"];
  staffOptionsResults: any[] = this.staffOptions;

  constructor(@Inject(MAT_DIALOG_DATA) public entryData: any,
              public dialogRef: MatDialogRef<ReviewDialogComponent>,
              private fb: FormBuilder,
              private noteSvc: NotesService,
              private dialogSvc: DialogService) { }
  
  ngOnInit(): void {
    if (typeof this.entryData.viewMode !== "undefined") this.viewMode = this.entryData.viewMode;
    this.reviewForm = this.fb.group({
      review_id: [],
      title: [''],
      content: [''],
      prepared_by: [''],
      person_link: []
    });
    if (typeof this.entryData.note !== "undefined" && this.entryData.note) {
      this.review = this.entryData.note;
      this.reviewForm.patchValue(this.entryData.note);
    }
    this.reviewForm.get('prepared_by').valueChanges.subscribe((text: string) => {
      this.staffOptionsResults = text?this.staffOptions.filter((option: string) => option.includes(text)):this.staffOptions;
    });
  }
  
  save = (note: any) => {
    if (typeof note.person_link === "undefined" || !note.person_link) note.person_link = this.entryData.person.person_id;
    if (typeof note.review_id === "undefined" || !note.review_id) {
      this.noteSvc.createNote(note).subscribe((response: any) => this.dialogRef.close(true));
    } else {
      this.noteSvc.updateNote(note).subscribe((response: any) => this.dialogRef.close(true));
    }
  }
  delete = (note: any) => {
    let title: string = "Confirm Note Delete",
        msg: string = `Are you sure you want to delete the note titled '${note.title}'?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.noteSvc.deleteNote(note.review_id))
    ).subscribe((response: any) => this.dialogRef.close(true));
  }
}