import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '@services/config.service';

import { Subscription, BehaviorSubject } from 'rxjs';
import { finalize, filter, tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  url: string;
  uploadedFiles$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  dbTables = [
    {label: "Activists", table: "people"},
  ]
  schema$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  tables: string[] = [];
  
  // VALIDATION TEST VARIABLES & FUNCTIONS ********************************************
  fieldValidations = [
    {columns: [{table: "people", fields: ["prefix"]}], validation: (value) => {if (value === null) {return false;} else {return (typeof value !== "string" || value.length > 20 || /[^-a-zA-Z\.]/.test(value))}}},
    {columns: [{table: "people", fields: ["first_name", "middle_name", "last_name"]}], validation: (value) => {if (value === null) {return false;} else {return (typeof value !== "string" || value.length > 100 || /[^-a-zA-Z'\.]/.test(value))}}},
    {columns: [{table: "people", fields: ["suffix"]}], validation: (value) => {if (value === null) {return false;} else {return (typeof value !== "string" || value.length > 30 || /[^a-zA-Z, \.]/.test(value))}}},
    {columns: [{table: "people", fields: ["img_url"]}], validation: (value) => {return (typeof value !== "string" || value.length > 100)}},
    {columns: [{table: "people", fields: ["preferred_name"]}], validation: (value) => {return (typeof value !== "string" || value.length > 100)}},
    {columns: [{table: "people", fields: ["name_on_envelope"]}], validation: (value) => {return (typeof value !== "string" || value.length > 255)}},
    {columns: [{table: "addresses", fields: ["label", "city"]}], validation: (value) => {return (typeof value !== "string" || value.length > 50 || /[^a-zA-Z\ ]/.test(value))}},
    {columns: [{table: "addresses", fields: ["address", "address2"]}], validation: (value) => {return (typeof value !== "string" || value.length > 100 || /[^0-9a-zA-Z\ \.]/.test(value))}},
    {columns: [{table: "addresses", fields: ["state"]}], validation: (value) => {return (typeof value !== "string" || value.length > 20 || /[^A-Z]/.test(value))}},
    {columns: [{table: "addresses", fields: ["zip"]}], validation: (value) => {return (/[^0-9\-]/.test(value))}},
    {columns: [{table: "addresses", fields: ["contact_link"]}, {table: "people", fields: ["imported_record_id"]}], validation: (value) => {return (typeof value !== "number" || value > 2147483647)}},
    {columns: [{table: "addresses", fields: ["date_created"]}], validation: (value) => {return (new Date(value) instanceof Date && !isNaN(value))}},
    {columns: [{table: "addresses", fields: ["user_created"]}], validation: (value) => {return (typeof value !== "string" || value.length > 100)}},
    {columns: [{table: "donations", fields: ["recipient"]}], validation: (value) => {return (typeof value !== "string" || value.length > 100)}},
    {columns: [{table: "donations", fields: ["amount"]}], validation: (value) => {return (typeof value !== "number")}},
    {columns: [{table: "email_addresses", fields: ["email"]}], validation: (value) => {return value.length > 255}},
    {columns: [{table: "phones", fields: ["phone_number"]}], validation: (value) => {return (value.length > 20 || /[^0-9]/.test(value))}},
    {columns: [{table: "people", fields: ["notes"]}], validation: (value) => {return typeof value !== "string"}}
  ];
  validations = [
    {
      table: "people",
      field: "prefix",
      errors: [
        (value) => this.fitsLength(value, 20),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "first_name",
      errors: [
        (value) => this.fitsLength(value, 50),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z]`)
      ]
    },
    {
      table: "people",
      field: "middle_name",
      errors: [
        (value) => this.fitsLength(value, 50),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "last_name",
      errors: [
        (value) => this.fitsLength(value, 50),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z]`)
      ]
    },
    {
      table: "people",
      field: "suffix",
      errors: [
        (value) => this.fitsLength(value, 30),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z, \.]`)
      ]
    },
    {
      table: "people",
      field: "preferred_name",
      errors: [
        (value) => this.fitsLength(value, 50),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "name_on_envelope",
      errors: [
        (value) => this.fitsLength(value, 255),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "notes",
      errors: [
        (value) => this.fitsLength(value, 65535),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "img_url",
      errors: [
        (value) => this.fitsLength(value, 100),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "verified",
      errors: [
        (value) => this.isNumber(value)
      ],
      warnings: []
    },
    {
      table: "people",
      field: "date_verified",
      errors: [
        (value) => this.isDate(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "user_verified",
      errors: [
        (value) => this.fitsLength(value, 100),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "date_created",
      errors: [
        (value) => this.isDate(value)
      ],
      warnings: []
    },
    {
      table: "people",
      field: "user_created",
      errors: [
        (value) => this.fitsLength(value, 100),
        (value) => this.isString(value)
      ],
      warnings: [
        (value) => this.validCharacters(value, `[^a-zA-Z\.]`)
      ]
    },
    {
      table: "people",
      field: "imported_record_table",
      errors: [
        (value) => this.fitsLength(value, 100),
        (value) => this.isString(value)
      ],
      warnings: []
    },
    {
      table: "people",
      field: "imported_record_id",
      errors: [
        (value) => this.isNumber(value)
      ],
      warnings: []
    },
  ];
  
  fitsLength = (text: string, length: number) => {
    let result = (text.length <= +length),
        message = `Value is ${text.length} characters long` + (result?".":`, exceeding ${length} character limit.`);
    return {
      valid: result,
      message: message
    };
  }
  isString = (value: any) => {
    let result = typeof value === "string";
    return {
      valid: result,
      message: result?`Value is a valid string`:`Value is not a valid string`
    };
  }
  isNumber = (value: any) => {
    return {
      valid: !isNaN(value),
      message: `Value is not a valid number`
    };
  }
  isDate = (value: any) => {
    let date = new Date(value);

    return {
      valid: typeof date.getMonth === 'function',
      message: `Value is not a valid date`
    }
  }
  validCharacters = (value: any, regExp: string, failMessage?: string, passMessage?: string) => {
    let message,
    regEx: RegExp = new RegExp(regExp);

    if (regEx.test(value)) {
      message = failMessage || "Value contains invalid characters";
    } else {
      message = passMessage || "Value contains no invalid characters";
    }

    return {
      valid: !regEx.test(value),
      message: message
    }
  }
  validateRow = (row: any, fieldMap: any[], sourceTablePrimaryKey: string) => {
    let rowValidation = [];
    fieldMap.forEach(seq => {
      seq.fieldMappings.forEach(fldMap => {
        let key = fldMap.from.name,
            value = row[key],
            validationTest = this.validations.find(val => val.table === fldMap.to.TABLE_NAME && val.field === fldMap.to.COLUMN_NAME),
            result = {
              recordId: row[sourceTablePrimaryKey],
              field: key,
              valid: true,
              messages: [],
              warnings: 0,
              errors: 0
            };
        
        validationTest.errors.forEach(fn => {
          if (value) {
            let test = fn(value);
            if (!test.valid) {
              result.valid = !test.valid;
              result.messages.push({type: "error", message: test.message});
              result.errors++;
            }
          }
        });
        validationTest.warnings.forEach(fn => {
          if (value) {
            let test = fn(value);
            if (!test.valid) {
              result.valid = !test.valid;
              result.messages.push({type: "warning", message: test.message});
              result.warnings++;
            }
          }
        });
        rowValidation.push(result);
      });
    });
    row._rowValidation = rowValidation;
  }
  // **********************************************************************************
  
  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    this.schema$.subscribe(s => {
      this.tables = s.map(tbl => tbl.TABLE_NAME);
    });
    this.configSvc.settings.pipe(
      filter((settings) => settings.apiUrl),
      tap((settings) => this.url = settings.apiUrl),
      switchMap(() => this.getSchema())
    ).subscribe((sch: any[]) => this.schema$.next(sch));
  }
  
  // UTILITY FUNCTIONS ****************************************************************
  displayText = (text: string) => {
    return this.toTitleCase(text.replaceAll("_"," "));
  }
  toTitleCase = (text: string) => {
    return text.split(" ").map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(" ");
  }
  
  // UPLOAD FILES FUNCTIONS ***********************************************************
  getFileUploadList = () => {
    return this.http.get(this.url + "files/upload-list");
  }
  fileUpload = (event: any) => {
    const file: File = event.target.files[0];

    if (file) {
      let fileName = file.name;

      const formData = new FormData();
      formData.append("thumbnail", file);
      return this.http.post(this.url + "files/upload", formData, { reportProgress: true, observe: 'events' });
    }
  }
  deleteFile = (fileId: number) => {
    return this.http.delete(this.url + "imported-file/" + fileId);
  }
  
  getFileContents = (fileId: number, options: any) => {
    return this.http.get(this.url + 'files/contents/' + fileId, {params: options});
  }
  loadFile = (fileId: number, options: any) => {
    return this.http.get(this.url + 'files/load/' + fileId, {params: options});
  }

  // SCHEMA FUNCTIONS *****************************************************************
  getSchema = () => {
    return this.http.get(this.url + 'database/schema');
  }
    
  // TEMPORARY TABLE FUNCTIONS *******************************************************
  tableNameDisplay = (text: string) => {
    return this.toTitleCase(text.replaceAll("_"," "));
  }
  getTable = (tblName: string) => {
    return this.http.get(this.url + 'files/table-info/' + tblName);
  }
  getTemporaryTableContent = (fileId: number, sheetName: string, page: number, size: number, filter: any) => {
    let options = {
      sheetName: sheetName,
      tempTablePageIndex: page,
      tempTablePageSize: size
    }
    return this.http.post(this.url + `files/temporary-table/${fileId}`, filter, {params: options});
  }
  importFileIntoTemporaryTable = (fileId, options) => {
    return this.http.post(this.url + "files/temp-table/" + fileId, options);
  }
  addColumnToTempTable = (fileId, colObj, options) => {
    return this.http.post(this.url + `files/add-temp-table-column/${fileId}`, {colObj: colObj, options: options});
  }
  saveUpdatedRecords = (fileId: number, sheetName: string, updateRows: any) => {
    return this.http.put(this.url + `files/temp-table-records/${fileId}/${encodeURIComponent(sheetName)}`, {updateRows: updateRows});
  }
  markAsDuplicate = (importedListId: number, sheetName: string, rowId: number, personId: number) => {
    return this.http.put(this.url + `files/mark-as-duplicate/${importedListId}/${encodeURIComponent(sheetName)}/${rowId}`, {personId: personId});
  }
    
  // FIELD MAP FUNCTIONS *************************************************************
  getFieldMapSequenceImport = (fileId: number, sheetName: string, sequence: number) => {
    let options = {
      fileId: fileId,
      sheetName: sheetName,
      sequence: sequence
    }
    return this.http.get(this.url + `files/field-map-sequence-data`, {params: options});
  }
  saveFieldMap = (fileId: number, sheetName: string, fieldMap: any[], page?: number, size?: number) => {
    let options = {
      sheetName: sheetName,
      tempTablePageIndex: page || 0,
      tempTablePageSize: size || 0
    }
    
    return this.http.post(this.url + `files/field-map/${fileId}`, {fieldMap: fieldMap}, {params: options});
  }
  getFromFieldName = (fieldMap: any[], toTableName: string, toFieldName: string) => {
    let map = fieldMap.map(arr => arr.fieldMappings).flat(1).find(fld => fld.to.TABLE_NAME === toTableName && fld.to.COLUMN_NAME === toFieldName);
    return map.from.name;
  }
  
  // VALIDATE FUNCTIONS **************************************************************
  validate = (fileId, sheetName) => {
    return this.http.get(this.url + "files/validate-list/" + fileId + "/" + encodeURIComponent(sheetName));
  }
  validateList = (fileId, fieldMap) => {
    return this.http.post(this.url + "files/validate-list-values/" + fileId, {fieldMap: fieldMap});
  }
  saveTableUpdates = (fileId, updateArray, fieldMap) => {
    return this.http.post(this.url + "files/save-valid-updates/" + fileId, {updateArray: updateArray, fieldMap: fieldMap});
  }
  transformTemporaryTableColumnDataTypes = (fileId, fieldMap) => {
    return this.http.post(this.url + "files/transform-temporary-table-column-data-types/" + fileId, {fieldMap: fieldMap});
  }
  importIntoPrimaryDestinationTable = (fileId, primaryTableName, fieldMap) => {
    return this.http.post(this.url + "files/import-into-primary-destination-table/" + fileId + "/" + primaryTableName, {fieldMap: fieldMap});
  }
  importIntoSecondaryTables = (fileId, primaryTableName, fieldMap) => {
    return this.http.post(this.url + "files/import-into-secondary-tables/" + fileId + "/" + primaryTableName, {fieldMap: fieldMap});
  }
  
  // DUPLICATE FUNCTIONS *************************************************************
  getDuplicateRows = (fileId: number) => {
    return this.http.get(this.url + "files/duplicates/" + fileId);
  }
  getDuplicateMatches = (values: any) => {
    return this.http.get(this.url + "files/duplicate-matches?" + Object.keys(values).map(fld => encodeURIComponent(fld) + "=" + encodeURIComponent(values[fld])).join("&"));
  }
  
  // IMPORT FILE FUNCTIONS ***********************************************************
  importFile = (fileId, sheetName) => {
    // fields: string[], fieldMap: {from: string, to: string}, data: any[]
    return this.http.get(this.url + "files/import-list/" + fileId + "/" + encodeURIComponent(sheetName));
  }
  importDistinctFile = (fileId, sheetName) => {
    return this.http.get(this.url + `files/import-distinct-table/${fileId}/${encodeURIComponent(sheetName)}`);
  }
  importSecondaryTables = (fileId, sheetName) => {
    return this.http.get(this.url + "files/import-secondary-tables/" + fileId + "/" + encodeURIComponent(sheetName));
  }
    
  // FILE PREP FUNCTIONS *************************************************************
  scanForBadChars = (fileId: number) => {
    return this.http.get(this.url + "files/scan-for-bad-chars/" + fileId);
  }
  replaceCsvFileRow = (fileId, rowIndex, newRow) => {
    return this.http.post(this.url + "files/replace-csv-file-row", {fileId: fileId, rowIndex: rowIndex, newRow: newRow});
  }
  replaceAllNonStandardCharacters = (fileId) => {
    return this.http.get(this.url + "files/replace-all-non-standard-chars/" + fileId);
  }
}