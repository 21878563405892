import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { switchMap, filter, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment';

import { PeopleService } from '@modules/person-profiles/people.service';
import { ContactLogService, ContactLogFilter } from '../contact-log.service';

@Component({
  selector: 'app-solicitor-list',
  templateUrl: './solicitor-list.component.html',
  styleUrls: ['./solicitor-list.component.scss']
})
export class SolicitorListComponent implements OnInit{
  @Input('solicitor') solicitorInput: any;
  @Input('date-filter') dateFilter$: ReplaySubject<any> = new ReplaySubject<any>();
  solicitor$: ReplaySubject<any> = new ReplaySubject<any>();
  filter$: BehaviorSubject<ContactLogFilter> = new BehaviorSubject<ContactLogFilter>({
    pageSize: 10,
    pageIndex: 0,
    orderBy: [{field: "contacted_by", order: "DESC"}],
    startDate: moment().format('YYYY-MM-DD'),
    endDate: ''
  });
  items: any[] = [];
  totalRecords: number = 0;

  constructor(private peopleSvc: PeopleService,
              private contactLogSvc: ContactLogService) {}
  
  ngOnInit() {
    this.solicitor$.pipe(
      filter((solicitor: any) => solicitor),
      withLatestFrom(this.filter$)
    ).subscribe(([solicitor, filter]: [any, ContactLogFilter]) => {
      console.log("FILTER: ", filter);
      filter.solicitors = [solicitor];
      this.filter$.next(filter);
    });
    this.dateFilter$.pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([dateFilter, filter]: [any, any]) => {
      console.log("DATE FILTER: ", dateFilter);
      filter.startDate = dateFilter.startDate || '';
      filter.endDate = dateFilter.endDate || '';
      this.filter$.next(filter);
    });
    this.filter$.pipe(
      switchMap((filter: any) => this.contactLogSvc.getContactLog(filter))
    ).subscribe((response: any) => {
      this.items = response.items;
      this.totalRecords = response.count;
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log("CHANGES: ", changes);
    this.solicitor$.next(changes.solicitorInput.currentValue);
  }
}