import { Injectable } from '@angular/core';

import { mergeMap, filter } from 'rxjs/operators';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ActionDialogComponent } from '@modules/action-manager/action-dialog/action-dialog.component';
import { DonationDialogComponent } from '@modules/donor-management/donation-dialog/donation-dialog.component';
import { CorrespondenceDialogComponent } from '@modules/correspondence-manager/correspondence-dialog/correspondence-dialog.component';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ReviewDialogComponent } from '@modules/activist-notes/review-dialog/review-dialog.component';
import { ContactLogDialogComponent } from '@modules/contact-logs/contact-log-dialog/contact-log-dialog.component';
import { TemporaryTableDialogComponent } from '@modules/file-importer/temporary-table-dialog/temporary-table-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }
  
  confirmDialog = (title: string, message: string) => {
    let dialogRef: MatDialogRef<ConfirmDialogComponent, boolean> = this.dialog.open(ConfirmDialogComponent, {data: {title: title, message: message}});
    
    return dialogRef.afterClosed().pipe(
      filter((response: any) => response)
    );
  }
  
  donationDialog = (viewMode, person?, donation?) => {
    let dialogDataObj = {person: null, viewMode: viewMode, donation: null}
    if (typeof donation !== "undefined") dialogDataObj.donation = donation;
    if (typeof person !== "undefined") dialogDataObj.person = person;
    
    let dialogRef: MatDialogRef<DonationDialogComponent, any> = this.dialog.open(DonationDialogComponent, {data: dialogDataObj});
    return dialogRef.afterClosed();
    // return Observable<Person>
  }
  reviewDialog = (viewMode: string, inputs: any) => {
    let dialogDataObj = {person: null, viewMode: viewMode, review: null};
    if (typeof inputs.review !== "undefined") dialogDataObj.review = inputs.review;
    if (typeof inputs.person !== "undefined") dialogDataObj.person = inputs.person;
    
    let dialogRef: MatDialogRef<ReviewDialogComponent, any> = this.dialog.open(ReviewDialogComponent, {data: dialogDataObj});
    return dialogRef.afterClosed();
  }
  contactLogDialog = (viewMode: string, inputs: any) => {
    let dialogDataObj: any = {person: null, viewMode: viewMode, contactLog: null};
    if (typeof inputs.contactLog !== "undefined") dialogDataObj.contactLog = inputs.contactLog;
    if (typeof inputs.person !== "undefined") dialogDataObj.person = inputs.person;
    
    let dialogRef: MatDialogRef<ContactLogDialogComponent, any> = this.dialog.open(ContactLogDialogComponent, {data: dialogDataObj});
    return dialogRef.afterClosed();
  }
  actionDialog = (viewMode, person?, action?) => {
    let dialogDataObj: any = {person: null, viewMode: viewMode, action: null};
    if (typeof action !== "undefined") dialogDataObj.action = action;
    if (typeof person !== "undefined") dialogDataObj.person = person;
    
    let dialogRef: MatDialogRef<ActionDialogComponent, any> = this.dialog.open(ActionDialogComponent, {data: dialogDataObj});
    return dialogRef.afterClosed();
  }
  correspondenceDialog = () => {
    let dialogRef: MatDialogRef<CorrespondenceDialogComponent, any> = this.dialog.open(CorrespondenceDialogComponent, {});
    return dialogRef.afterClosed();
  }
  temporaryTableDialog = (dataObj) => {
    let dialogRef: MatDialogRef<TemporaryTableDialogComponent, any> = this.dialog.open(TemporaryTableDialogComponent, {data: {recordObj: dataObj}});
    return dialogRef.afterClosed();
  }
}