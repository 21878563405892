import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';

import { Subject, BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as moment from "moment";

import { RegionService } from '@services/region.service';
import { ListService } from '@modules/list-management/list.service';
import { PeopleService } from '../people.service';
import { UtilityService } from '@services/utility.service';
import { ConfigService } from '@services/config.service';

export class Filter {
  list: any = {};
  states: any[] = [];
  pageIndex: number = 0;
  pageSize: number = 40;
  orderBy: string = "LOWER(people.last_name), LOWER(people.first_name)"
}

@Component({
  selector: 'app-person-filter-list',
  templateUrl: './person-filter-list.component.html',
  styleUrls: ['./person-filter-list.component.scss']
})
export class PersonFilterListComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  listItems$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  stateFilter$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  listFilter$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>(new Filter());
  pageSizeOptions: number[] = [5,10,15,20,25,30,35,40,45,50];
  selectedIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  loading: boolean = false;
  totalRecords$: Subject<number> = new Subject<number>();
  totalPages: number = 0;
  // list, state, verified, muted, has email, has phone number
  @Output('person-selected') personSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(public regionSvc: RegionService,
              public listSvc: ListService,
              private peopleSvc: PeopleService,
              private utilitySvc: UtilityService,
              public configSvc: ConfigService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(
      filter((params: ParamMap) => params.get('filterPage') && typeof +params.get('filterPage') === 'number'),
      withLatestFrom(this.filter$)
    ).subscribe(([params, filter]: [ParamMap, Filter]) => {
      filter.pageIndex = +params.get('filterPage') - 1;
      this.filter$.next(filter);
    });
    this.filter$.pipe(
      tap(() => this.loading = true),
      switchMap((filter: any) => this.peopleSvc.getFilteredPeople(filter)),
      withLatestFrom(this.filter$, this.selectedIndex$)
    ).subscribe(([response, filter, index]: [any, any, number]) => {
      console.log("INDEX WHEN FILTER UPDATES: ", index);
      this.loading = false;
      this.listItems$.next(response.items);
      this.totalRecords$.next(response.count);
      if (filter.currentIndex) this.selectedIndex$.next(index);
      if (typeof filter.itemIndex !== "undefined") this.selectedIndex$.next(filter.itemIndex);
    });
    this.totalRecords$.pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([totalRecords, filter]: [number, any]) => {
      this.totalPages = Math.ceil(totalRecords / filter.pageSize);
    });
    this.selectedIndex$.pipe(
      tap((index: number) => console.log("SELECTED INDEX POST DELETE: ", index)),
      filter((selectedIndex: number) => typeof selectedIndex !== "undefined"),
      withLatestFrom(this.listItems$, this.person$),
      filter(([index, listItems, person]: [number, any[], any]) => {
        let i: number = (listItems || []).findIndex((p: any) => p.person_id === person.person_id);
        
        return index > -1 && index !== i && typeof listItems[index] !== "undefined";
      })
    ).subscribe(([selectedIndex, listItems, person]: [number, any[], any]) => {
      let id: number = listItems[selectedIndex].person_id;
      
      console.log("NAVIGATION ID: ", id);
      this.router.navigate(['activists', id]);
    });
    combineLatest(this.person$, this.listItems$).subscribe(([person, listItems]: [any, any[]]) => {
      let index: number = (listItems || []).findIndex((p: any) => person.person_id === p.person_id);
      
      this.selectedIndex$.next(index);
    });
  }
    
  toggleState = (state: string, filter: any) => {
    if (!filter.states.includes(state)) {
      filter.states.push(state);
    } else {
      filter.states = filter.states.filter((st: any) => st !== state);
    }
    
    filter.pageIndex = 0;
    this.filter$.next(filter);
  }
  showAll = (filter: any) => {
    this.listItems$.next([]);
    this.loading = true;
    if (filter.list.list_id === -1) {
      filter.list = {};
    } else {
      filter.list = {list_id: -1, list: "All Entries"}
    }
    
    this.filter$.next(filter);
  }
  toggleList = (list: any, filter: any) => {
    let input = (list.list_id !== filter.list) ? list : {};
    
    this.listItems$.next([]);
    this.loading = true;
    filter.pageIndex = 0;
    filter.list = input;
    this.filter$.next(filter);
  }
  
  refreshList = () => {
    let filter: any = this.filter$.getValue();
    
    this.filter$.next(filter);
  }
  selectCurrentIndexRow = () => {
    let index: number = this.selectedIndex$.getValue();
    
    this.selectedIndex$.next(index);
  }
  clearFilter = () => {
    this.filter$.next(new Filter());
  }
  stateFilterDisplay = (stateFilter: any[]) => {
    return stateFilter.sort((a,b) => a>b?1:-1).join(", ");
  } 
  
  changePage = (filter: any, pageIncrement: number, itemIndex: number) => {
    const queryParams: Params = {filterPage: (filter.pageIndex + 1) + pageIncrement};

    this.router.navigate([], {relativeTo: this.route, queryParams, queryParamsHandling: 'merge'});
  }
  scroll = (event: any, index: number, filter: any) => {
    let increment: number = event.deltaY > 0?1:-1, newIndex: number;
    
    if (!(index >= filter.pageSize - 1 && increment === 1) && !(index === 0 && increment === -1)) {
      if (typeof index === "undefined") {
        newIndex = increment;
      } else if (index === -1) {
        newIndex = 0;
      } else {
        newIndex = index + increment;
      }
      this.selectedIndex$.next(newIndex);
    } else if (index === filter.pageSize - 1 && increment === 1 && filter.pageIndex < this.totalPages) {
      this.changePage(filter, 1, 0);
    } else if (filter.pageIndex > 0 && index === 0 && increment === -1) {
      this.changePage(filter, -1, filter.pageSize - 1);
    }
  }
  scrollPage = (event: any, filter: any) => {
    let increment = event.deltaY > 0?1:-1;
    
    if (!(filter.pageIndex === 0 && increment === -1) && !(filter.pageIndex + 1 === this.totalPages && increment === 1)) {
      this.changePage(filter, increment, 0);
    }
  }
  changePageSize = (filter: any, pageSize: number) => {
    filter.pageSize = pageSize;
    this.filter$.next(filter);
  }
  
  getPersonName = (personObj: any) => {
    if (personObj.first_name && personObj.last_name) return personObj.first_name + " " + personObj.last_name;
    if (personObj.first_name) return personObj.first_name;
    return personObj.last_name;
  }
  
  downloadCallList = () => {
    this.listSvc.downloadCallList().subscribe((blob: Blob) => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = "Call List - " + moment().format("YYYY-MM-DD") + ".xlsx";
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  downloadEmailList = () => {
    this.listSvc.downloadEmailList().subscribe((blob: Blob) => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = "Email Notes List - " + moment().format("YYYY-MM-DD") + ".xlsx";
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  downloadFilterList = (filter: any) => {
    this.listSvc.downloadFilterList(filter).subscribe((blob: Blob) => {
      let fileName = (filter.list.list || "All Contacts") + (filter.states.length?" - " + filter.states.join(" "):"") + " - " + moment().format("YYYY-MM-DD HH-mm-ss") + ".xlsx"
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  downloadDonorReport = () => {
    this.listSvc.downloadDonorReport().subscribe((blob: Blob) => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = "1K Donors - " + moment().format("YYYY-MM-DD") + ".xlsx";
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  downloadNoStateReport = () => {
    this.listSvc.downloadNoStateReport().subscribe((blob: Blob) => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = "No State Report - " + moment().format("YYYY-MM-DD") + ".xlsx";
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
}