import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DonationsService } from '../donations.service';

@Component({
  selector: 'app-donor-dashboard',
  templateUrl: './donor-dashboard.component.html',
  styleUrls: ['./donor-dashboard.component.scss']
})
export class DonorDashboardComponent implements OnInit {
  filterForm: FormGroup = this.fb.group({
    name: [],
    start: [],
    end: [],
    min: [],
    max: [],
    state: [],
    pageSize: [25],
    pageIndex: [0]
  });
  donorData: any[] = [];
  totalRows: number = 0;
  totalPages: number = 0;

  constructor(private fb: FormBuilder,
              private donationSvc: DonationsService) { }

  ngOnInit(): void {
    this.filterForm.valueChanges.pipe(
      switchMap((filter: any) => this.donationSvc.getFilteredDonors(filter))
    ).subscribe((response: any) => {
      this.donorData = response.donors;
      this.totalRows = response.count;
      this.totalPages = Math.ceil(this.totalRows / this.filterForm.get('pageSize').value);
    });
  }
  
  changePage = (increment: number) => {
    let page: number = this.filterForm.get('pageIndex').value + increment;
    
    this.filterForm.patchValue({pageIndex: page});
  }
}