<div>
  <div>
    <span class="bold">SUBJECT: </span>
    <span>{{sentItem.subject}}</span>
  </div>
  <div>
    <span class="bold">DATE SENT: </span>
    <span>{{sentItem.date_sent}}</span>
  </div>
  <div>
    <span class="bold">TOTAL REQUESTS SENT: </span>
    <span>{{sentItem.sendResultsCount}}</span>
  </div>
  <div>
    <span class="bold">TOTAL MESSAGES SENT: </span>
    <span>{{sentItem.messageCount}}</span>
  </div>
  <div>
    <span class="bold underline">EMAIL RECIPIENTS</span>
  </div>
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Received</th>
        <th>Opens</th>
        <th>Last Open</th>
        <th>Clicks</th>
        <th>Last Click</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of itemRecipients">
        <td><span>{{row.first_name + ' ' + row.last_name}}</span></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>

<p>{{sentItem | json}}</p>