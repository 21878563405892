import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface OrderBy {
  field: string;
  order?: string;
}

export interface ContactLogFilter {
  pageSize: number;
  pageIndex: number;
  orderBy: OrderBy[];
  contactLink?: number;
  solicitors?: any[];
  startDate?: string;
  endDate?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContactLogService {
  url: string = "https://slingofdavid.com:3000"

  constructor(private http: HttpClient) { }
  
  getContactLog = (filter: any) => {
    return this.http.post(this.url + "/contact-log/filter", filter);
  }
  create = (contactLog: any) => {
    return this.http.post(this.url + "/contact-log", contactLog);
  }
  update = (contactLog: any) => {
    return this.http.put(this.url + "/contact-log", contactLog);
  }
  delete = (id: number) => {
    return this.http.delete(this.url + "/contact-log", {params: {id: id}});
  }
}