import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as moment from "moment";

import { FilesService } from '../files.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { DonationsService } from '@modules/donor-management/donations.service';
import { Person } from '@modules/person-profiles/person.model';

@Component({
  selector: 'app-import-duplicate-display',
  templateUrl: './import-duplicate-display.component.html',
  styleUrls: ['./import-duplicate-display.component.scss']
})
export class ImportDuplicateDisplayComponent implements OnInit {
  @Input('duplicate-row') duplicateRow: BehaviorSubject<any> = new BehaviorSubject<any>(new Person());
  @Input('import-sequence') importSequence: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Input('selected-person') selectedPerson$: BehaviorSubject<Person> = new BehaviorSubject<Person>(new Person());
  @Input('imported-list-id') importedListId: number;
  @Input('sheet-name') sheetName: string;
  @Output('updated-import-row') updatedImportRow: EventEmitter<any> = new EventEmitter<any>();
  @Output('updated-person') updatedPerson: EventEmitter<any> = new EventEmitter<any>();
  @Output('updated-duplicate-row') updatedDuplicateRow: EventEmitter<any> = new EventEmitter<any>();
  @Output('scroll-event') scrollEvent: EventEmitter<any> = new EventEmitter<any>();
  rowKeys: any[] = [];
  formGroupObject: any = {};
  importDuplicateForm: FormGroup;
  removeFields: string[] = ["_unsaved", "_rowValidation", "_selected", "_saving"];
  tableArray: string[];
  fieldMapArray: any[];
  fieldFilter = ["destination_table_primary_key"];
  
  constructor(private fb: FormBuilder,
              public fileSvc: FilesService,
              private peopleSvc: PeopleService,
              private donationsSvc: DonationsService) { }

  ngOnInit(): void {
    this.duplicateRow.pipe(
      filter(row => row)
    ).subscribe((row: any) => {
      this.rowKeys = Object.keys(row).filter(key => this.removeFields.indexOf(key) === -1);
      this.rowKeys.forEach(key => {
        this.formGroupObject[key] = [row[key]];
      });
      this.importDuplicateForm = this.fb.group(this.formGroupObject);
    });
    this.importSequence.subscribe((seq: any[]) => {
      this.tableArray = seq.map(imp => imp.fieldMappings[0].to.TABLE_NAME).filter((el, i, arr) => arr.indexOf(el) === i);
      this.fieldMapArray = seq.map(s => s.fieldMappings).flat(1);
    });
  }
  
  createObject = (key: string, value: any) => {
    let obj: any = {};
    obj[key] = value;
    return obj;
  }
  
  checkValueAgainstList = (dupFieldArray: any[], key: string, value: any) => {
    if(typeof dupFieldArray !== "undefined") return dupFieldArray.some(fld => fld[key] === value);
    return true;
  }
  checkDonations = (donationsArray: any[], importStep: any, row: any) => {
    let amountPledgedFieldMap: any = importStep.find(step => step.to.COLUMN_NAME === "amount_pledged"),
        amountPledgedField = amountPledgedFieldMap.from.name,
        dateFieldMap: any = importStep.find(step => step.to.COLUMN_NAME === "date"),
        datePledgedField: string = (typeof dateFieldMap !== "undefined")?dateFieldMap.from.name:"";
    
    if (typeof donationsArray !== "undefined" && !datePledgedField) {
      return donationsArray.some(don => {
        let person_date: string = new Date(don.date).toString(),
            row_date: string = new Date(this.displayValue(dateFieldMap, row[datePledgedField])).toString();
        
        return (+don.amount_pledged === +row[amountPledgedField] && person_date === row_date);
      });
    }
    return false;
  }
  
  displayValue = (fieldMap: any, value: any) => {
    if (!value) return "";
    if (fieldMap.to.DATA_TYPE === "datetime") {
      if (true) {
        return this.excelDateToJSDate(value).toLocaleDateString('en-US', {timeZone: "America/New_York"});
      } else {
        return new Date(value);
      }
    }
    return value;
  }
  excelDateToJSDate = (serial) => {
   let utc_days  = Math.floor(serial - 25568),
       utc_value = utc_days * 86400,
       date_info = new Date(utc_value * 1000),
       fractional_day = serial - Math.floor(serial) + 0.0000001,
       total_seconds = Math.floor(86400 * fractional_day),
       seconds = total_seconds % 60;
    
    total_seconds -= seconds;

    let hours = Math.floor(total_seconds / (60 * 60)),
        minutes = Math.floor(total_seconds / 60) % 60;
    
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }
  
  getFromField = (fieldMap: any[], toTable: string, toField: string) => {
    return fieldMap.find(fld => fld.to.TABLE_NAME === toTable && fld.to.COLUMN_NAME === toField).from.name;
  }
  getTableFieldMappings = (table: string) => {
    return this.fieldMapArray.filter(fld => fld.to.TABLE_NAME === table).filter(fld => this.fieldFilter.indexOf(fld.from.name) === -1);
  }
  getTableImportSequences = (table: string) => {
    let importSeq = this.importSequence.getValue(),
        arr = importSeq.filter(imp => imp.fieldMappings.some(fld => fld.to.TABLE_NAME === table));
    
    arr = arr.map(seq => seq.fieldMappings);
    return arr;
  }
  
  markAsDuplicate = (row: any) => {
    console.log("Imported Rows: ", row._import_rows)
    if (typeof row.sling_imported_record_id === "string") {
      console.log(row.sling_imported_record_id.split(";"));
    } else {
      console.log(row.sling_imported_record_id);
    }
    /* let person;
    this.selectedPerson$.subscribe(pers => person = pers);
    this.fileSvc.markAsDuplicate(this.importedListId, this.sheetName, rowId, person.person_id).subscribe((response: any) => {
      this.updatedDuplicateRow.emit(response.row.data[0]);
    }); */
  }
  
  // PERSON FUNCTIONS ***************************************************************************************
  updatePersonField = (field: string, value: any) => {
    let person = this.selectedPerson$.getValue(),
    updatedPersonObj = {person_id: person.person_id};
    updatedPersonObj[field] = value;
    
    this.peopleSvc.updatePerson(updatedPersonObj).subscribe((response: any) => {
      this.updatedPerson.emit(response.updatedPerson);
    });
  }
  addToNotes = (value: string) => {
    let person = this.selectedPerson$.getValue(),
        updatedPersonObj = {
          person_id: person.person_id,
          notes: person.notes + "; " + value
        };
    
    this.peopleSvc.updatePerson(updatedPersonObj).subscribe((response: any) => {
      this.updatedPerson.emit(response.updatedPerson);
    });
  }
  replaceNotes = (value: string) => {
    let person = this.selectedPerson$.getValue(),
        updatedPersonObj = {
          person_id: person.person_id,
          notes: value
        };
    
    this.peopleSvc.updatePerson(updatedPersonObj).subscribe((response: any) => {
      this.updatedPerson.emit(response.updatedPerson);
    });
  }
  
  // DONATION FUNCTIONS *************************************************************************************
  addDonation = (sequence: any) => {
    let person = this.selectedPerson$.getValue(),
        row = this.duplicateRow.getValue(),
        donationObj: any = {
          date: moment(this.excelDateToJSDate(row[this.getFromField(sequence, "donations", "date")])).format("YYYY-MM-DD"),
          amount_pledged: row[this.getFromField(sequence, "donations", "amount_pledged")],
          contact_link: person.person_id
        };
    
    this.donationsSvc.save(donationObj).subscribe((response: any) => {
      let person = this.selectedPerson$.getValue();
      
      person.donations.push(response);
      this.updatedPerson.emit(person);
    });
  }
  
  // EMAIL FUNCTIONS ****************************************************************************************
  addEmailAddress = (emailAddressObj: any) => {
    let person = this.selectedPerson$.getValue();
    
    emailAddressObj.contact_link = emailAddressObj.contact_link || person.person_id;
    this.peopleSvc.addEmailAddress(emailAddressObj).subscribe((response: any) => this.updatedPerson.emit(response));
  }
  
  // PHONE FUNCTIONS ****************************************************************************************
  addPhone = (phoneObj: any, personId: number) => {
    let person = this.selectedPerson$.getValue();
    
    phoneObj.contact_link = phoneObj.contact_link || personId || person.person_id;
    this.peopleSvc.addPhoneNumber(phoneObj).subscribe((response: any) => this.updatedPerson.emit(response));
  }
  
  // ADDRESS FUNCTIONS **************************************************************************************
  addAddress = (fieldMap: any, row: any, personId: number) => {
    let addressObj: any = {
      contact_link: personId,
      address: row[this.getFromField(fieldMap, "addresses", "address")],
      address2: row[this.getFromField(fieldMap, "addresses", "address2")],
      city: row[this.getFromField(fieldMap, "addresses", "city")],
      state: row[this.getFromField(fieldMap, "addresses", "state")],
      zip: row[this.getFromField(fieldMap, "addresses", "zip")]
    }
    
    this.peopleSvc.addAddress(addressObj).subscribe((response: any) => this.updatedPerson.emit(response));
  }
  updateAddress = (addressId: number, addressObj: any) => {
    let person = this.selectedPerson$.getValue(),
        index = person.addresses.findIndex(add => add.address_id === addressId);
    
    this.peopleSvc.updateAddress(addressObj).subscribe((response: any) => {
      person.addresses.splice(index, 1, response.updatedAddress);
      this.updatedPerson.emit(person);
    });
  }
  getFullAddress = (address: any) => {
    return address.address + (address.address2?", " + address.address2:"") + (address.city?", " + address.city:"") + (address.state?", " + address.state:"") + (address.zip?" " + address.zip:"")
  }
  
  getObjectKeys = (obj: any) => {
    return Object.keys(obj);
  }
}