<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th>Title</th>
            <th>Addressee</th>
            <th>Recipients</th>
            <th>Status</th>
            <th>Date Sent</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let corr of correspondence | async">
            <td>{{corr.title}}</td>
            <td>{{corr.envelope_title}}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>