import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CorrespondenceService {
  domain: string = 'http://slingofdavid.com';
  port: string = '3000';
  url: string = this.domain + ':' + this.port + '/';

  constructor(private http: HttpClient) { }
  
  createCorrespondence = (correspondenceObj, linkObj) => {
    return this.http.post(this.url + 'correspondence', {correspondenceObj: correspondenceObj, linkObj: linkObj});
  }
}