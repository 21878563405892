<div fxFlexFill fxLayout="row" fxLayoutGap="10px"
     *ngIf="activeFile$ | async as activeFile">
  <ng-container *ngIf="activeWorksheet$ | async as activeWorksheet">
    <div fxFlex="20" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center">
        <input type="file" style="display: none;" #fileUpload (change)="uploadFile($event)">
        <mat-form-field fxFlex>
          <mat-label>Search Uploaded Files</mat-label>
          <input type="text" matInput [matAutocomplete]="fileSearchAuto">
        </mat-form-field>
        <mat-autocomplete #fileSearchAuto="matAutocomplete" [displayWith]="displayNull"
                          (optionSelected)="activeFile$.next($event.option.value)">
          <mat-option *ngFor="let fileSearchResult of fileSearchResults"
                      [title]="fileSearchResult.name" [value]="fileSearchResult">
            <span>{{fileSearchResult.name}}</span>
          </mat-option>
        </mat-autocomplete>
        <button mat-icon-button color="primary" (click)="fileUpload.click()">
          <mat-icon>upload</mat-icon>
        </button>
      </div>
      <div>
        <ul>
          <li *ngFor="let worksheet of activeFile.sheets" (click)="activeWorksheet$.next(worksheet)"
              [ngClass]="{'selected': worksheet.id === activeWorksheet.id}">
            <span>{{worksheet.sheet_name}}</span>
          </li>
        </ul>
      </div>
      <div class="selected-item-container">
        <div>
          <span class="bold">Active File: </span>
          <span>{{activeFile.name}}</span>
        </div>
        <div>
          <span class="bold">Active Worksheet: </span>
          <span>{{activeWorksheet.sheet_name}}</span>
        </div>
      </div>
      <ul>
        <li fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px"
            *ngFor="let actionStep of importActionSteps">
          <mat-icon style="font-size: 18px; height: 18px; width: 18px;">{{actionStep.complete?'check_box':'check_box_outline_blank'}}</mat-icon>
          <span fxFlex class="ellipsis" [title]="actionStep.name">{{actionStep.name}}</span>
        </li>
      </ul>
    </div>
    <div fxFlex fxLayout="row">
      <div fxFlex="25">
        <div>
          <span class="header">Spreadsheet Column Headers</span>
        </div>
        <ul>
          <li *ngFor="let spreadsheetColumnHeader of spreadsheetColumnHeaders">
            <span>{{spreadsheetColumnHeader}}</span>
          </li>
        </ul>
      </div>
      <div fxFlex="25">
        <div>
          <span class="header">Imported Table Field Names</span>
        </div>
        <ul>
          <li *ngFor="let tempTableField of tempTableFields">
            <span></span>
          </li>
        </ul>
      </div>
      <div fxFlex="25">
        <div>
          <span class="header">Destination Table Fields</span>
        </div>
        <div>
          <div *ngFor="let destinationTableOption of destinationTableOptions">
            <span (click)="toggleTable(destinationTableOption, tableDisplay)"
                  class="bold pointer">{{destinationTableOption.label}}</span>
            <div *ngIf="destinationTableOption.tableName === tableDisplay.tableName">
              <div *ngFor="let column of destinationTableOption.columns">
                <span>{{column.COLUMN_NAME}}</span>
              </div>
            </div>
          </div>
        </div>
        <ul>
          <li *ngFor="let destinationTableField of destinationTableFields">
            <span></span>
          </li>
        </ul>
      </div>
      <div fxFlex="25">
        <div>
          <span class="header">Import Field Mappings</span>
        </div>
        <ul fxFlex="33">
          <li *ngFor="let importedFileFieldMapping of importedFileFieldMappings">
            <span></span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>