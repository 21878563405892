<ng-container *ngIf="donorFilter$ | async as donorFilter">
  <div id="menu-banner">
    <app-pagination item-name="Donors" [total-records]="totalRecords"
                    [page-index]="donorFilter.pageIndex" [page-size]="donorFilter.pageSize"
                    (change-page-size)="changePageSize($event, donorFilter, donorFilter$)"
                    (page-increment)="changePage(donorFilter, $event, donorFilter$)"
                    ></app-pagination>
  </div>
  <div id="table-container">
    <table>
      <thead>
        <tr>
          <th class="first-col" rowspan="2"><span>Donor</span></th>
          <th rowspan="2"><span>Total 2024 Gift</span></th>
          <th rowspan="2"></th>
          <ng-container *ngFor="let col of cellRemainder(0); let index = index">
            <th colspan="2"><span>Gift {{index + 1}}</span></th>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let col of cellRemainder(0)">
            <th style="text-align: center;"><span>Amount</span></th>
            <th style="text-align: center;"><span>Date</span></th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="first-col"><span>Total</span></td>
          <td>{{totalDonations | currency}}</td>
          <td></td>
          <ng-container *ngFor="let col of cellRemainder(0)">
            <td></td>
            <td></td>
          </ng-container>
        </tr>
        <tr *ngFor="let donor of donors">
          <td class="first-col"><span>{{donor.first_name + " " + donor.last_name}}</span></td>
          <td><span>{{donor.total_paid}}</span></td>
          <td></td>
          <ng-container *ngFor="let donation of donor.donations">
            <td style="text-align: right;"><span>{{donation.amount_paid | currency}}</span></td>
            <td style="text-align: center;"><span>{{donation.date | date: 'shortDate'}}</span></td>
          </ng-container>
          <ng-container *ngFor="let cell of cellRemainder((donor.donations || []).length)">
            <td></td>
            <td></td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>