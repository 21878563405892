<div class="selector-row">
  <div *ngFor="let option of viewModeOptions"
       class="selector" [ngClass]="{'selected': option.value === viewMode}"
       (click)="setViewMode(option.value)">
    <span>{{option.label}}</span>
  </div>
  <div *ngIf="authSvc.getCurrentUserName() === 'Michael Duffey'"
       class="selector" [ngClass]="{'selected': viewMode === 'results'}"
       (click)="setViewMode('results')">
    <span>Results</span>
  </div>
</div>
<div fxFlex style="overflow: none;">
  <app-email-editor *ngIf="viewMode === 'editor'" [email-update]="email$"></app-email-editor>
  <app-sent-items *ngIf="viewMode === 'sent_items'" (edit-email)="updateEmail($event)"></app-sent-items>
  <app-send-attempts *ngIf="viewMode === 'send_attempts'" (edit-email)="updateEmail($event)"></app-send-attempts>
  <app-email-results *ngIf="viewMode === 'results'"></app-email-results>
  <app-sent-item-summary *ngIf="viewMode === 'summary'"></app-sent-item-summary>
</div>