<div class="dialog-header" fxLayout="row" fxLayoutAlign="start center">
  <span class="dialog-title" fxFlex>Contact Log</span>
  <button mat-icon-button *ngIf="viewMode === 'view'"
          [matMenuTriggerFor]="dialogActionMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #dialogActionMenu="matMenu">
    <button mat-menu-item (click)="viewMode = 'edit'">
      <span>Edit Note</span>
    </button>
    <button mat-menu-item (click)="delete(contactLog)">
      <span>Delete Note</span>
    </button>
  </mat-menu>
</div>
<div mat-dialog-content>
  <div *ngIf="viewMode === 'edit'"
        [formGroup]="contactLogForm" autocomplete="off">
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" name="title" [matAutocomplete]="titleMenu">
      </mat-form-field>
      <mat-autocomplete #titleMenu>
        <mat-option *ngFor="let title of titleOptions" [value]="title">{{title}}</mat-option>
      </mat-autocomplete>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <mat-label>Contact Date</mat-label>
        <input matInput [matDatepicker]="contact" formControlName="contact_datetime">
        <mat-datepicker-toggle matSuffix [for]="contact"></mat-datepicker-toggle>
        <mat-datepicker #contact></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Contact Type</mat-label>
        <input matInput type="text" formControlName="contact_type" [matAutocomplete]="typeMenu">
      </mat-form-field>
      <mat-autocomplete #typeMenu>
        <mat-option *ngFor="let typ of typeOptions" [value]="typ">{{typ}}</mat-option>
      </mat-autocomplete>
    </div>
    <div>
      <mat-form-field style="width: 100%; margin-right: 5px;">
        <mat-label>Contacted By</mat-label>
        <input matInput type="text" name="contacted_by" formControlName="contacted_by">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Status</mat-label>
        <input matInput type="text" name="status" formControlName="status" [matAutocomplete]="statusAuto">
      </mat-form-field>
      <mat-autocomplete #statusAuto="matAutocomplete">
        <mat-option *ngFor="let opt of statusOptions" [value]="opt"><span>{{opt}}</span></mat-option>
      </mat-autocomplete>
    </div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Date Completed</mat-label>
        <input matInput type="text" name="date_completed" formControlName="date_completed">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Notes</mat-label>
        <textarea matInput type="text" formControlName="contact_notes" style="height: 200px;"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="viewMode === 'view'" class="row">
    <div>
      <h3><b>Title: </b>{{contactLog.title}}</h3>
      <p><b>When: </b>{{contactLog.contact_datetime | date: 'short'}}</p>
      <p><b>Contact Type: </b>{{contactLog.contact_type}}</p>
      <p><b>Contacted By: </b>{{contactLog.contacted_by}}</p>
      <p><b>Status: </b>{{contactLog.status}}</p>
      <p><b>Date Completed: </b>{{contactLog.date_completed}}</p>
      <p><b>Notes: </b>{{contactLog.contact_notes}}</p>
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center">
  <button mat-flat-button mat-dialog-close color="warn">Cancel</button>
  <button mat-flat-button color="primary" [disabled]="contactLogForm.pristine"
          *ngIf="viewMode === 'edit'" (click)="save(contactLogForm.value, filter)">
    <span>Save</span>
  </button>
</div>