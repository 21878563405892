import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { switchMap, map, tap, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment';

import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {
  data$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  timeframes: string[] = ["Today", "Last 24 Hours", "This Week", "Last 7 Days", "This Month", "Last 30 Days", "This Year", "Last 12 Months"];
  timeframeControl: FormControl = new FormControl(this.timeframes[0]);
  sortOptions: any[] = [
    {key: "name", direction: 1, label: "State (A-Z)"},
    {key: "name", direction: -1, label: "State (Z-A)"},
    {key: "total", direction: 1, label: "Total Activists (0-9)"},
    {key: "total", direction: -1, label: "Total Activists (9-0)"},
    {key: "overTime", direction: 1, label: "Overall Growth (0-9)"},
    {key: "overTime", direction: -1, label: "Overall Growth (9-0)"},
    {key: "emailTotal", direction: 1, label: "Email List (0-9)"},
    {key: "emailTotal", direction: -1, label: "Email List (9-0)"},
    {key: "phoneTotal", direction: 1, label: "Phone List (0-9)"},
    {key: "phoneTotal", direction: -1, label: "Phone List (9-0)"},
  ];
  sortControl: FormControl = new FormControl(this.sortOptions[0]);
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    timeframe: this.timeframes[0],
    orderBy: {
      value: "state",
      label: "State (A-Z)"
    }
  });
  preparingReport: boolean = false;
  
  constructor(private dashboardSvc: DashboardService) { }

  ngOnInit(): void {
    this.filter$.pipe(
      map((filter: any) => {
        let newFilter = {...filter};
        newFilter.orderBy = filter.orderBy.value;
        return newFilter;
      }),
      switchMap((filter: any) => this.dashboardSvc.getDashboardData(filter))
    ).subscribe((response: any) => {
      this.data$.next(response);
    });
    this.timeframeControl.valueChanges.pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([timeframe, filter]: [string, any]) => {
      filter.timeframe = timeframe;
      this.filter$.next(filter);
    });
    this.sortControl.valueChanges.pipe(
      withLatestFrom(this.data$)
    ).subscribe(([sort, data]: [any, any]) => {
      data.states.sort((a: any, b: any) => (a[sort.key] > b[sort.key])?sort.direction:-sort.direction);
      this.data$.next(data);
    });
  }

  updateFilter = (filter: any, setting: string, value: any) => {
    filter[setting] = value;
    this.filter$.next(filter);
  }
  getActivistMetricReport = (filter: any) => {
    this.preparingReport = true;
    this.dashboardSvc.getActivistMetricReport(filter).subscribe((blob: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = "Activist Metric Report - " + moment().format("YYYY-MM-DD-hh-mm-ss") + ".pdf";
      a.click();
      URL.revokeObjectURL(objectUrl);
      this.preparingReport = false;
    });
  }
}