import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { EmailService } from '../email.service';

@Component({
  selector: 'app-send-attempts',
  templateUrl: './send-attempts.component.html',
  styleUrls: ['./send-attempts.component.scss']
})
export class SendAttemptsComponent implements OnInit {
  @Output('edit-email') editEmail: EventEmitter<any> = new EventEmitter<any>();

  constructor(public emailSvc: EmailService) { }

  ngOnInit(): void {}

}