<mat-form-field appearance="outline">
  <mat-label>Search</mat-label>
  <input matInput type="search" placeholder="Search" [formControl]="searchControl" [matAutocomplete]="auto">
</mat-form-field>
<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectItem($event)"
                  [displayWith]="displayFn" panelWidth="350px">
  <mat-option *ngIf="spin"><span>Searching...</span></mat-option>
  <mat-option *ngFor="let option of searchResults | async" [value]="option">
    <span>{{option.first_name + " " + option.last_name}}</span>
    <span *ngIf="option.phone_number"> - {{option.phone_number |  mask: '(000) 000-0000'}}</span>
  </mat-option>
</mat-autocomplete>