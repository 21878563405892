<div fxFlexFill fxLayout="column" fxLayoutGap="10px"
     *ngIf="filter$ | async as filter" class="overflow-auto">
  <app-pagination item-name="Activist Notes" [total-records]="totalRecords$ | async"
                  [page-index]="filter.pageIndex" [page-size]="filter.pageSize"
                  (change-page-size)="updatePageSize($event, filter)"
                  (page-increment)="pageIncrement(filter, $event)"></app-pagination>
  <table *ngIf="person$ | async as person">
    <thead>
      <tr>
        <th>
          <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="5px">
            <span>Note Title</span>
            <button mat-icon-button class="sm-icon-btn"
                    color="primary" [disabled]="!person.person_id"
                    (click)="openDialog({}, person, filter, 'edit')">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
        </th>
        <th><span>Prepared By</span></th>
        <th><span>Sent</span></th>
        <th>
          <div class="sm-icon-btn" style="vertical-align: bottom">
            <mat-icon title="Note Actions">assignment</mat-icon>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let review of notes$ | async">
        <td scope="row" style="cursor: pointer;"
            (click)="openDialog(review, person, filter, 'view')">
          <span>{{review.title}}</span>
        </td>
        <td><span>{{review.prepared_by}}</span></td>
        <td><span>{{review.date_sent | date: 'shortDate'}}</span></td>
        <td>
          <button mat-icon-button class="sm-icon-btn"
                  [matMenuTriggerFor]="noteActionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
        <mat-menu class="compact-menu" #noteActionMenu="matMenu">
          <button mat-menu-item (click)="delete(review, filter)">
            <mat-icon color="warn">delete</mat-icon>
            <span color="warn">Delete Note</span>
          </button>
          <button mat-menu-item (click)="openDialog(review, person, filter, 'edit')">
            <mat-icon>edit</mat-icon>
            <span>Edit Note</span>
          </button>
        </mat-menu>
      </tr>
    </tbody>
  </table>
</div>