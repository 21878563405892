<div class="menu-row" *ngIf="{sheet: (selectedSheet$ | async)} as selected">
  <div>
    <span class="bold">Preview Rows:&nbsp;</span>
    <span>{{previewRows}}</span>
  </div>
  <button mat-flat-button color="primary"
          (click)="importSheet.emit((selected.sheet || {}).id)"
          [disabled]="!(selected.sheet || {}).id">
    <span>Map Worksheet Columns For Import</span>
  </button>
</div>
<div class="table-container">
  <table>
    <thead>
      <tr>
        <th *ngFor="let hdr of sheetPreviewHeaders">{{hdr}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of sheetPreviewData | slice:0:previewRows">
        <td *ngFor="let hdr of sheetPreviewHeaders">{{row[hdr]}}</td>
      </tr>
    </tbody>
  </table>
</div>