<ng-container *ngIf="solicitor$ | async as solicitor">
  <div class="header"><span>{{(solicitor || {}).name}}</span></div>
  <div>
    <span class="bold">Total Contact Attempts:&nbsp;</span>
    <span>{{totalRecords}}</span>
  </div>
  <div>
    <div *ngFor="let item of items">
      <span>{{item.contact_datetime | date: 'shortDate'}}</span>
      <span>&nbsp;-&nbsp;</span>
      <span class="bold">{{item.name}}</span>
      <span>:&nbsp;{{item.title}}</span>
    </div>
  </div>
</ng-container>