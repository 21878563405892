<div class="dialog-header" fxLayout="row" fxLayoutAlign="start center">
  <span class="dialog-title" fxFlex>Donation</span>
  <button mat-icon-button *ngIf="viewMode === 'view'" [matMenuTriggerFor]="donationDialogMenu"
          style="margin-top: -7px;">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #donationDialogMenu="matMenu">
    <button mat-menu-item (click)="viewMode = 'edit'">
      <span>Edit</span>
    </button>
    <button mat-menu-item (click)="delete(donation)">
      <span>Delete</span>
    </button>
  </mat-menu>
</div>
<div mat-dialog-content>
  <div *ngIf="viewMode === 'view'">
    <div>
      <span><b>Recipient: </b>{{donation.recipient}}</span>
    </div>
    <div *ngIf="entry.person.person_id === donation.contact_link">
      <span>
        <span class="bold">Donor: </span>
        <span>{{utilitySvc.getPersonName(entry.person)}}</span>
      </span>
    </div>
    <div>
      <span class="bold">Date:&nbsp;</span>
      <span>{{donation.date | date: 'shortDate'}}</span>
    </div>
    <div>
      <span class="bold">Amount Pledged:&nbsp;</span>
      <span>{{donation.amount_pledged | currency}}</span>
    </div>
    <div>
      <span class="bold">Amount Paid:&nbsp;</span>
      <span>{{donation.amount_paid | currency}}</span>
    </div>
    <div *ngIf="donation.gift_type">
      <span class="bold">Gift Type:&nbsp;</span>
      <span>{{donation.gift_type}}</span>
    </div>
    <div *ngIf="donation.notes">
      <span class="bold">Notes:&nbsp;</span>
      <span style="white-space: pre-wrap;">{{donation.notes}}</span>
    </div>
  </div>
  <div *ngIf="viewMode === 'edit'" [formGroup]="donationForm">
    <mat-form-field class="w-100">
      <mat-label>Donor</mat-label>
      <input matInput type="text" [matAutocomplete]="donorAuto"
             formControlName="contact_link" name="contact_link">
      <button mat-icon-button matSuffix (click)="donationForm.get('contact_link').reset({})">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #donorAuto="matAutocomplete" [displayWith]="autoDisplay">
        <mat-option *ngFor="let result of searchResults" [value]="result">
          <span>{{peopleSvc.getPersonName(result)}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="66">
        <mat-label>Recipient</mat-label>
        <mat-select formControlName="recipient" name="recipient">
          <mat-option *ngFor="let recip of donationSvc.donationRecipientOptions" [value]="recip">
            <span>{{recip}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="34">
        <mat-label>Gift Type</mat-label>
        <mat-select formControlName="gift_type">
          <mat-option [value]="''"></mat-option>
          <mat-option *ngFor="let giftType of giftTypes" [value]="giftType">
            <span>{{giftType}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>Amount Pledged</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput style="text-align: right;" type="text"
               formControlName="amount_pledged" class="text-end">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>Amount Paid</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput type="text" formControlName="amount_paid" class="text-end">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="donationDate" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="donationDate"></mat-datepicker-toggle>
        <mat-datepicker #donationDate></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-form-field class="w-100" style="height: 240px;">
      <mat-label>Notes</mat-label>
      <textarea cdkTextareaAutosize matInput name="notes" formControlName="notes"></textarea>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>Add Donation Tag</mat-label>
      <input matInput [formControl]="addDonationTagControl" [matAutocomplete]="addDonationAuto">
    </mat-form-field>
    <mat-autocomplete #addDonationAuto="matAutocomplete" [displayWith]="displayNull"
                      (optionSelected)="selectDonorTag(donation, $event.option.value)">
      <mat-option *ngIf="addDonationTagControl.value" [value]="{name: addDonationTagControl.value}">
        <span>Create New Donation Tag '{{addDonationTagControl.value}}'</span>
      </mat-option>
      <mat-option *ngFor="let donationTag of donationTags" [value]="donationTag">
        <span>{{donationTag.name}}</span>
      </mat-option>
    </mat-autocomplete>
    <div class="tags">
      <div *ngFor="let donationTag of donation.donationTags">
        <span>{{donationTag.name}}</span>
        <button mat-icon-button class="sm-icon-btn"
                (click)="removeDonorTag(donationTag)">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div id="document-section">
    <div id="document-title-row">
      <span class="header">Documents</span>
      <button mat-icon-button color="primary" class="sm-icon-btn" (click)="fileUpload.click()">
        <mat-icon>add_circle</mat-icon>
      </button>
      <input type="file" style="display: none;" #fileUpload
             (change)="onFileSelected($event, donation.donation_id)">
    </div>
    <div id="document-list">
      <div *ngFor="let document of donation.documents"
           (click)="downloadFile(document.document_key, document.name)">
        <span>{{document.name}}</span>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
    <button mat-flat-button mat-dialog-close color="warn">
      <span>Cancel</span>
    </button>
    <button mat-flat-button color="primary" (click)="save(donationForm.value)"
            [disabled]="donationForm.pristine">
      <span>Save</span>
    </button>
  </div>
</div>