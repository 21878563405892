<mat-form-field appearance="legacy" class="w-100">
  <mat-label>Select List</mat-label>
  <mat-select [(value)]="selectedList" (selectionChange)="getListPeople($event)">
    <mat-option *ngFor="let lst of listSvc.lists | async" [value]="lst">
      <span>{{lst.list}}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="w-100 d-flex flex-row">
  <form [formGroup]="newPersonForm" class="flex-grow-1">
    <mat-form-field appearance="legacy" class="w-100">
      <mat-label>Add New Person</mat-label>
      <input matInput type="text" [matAutocomplete]="newPerson"
             formControlName="peopleSearch" name="peopleSearch">
    </mat-form-field>
    <mat-autocomplete #newPerson (optionSelected)="selectPerson($event)">
      <mat-option *ngFor="let person of peopleSearchResults" [value]="person">
        <span>{{utilitySvc.getPersonName(person)}}</span>
      </mat-option>
    </mat-autocomplete>
  </form>
  <button mat-icon-button color="warn" (click)="removePersonFromList(selectedPerson)">
    <mat-icon>delete</mat-icon>
  </button>
</div>
<ul class="listbox w-100" [ngClass]="{'ul-border': people.length}">
  <li *ngFor="let person of people" (click)="selectedPerson = person;"
      [ngClass]="{'selected': selectedPerson?.person_id === person.person_id}">
    <span>{{utilitySvc.getPersonName(person)}}</span>
  </li>
</ul>